var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "预览",
        visible: _vm.dialogVisible,
        width: _vm.width,
        "before-close": _vm.handleClose,
        modal: false,
        "close-on-click-modal": false,
        "custom-class": "evaluate-dialog"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "12px" },
          attrs: { size: "mini" },
          on: { click: _vm.downLoad }
        },
        [_vm._v("下载")]
      ),
      _vm.getFileType(_vm.fileData.url) == "docx" ||
      _vm.getFileType(_vm.fileData.url) == "doc"
        ? _c(
            "div",
            [
              _c("vue-office-docx", {
                staticClass: "wid-heig",
                attrs: { src: _vm.fileData.url }
              })
            ],
            1
          )
        : _vm.getFileType(_vm.fileData.url) == "xlsx" ||
          _vm.getFileType(_vm.fileData.url) == "xls"
        ? _c(
            "div",
            [
              _c("vue-office-excel", {
                staticClass: "wid-heig",
                attrs: { src: _vm.fileData.url }
              })
            ],
            1
          )
        : _c("div", [
            _c("iframe", {
              staticClass: "wid-heig",
              attrs: { src: _vm.newUrl }
            })
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }