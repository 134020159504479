import axios from 'axios';
import { Message } from 'element-ui';
import qs from 'qs'; // 用于处理 GET 请求参数

// 请求白名单
const whiteList = ['/system/bpm/user/getUserToken'];

// 创建 axios 实例
const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // 根据你的API地址配置
    timeout: 10000, // 设置超时时间
});

// 请求拦截器
instance.interceptors.request.use(
    config => {
        const url = config.url.split("?")[0];
        // 检查请求是否在白名单中
        if (whiteList.includes(url)) {
            return config;
        }

        // 从本地存储中获取token
        const token = localStorage.getItem('key_user_token');
        // 检查token是否存在
        if (token) {
            // 如果存在，将token添加到请求头中
            config.headers['Authorization'] = JSON.parse(token);
            config.headers['clientid'] = 'e5cd7e4891bf95d1d19206ce22dad52';
        } else {
            // 如果token不存在，抛出错误并提示
            Message.error('Token 失效，请重新登录');
            return Promise.reject(new Error('Token 失效'));
        }
        // GET 请求参数处理
        if (config.method === 'get' && config.params) {
            config.url += '?' + qs.stringify(config.params, { arrayFormat: 'brackets' });
            config.params = {};
        }
        return config;
    },
    error => {
        // 请求错误处理
        Message.error('请求错误');
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => {
        if (response.data.code == 200 || response.data.code == 0) {
            return response.data; // 处理响应数据
        } else {
            Message.error(response.data.msg);
            // 抛出错误，进入 catch 块
            return Promise.reject(new Error(response.data.msg || '请求失败'));
        }
    },
    error => {
        // 响应错误处理
        if (error.message.includes('timeout')) {
            Message.error('请求超时，请稍后再试');
        } else {
            Message.error(error.response?.data?.message || '请求失败');
        }
        return Promise.reject(error);
    }
);

export default instance;
