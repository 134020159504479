<template>
    <div id="navig">
        <el-menu class="el-menu-demo" mode="horizontal" :default-active="activeMenu" @select="handleSelect">
            <!-- 首页菜单 -->
            <el-menu-item route>
                <img src="@/assets/ioc/Group_1000005379.png" alt="" @click.stop="aaa" />
            </el-menu-item>

            <!-- 动态菜单项 -->
            <el-menu-item :index="item.path" v-for="item in routes" :key="item.path" @click="setActiveMenu(item.path)">
                <div>
                    <img :src="getImagePath(item.meta.icon)" alt="" style="margin-right: 5px;" />
                    <span>{{ item.meta.title }}</span>
                </div>
            </el-menu-item>

            <!-- 管理后台 -->
            <el-menu-item class="setUp" style="position: absolute; right: 0;" v-if="condition">
                <el-button size="medium" style="color: rgb(144, 147, 153); display: flex;" @click.stop="openBackGround">
                    <img src="@/assets/ioc/nut.png" alt="Custom Icon" class="icon" />
                    <span>管理后台</span>
                </el-button>
            </el-menu-item>
        </el-menu>

        <!-- <keep-alive > -->
        <!-- <keep-alive exclude="ApprovalCenter,Home,OperationRecord"> -->
        <keep-alive exclude="ApprovalCenter,Home,OperationRecord,approvalList">
            <router-view v-if="refresh" :key="$route.fullPath + key"></router-view>
        </keep-alive>
    </div>
</template>

<script>
import actions from "../actions";
import { mapState, mapActions } from 'vuex'
import routes from '@/router/index'
import { getRoutersV2 } from "@/api/system/menu";
export default {
    data() {
        return {
            activeMenu: this.$route.path,
            routes: [],
            condition: false,
            refresh: true,
            key: 1
        };
    },
    watch: {
        $route(newRoute) {
            // 如果跳转的路由存在于菜单项中，更新选中状态
            const matchedMenu = this.routes.find((item) => item.path === newRoute.path);
            if (matchedMenu) {
                this.activeMenu = newRoute.path;
            }
        },
    },
    async mounted() {
        actions.onGlobalStateChange(state => {
            console.log("我是子应用，我检测到数据了：", state);
            if (this.$route.path.includes("/main/workbench/Workflow")) {
                if (state.refreshaaa == false) {
                    this.refresh = state.refreshaaa;
                    setTimeout(() => {
                        this.key++;
                        this.refresh = true;
                    }, 100);
                }
            }
        }, true); //onGlobalStateChange的第二个参数设置为true，则会立即触发一次观察者函数
        this.routes = await this.getRoutes()
    },
    methods: {
        aaa() {
            location.reload(true);
        },
        // 使用 require 动态解析路径
        getImagePath(iconPath) {
            return require(`@/assets/${iconPath}`);
        },
        handleSelect(index) {
            // 点击菜单时更新选中状态
            this.navigateTo(index); // 点击菜单时执行导航
        },
        navigateTo(path) {
            // 路由跳转并更新选中状态
            this.activeMenu = path;
            if (this.$route.path !== path) {
                this.$router.push(path).catch((err) => {
                    if (err.name !== 'NavigationDuplicated') {
                        console.error(err);
                    }
                });
            }
        },
        setActiveMenu(path) {
            this.activeMenu = path;
        },
        openBackGround() {
            this.txsuggestion()
        },
        async getRoutes() {
            let list = routes[0].children.filter(item => item.hasOwnProperty('meta'))
            const res = await getRoutersV2({ flag: 'workflow' })
            list.forEach((err, index) => {
                if (err.meta.menuId) {
                    let is = res.data.some(item => item.menuId === err.meta.menuId);
                    if (!is) {
                        list.splice(index, 1);
                    }
                }
            });
            this.condition = res.data.some(item => item.menuId === '1785157490073968642');
            return list
        }
    }
}
</script>

<style>
#navig {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

.btns {
    margin: 100px;
}

.btns button {
    margin: 0 10px;
}

.icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    margin-left: -8px;
}
</style>