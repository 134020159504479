var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classNames },
    [
      _vm.record.length > 1
        ? _c("img", {
            attrs: { src: require("@/assets/ioc/angle-left.png"), alt: "" },
            on: { click: _vm.fallback }
          })
        : _vm._e(),
      _c("Detail", {
        ref: "DetailRef",
        staticStyle: { "padding-top": "40px" },
        attrs: { drawerData: _vm.drawerData, operation: false },
        on: { closeDrawer: _vm.closeDrawer, setBackgColor: _vm.setBackgColor }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }