var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "about" },
    [
      _c("div", { staticClass: "about-search" }, [
        _c(
          "div",
          { staticClass: "about-search-input" },
          [
            _c("span", { staticClass: "required" }, [_vm._v("*")]),
            _c(
              "el-select",
              {
                staticClass: "sx-select",
                staticStyle: { width: "200px" },
                attrs: {
                  placeholder: "请选择流程名称",
                  filterable: "",
                  "popper-append-to-body": false
                },
                on: { change: _vm.search },
                model: {
                  value: _vm.formInline.modelId,
                  callback: function($$v) {
                    _vm.$set(_vm.formInline, "modelId", $$v)
                  },
                  expression: "formInline.modelId"
                }
              },
              _vm._l(_vm.modelIdList, function(dict) {
                return _c("el-option", {
                  key: dict.modelId,
                  attrs: { label: dict.name, value: dict.modelId }
                })
              }),
              1
            ),
            _c(
              "span",
              {
                staticClass: "required",
                staticStyle: { "margin-left": "8px" }
              },
              [_vm._v("*")]
            ),
            _c("el-date-picker", {
              staticStyle: {
                border: "0px",
                "background-color": "rgb(243, 244, 246)",
                width: "220px",
                height: "34px",
                "border-radius": "6px"
              },
              attrs: {
                align: "left",
                "value-format": "yyyy-MM-dd HH:mm:ss",
                type: "daterange",
                "range-separator": "-",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                "default-time": ["00:00:00", "23:59:59"],
                "append-to-body": false,
                size: "mini"
              },
              on: { change: _vm.search },
              model: {
                value: _vm.formInline.startTime,
                callback: function($$v) {
                  _vm.$set(_vm.formInline, "startTime", $$v)
                },
                expression: "formInline.startTime"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "about-search-input" },
          [
            _c(
              "el-popover",
              {
                attrs: {
                  placement: "left",
                  trigger: "click",
                  "append-to-body": false,
                  width: "366",
                  "popper-options": {}
                }
              },
              [
                _c(
                  "el-form",
                  {
                    staticClass: "demo-form-inline",
                    attrs: {
                      inline: true,
                      model: _vm.formInline,
                      "label-position": "top"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "47%" },
                        attrs: { label: "审批状态" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择审批状态",
                              clearable: "",
                              "popper-append-to-body": false
                            },
                            model: {
                              value: _vm.formInline.status,
                              callback: function($$v) {
                                _vm.$set(_vm.formInline, "status", $$v)
                              },
                              expression: "formInline.status"
                            }
                          },
                          _vm._l(_vm.approvalList, function(dict) {
                            return _c("el-option", {
                              key: dict.value,
                              attrs: { label: dict.label, value: dict.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "47%" },
                        attrs: { label: "启用状态" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择启用状态",
                              clearable: "",
                              "popper-append-to-body": false
                            },
                            model: {
                              value: _vm.formInline.defStatus,
                              callback: function($$v) {
                                _vm.$set(_vm.formInline, "defStatus", $$v)
                              },
                              expression: "formInline.defStatus"
                            }
                          },
                          _vm._l(_vm.openList, function(dict) {
                            return _c("el-option", {
                              key: dict.value,
                              attrs: { label: dict.label, value: dict.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form",
                  {
                    staticClass: "demo-form-inline",
                    staticStyle: { width: "100%" },
                    attrs: {
                      inline: true,
                      model: _vm.formInline,
                      "label-position": "top"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { label: "发起人" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              filterable: "",
                              placeholder: "请选择发起人",
                              "popper-append-to-body": false
                            },
                            model: {
                              value: _vm.formInline.startUserId,
                              callback: function($$v) {
                                _vm.$set(_vm.formInline, "startUserId", $$v)
                              },
                              expression: "formInline.startUserId"
                            }
                          },
                          _vm._l(_vm.UserList, function(item) {
                            return _c("el-option", {
                              key: item.userId,
                              attrs: {
                                label: item.userName,
                                value: item.userId
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { label: "完成时间" }
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%", height: "40px" },
                          attrs: {
                            align: "center",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            type: "daterange",
                            "range-separator": "-",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "default-time": ["00:00:00", "23:59:59"],
                            "append-to-body": false,
                            size: "mini"
                          },
                          model: {
                            value: _vm.formInline.endTime,
                            callback: function($$v) {
                              _vm.$set(_vm.formInline, "endTime", $$v)
                            },
                            expression: "formInline.endTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "sx-btn-box" }, [
                  _c(
                    "div",
                    { staticClass: "sx-btn", on: { click: _vm.reset } },
                    [_vm._v(" 重置 ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "sx-btns", on: { click: _vm.search } },
                    [_vm._v(" 搜索 ")]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "about-search-input-item",
                    attrs: { slot: "reference" },
                    slot: "reference"
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/ioc/menu-right-alt.png"),
                        alt: ""
                      }
                    }),
                    _c("span", [_vm._v("高级筛选")])
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "about-search-input-item",
                on: {
                  click: function($event) {
                    return _vm.$router.push({ name: "OperationRecord" })
                  }
                }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/ioc/clipboard-list.png"),
                    alt: ""
                  }
                }),
                _c("span", [_vm._v("操作记录")])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "about-search-input-item",
                on: { click: _vm.handleExportBeach }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/ioc/arrow-up-from-line.png"),
                    alt: ""
                  }
                }),
                _c("span", [_vm._v("批量导出")])
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tableData.length > 0,
              expression: "tableData.length > 0"
            }
          ],
          staticClass: "about-table"
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "featureRef",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-cell-style": _vm.tableHeaderColor,
                "row-class-name": _vm.tableRowClassName,
                height: "calc(100vh - 230px)"
              },
              on: {
                "row-click": _vm.handleClick,
                "selection-change": _vm.handleSelectionChange
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { label: "流程名称", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "about-table-name" }, [
                          _c("img", {
                            staticStyle: {
                              width: "24px",
                              height: "24px",
                              "margin-right": "8px"
                            },
                            attrs: {
                              src:
                                scope.row.initiator.avatarUrl ||
                                require("@/assets/ioc/Group_41.png"),
                              alt: ""
                            }
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: { color: "rgb(86, 105, 236)" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.UserClick(
                                    scope.row,
                                    scope.column,
                                    $event
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(_vm._s(scope.row.initiator.userName)),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "rgb(27, 27, 27)",
                                    "margin-left": "2px"
                                  }
                                },
                                [_vm._v("提交的")]
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticStyle: { "text-align": "left" } }, [
                          _vm._v(" " + _vm._s(scope.row.name) + " ")
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "提交时间", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parseTime(scope.row.startTime)))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "流程状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.styleObj[scope.row.status]))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "结束时间", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parseTime(scope.row.endTime)))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "历史审批人", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.historyApprover))])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "address", label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "rgb(86, 105, 236)",
                                "margin-right": "16px"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.handleExport(scope.row)
                                }
                              }
                            },
                            [_vm._v("导出")]
                          ),
                          _c(
                            "span",
                            {
                              style: {
                                color: scope.row.taskId
                                  ? "rgb(86, 105, 236)"
                                  : "rgb(195, 196, 204)"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.handleTran(scope.row)
                                }
                              }
                            },
                            [_vm._v("转交")]
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "16px",
                                color: "rgb(248, 62, 69)"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.handleDelete(scope.row)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tableData.length == 0,
              expression: "tableData.length == 0"
            }
          ],
          staticClass: "about-img-box"
        },
        [
          _c("img", {
            staticClass: "about-img",
            attrs: {
              src: "https://source.fungsong.com/3453288888914YKG1W1O2ZTgm.png",
              alt: ""
            }
          }),
          !_vm.formInline.modelId || _vm.formInline.startTime.length == 0
            ? _c("div", { staticClass: "about-img-text" }, [
                _vm._v(" 选择"),
                _c("span", { staticStyle: { color: "rgb(86, 105, 236)" } }, [
                  _vm._v("流程名称和日期范围")
                ]),
                _vm._v("后显示内容 ")
              ])
            : _c("div", { staticClass: "about-img-text" }, [
                _c("span", [_vm._v("暂无数据")])
              ])
        ]
      ),
      _c("processDetails", {
        ref: "processDetails",
        attrs: { drawerData: _vm.drawerData }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.dialogVisible,
            width: "30%",
            "custom-class": "transfer-custom",
            "modal-append-to-body": false,
            center: false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "dialog-footer dialog-footer-btn" },
            [
              _c("div", { staticClass: "dialog-footer-btn-item" }, [
                _vm._v(_vm._s(_vm.dialogTxt.title))
              ]),
              _vm.dialogTxt.title == "批量导出"
                ? _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.checkList,
                        callback: function($$v) {
                          _vm.checkList = $$v
                        },
                        expression: "checkList"
                      }
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "表单" } }),
                      _c("el-checkbox", { attrs: { label: "附件" } })
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "dialog-item" }, [
                _vm._v(_vm._s(_vm.dialogTxt.content))
              ])
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "div",
                {
                  staticClass: "cancel",
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "div",
                {
                  staticClass: "submit",
                  on: { click: _vm.debounceDetermineTheEvent }
                },
                [_vm._v(" 确定 ")]
              )
            ]
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "转交",
            visible: _vm.open,
            width: "368px",
            "modal-append-to-body": false,
            "custom-class": "handover-dialog"
          },
          on: {
            "update:visible": function($event) {
              _vm.open = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "110px",
                "label-position": "top"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "新审批人", prop: "assigneeUserId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        "popper-append-to-body": false
                      },
                      model: {
                        value: _vm.form.assigneeUserId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "assigneeUserId", $$v)
                        },
                        expression: "form.assigneeUserId"
                      }
                    },
                    _vm._l(_vm.userOptions, function(item) {
                      return _c("el-option", {
                        key: item.userId,
                        attrs: { label: item.userName, value: item.userId }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "转交理由", prop: "reason" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入转交理由" },
                    model: {
                      value: _vm.form.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "reason", $$v)
                      },
                      expression: "form.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "div",
                {
                  staticClass: "dialog-footer-btn-cc",
                  on: {
                    click: function($event) {
                      _vm.open = false
                    }
                  }
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer-btn-determine",
                  on: { click: _vm.debounceSubmitUpdateDelegateForm }
                },
                [_vm._v(" 确定 ")]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }