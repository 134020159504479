<template>
  <div class="container">
    <div class="left">
      <div style="  padding-left: 20px;padding-right: 20px;">
        <header>
          <div class="detailed">
            <div class="Number">
              审批编号：{{ processInstance.id }}
            </div>

            <div v-if="operation" class="operation">
              <el-tooltip effect="dark" content="变更记录" placement="bottom" popper-class="custom-tooltip">
                <img src="@/assets/ioc/clipboard-edit@2x.png" alt="" @click="showUpdateLog" />
              </el-tooltip>

              <el-tooltip effect="dark" content="分享" placement="bottom" popper-class="custom-tooltip">
                <img style="margin-left: 8px" src="@/assets/ioc/arrow-export.png" alt=""
                  @click="shareApproval(processInstance, currentNodeId)" />
              </el-tooltip>

              <el-tooltip effect="dark" content="独立窗口" placement="bottom" popper-class="custom-tooltip">
                <img style="margin-left: 8px" src="@/assets/ioc/Group05661.png" alt="" @click="OnSeparate" />
              </el-tooltip>
            </div>
          </div>

          <!-- <button @click="showUpdateLog">变更记录</button> -->

          <div class="user">
            <img class="info_img" :src="startUser.avatarUrl ||  require(`@/assets/ioc/Group_41.png`)" alt="" />
            <div class="info">
              <div class="info_top">
                <p style="margin: 0px;">{{ processInstance.name }}</p>
                <dict-tag type="bpm_process_instance_result" :value="processInstance.result" />
              </div>
              <div class="info_bottom">
                <span>提交于 {{ formattedTime(processInstance.createTime) }}</span>
              </div>
            </div>
            <img class="info_type" :src="displayStatus()" alt="" />
          </div>

          <div class="descriptions-label" v-if="currentApproval()">
            {{ currentApproval() }}
          </div>

        </header>
        <main class="main" v-loading="!dataLoaded">
          <div class="first_line" style="margin-bottom: 8px;"></div>
          <div class="info_box">
            <div v-if="
                this.processInstance.processDefinition &&
                  this.processInstance.processDefinition.formType === 10
              " v-loading="parserLoading">
              <parser ref="oArser" :key="datekey" :form-conf="detailForm" :disabled="true" :disabledReset="true"
                @submit="submit" @onPreview="onPreviewFile" v-if="dataLoaded" :submitDisabled="submitDisabled"
                @changefiles="changefiles" :candidate_dept="candidate_dept" @refresh="initial" />
            </div>

            <div v-if="
                this.processInstance.processDefinition &&
                  this.processInstance.processDefinition.formType === 20
              ">
              <async-biz-form-component :id="this.processInstance.businessKey"></async-biz-form-component>
            </div>
            <!-- 关联审批,上传附件浏览 -->
            <template>
              <div v-for="(item, index) in detailForm.fields" :key="index"></div>
            </template>
          </div>
          <div class="first_line" style="margin-top:20px ;margin-bottom: 20px;"></div>
          <div class="right">
            <div class="right_top">审批</div>
            <timeline :tasks="tasks" @deleteComment="deleteComment" :userId="userId"
              :processInstance="processInstance" />
          </div>
        </main>
      </div>
      <!-- 底部 -->
      <div class="footer" id="btnDiv">
        <div @click="openEvaluateDialog(processInstance.status)" class="btnV"
          v-if="processInstance.status == 1 || processInstance.status == 2">
          <img src="@/assets/ioc/chat.png" alt="" />
          <div class="footer-text">评论</div>
        </div>
        <div @click="handleCancel" v-if="chexiaoBtn" class="btnV">
          <img src="@/assets/ioc/rotate-left.png" alt="" />
          <div class="footer-text">撤销</div>
        </div>
        <div @click="reStartGo" v-if="reStartBtn && !isH5" class="btnV">
          <img src="@/assets/ioc/arrow-up-right.png" alt="" />
          <div class="footer-text">再次发起</div>
        </div>
        <div @click="handleUpdateAssignee" v-if="caozuoBtn" class="btnV">
          <img src="@/assets/ioc/arrows-repeat.png" alt="" />
          <div class="footer-text">转办</div>
        </div>
        <div class="btnV">
          <el-dropdown :render-to-body="false" trigger="click" @command="handleCommand">
            <div>
              <img src="@/assets/ioc/more-horizontal.png" alt="" />
              <div class="footer-text">更多</div>
            </div>
            <el-dropdown-menu slot="dropdown" :append-to-body="false">
              <el-dropdown-item v-if="processInstance.status == 2" :command="1">
                <div style="display: flex; align-items: center;justify-content: center;">
                  <img src="@/assets/ioc/send.png" alt="" />
                  <span style="margin-left: 8px;">抄送</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item v-if="!scopeType && jiaqianBtn && caozuoBtn" :command="2">
                <div style="display: flex; align-items: center;justify-content: center;">
                  <img src="@/assets/ioc/plus-circle.png" alt="" />
                  <span style="margin-left: 8px;">加签</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item v-if="!scopeType && caozuoBtn" :command="3">
                <div style="display: flex; align-items: center;justify-content: center;">
                  <img src="@/assets/ioc/arrow-back.png" alt="" />
                  <span style="margin-left: 8px;">回退</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item :command="4">
                <div style="display: flex; align-items: center;justify-content: center;">
                  <img src="@/assets/ioc/download@2x.png" alt="" style="width: 16px;height: 16px;" />
                  <span style="margin-left: 8px;">下载</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item :command="5" v-if="urgeProcessingBut">
                <div style="display: flex; align-items: center;justify-content: center;">
                  <img src="@/assets/ioc/bell@2x.png" alt="" style="width: 16px;height: 16px;" />
                  <span style="margin-left: 8px;">催办</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item :command="6" v-if="!isH5">
                <div style="display: flex; align-items: center;justify-content: center;">
                  <img src="@/assets/ioc/print.png" alt="" style="width: 16px;height: 16px;" />
                  <span style="margin-left: 8px;">打印</span>
                </div>
              </el-dropdown-item>

            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-if="caozuoBtn">
          <div class="footer-notApproved" @click="handleAudit(false)">
            不通过
          </div>
        </div>
        <div v-if="caozuoBtn">
          <div class="footer-adopt" @click="handleAudit(true)">
            通过
          </div>
        </div>
      </div>
    </div>

    <!-- 对话框(转办审批人) -->
    <el-dialog title="转办审批人" :close-on-click-modal="false" :visible.sync="updateAssignee.open" width="368px"
      :modal-append-to-body="false" :custom-class="'handover-dialog'">
      <el-form ref="updateAssigneeForm" :model="updateAssignee.form" :rules="updateAssignee.rules" label-width="110px"
        :label-position="'top'">
        <el-form-item label="新审批人" prop="assigneeUserId">
          <el-select v-model="updateAssignee.form.assigneeUserId" filterable clearable style="width: 100%"
            :popper-append-to-body="false">
            <el-option v-for="(item, index) in userOptions" :key="index" :label="item.userName" :value="item.userId" />
          </el-select>
        </el-form-item>
        <el-form-item label="转办原因">
          <el-input v-model="updateAssignee.form.reason" clearable placeholder="请输入转办原因" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div @click="cancelUpdateAssigneeForm" class="dialog-footer-btn">
          取消
        </div>
        <div @click="submitUpdateAssigneeForm" class="dialog-footer-btn-determine">
          确定
        </div>
      </div>
    </el-dialog>

    <!-- 撤销流程 -->
    <el-dialog title="取消流程" :close-on-click-modal="false" :visible.sync="revokeShow" width="368px"
      :modal-append-to-body="false" :custom-class="'handover-dialog'">
      <el-form ref="revoke" :model="revoke" v-loading="formLoading" :rules="revokeRules" label-width="110px"
        :label-position="'top'">
        <el-form-item label="取消理由" prop="reasonForRevocation">
          <el-input v-model="revoke.reasonForRevocation" clearable placeholder="请输入取消理由" />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <div @click="revokeShow = false" class="dialog-footer-btn">
          取消
        </div>
        <div @click="confirmRevocation" class="dialog-footer-btn-determine">
          确定
        </div>
      </div>
    </el-dialog>

    <!-- 对话框(委派审批人) -->
    <el-dialog title="委派审批人" :close-on-click-modal="false" :visible.sync="updateDelegate.open" width="500px"
      append-to-body>
      <el-form ref="updateDelegateForm" :model="updateDelegate.form" :rules="updateDelegate.rules" label-width="110px">
        <el-form-item label="新审批人" prop="delegateUserId">
          <el-select v-model="updateDelegate.form.delegateUserId" filterable clearable style="width: 100%">
            <el-option v-for="(item, index) in userOptions" :key="index" :label="item.userName" :value="item.userId" />
          </el-select>
        </el-form-item>
        <el-form-item label="委派理由" prop="reason">
          <el-input v-model="updateDelegate.form.reason" clearable placeholder="请输入委派理由" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitUpdateDelegateForm">确 定</el-button>
        <el-button @click="cancelUpdateDelegateForm">取 消</el-button>
      </div>
    </el-dialog>
    <!--退回流程-->
    <el-dialog title="退回流程" :close-on-click-modal="false" :visible.sync="returnOpen" width="368px"
      :modal-append-to-body="false" :custom-class="'handover-dialog'">
      <el-form ref="formRef" v-loading="formLoading" :model="formData" :rules="formRules" label-width="110px"
        :label-position="'top'">
        <el-form-item label="退回节点" prop="targetDefinitionKey">
          <el-select v-model="formData.targetDefinitionKey" clearable style="width: 100%"
            :popper-append-to-body="false">
            <el-option v-for="(item, index) in returnList" :key="index" :label="item.name"
              :value="item.definitionKey" />
          </el-select>
        </el-form-item>
        <el-form-item label="回退理由" prop="reason">
          <el-input v-model="formData.reason" clearable placeholder="请输入回退理由" />
        </el-form-item>
      </el-form>
      <div style="display: flex;height: 20px;align-items: center;">
        <div style="width: 16px;height: 16px;margin-right: 4px;">
          <img src="@/assets/ioc/check4.png" alt="" style="width: 100%;height: 100%;cursor: pointer;" v-if="isNotice"
            @click="isNotice = false">
          <img src="@/assets/ioc/check3.png" alt="" style="width: 100%;height: 100%;cursor: pointer;" v-else
            @click="isNotice = true">
        </div>
        <div style="height: 20px;line-height: 20px;" class="aaaaaa"><span>审批意见通过聊天转发给</span><span class="sss">{{
            startUser.userName
            }}</span><span class="sss" v-for="item in atList" :key="item.userId">{{ item.userName }}</span></div>
      </div>

      <div slot="footer" class="dialog-footer">
        <div @click="returnOpen = false" class="dialog-footer-btn">
          取消
        </div>
        <div @click="submitReturn" class="dialog-footer-btn-determine">
          确定
        </div>
      </div>
    </el-dialog>
    <!-- 催办弹窗 -->
    <el-dialog title="催办" :close-on-click-modal="false" :visible.sync="urgeProcessing" width="368px"
      :modal-append-to-body="false" :custom-class="'handover-dialog'">
      <div class="urgeProcessing">
        <div class="text-left urgeProcessing-title">
          {{processInstance.name}}
        </div>
        <div class="text-left urgeProcessing-time">
          提交于{{formattedTime(processInstance.createTime)}}
        </div>
        <div class="first_line" style="height: 0px;"></div>
        <div class="text-left urgeProcessing-content">
          发送给
        </div>
        <div class="urgeProcessing-userList">
          <div class="urgeProcessing-user" v-for="item in currentNode.assigneeUsers" :key="item.userId">
            <img :src="item.avatarUrl || require('@/assets/ioc/Group_41.png')" alt="">
            <div>{{item.userName}}</div>
          </div>
        </div>
        <div class="text-left urgeProcessing-remarks">备注</div>
        <el-input type="textarea" :rows="3" placeholder="请输入" v-model="urgeProcessing_remarks">
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <div @click="urgeProcessing = false" class="dialog-footer-btn">
          取消
        </div>
        <div @click="OnUrgeProcessing" class="dialog-footer-btn-determine" style="width: 100%;">
          确定
        </div>
      </div>
    </el-dialog>

    <!--变更记录-->
    <el-dialog title="变更记录" :close-on-click-modal="false" :visible.sync="isShowUpdateLog" width="60%"
      :modal-append-to-body="false" :custom-class="'handover-dialog'">
      <el-timeline :reverse="false">
        <el-timeline-item v-for="(move, index) in logList" :key="index"
          :timestamp="new Date(move.updateTime).toLocaleString()" placement="top" style="text-align: left">
          <div class="log-item">{{ move.description }}</div>
        </el-timeline-item>
      </el-timeline>
    </el-dialog>
    <!-- 加签弹窗 -->
    <addSign ref="addSign" :userOptions="userOptions" :taskId="taskId" :currentTaskId="currentTaskId"
      @addSuccess="addSuccess" :processInstance="processInstance" :detailForm="detailForm"
      :candidate_dept="candidate_dept" :nodesInfo="nodesInfo" />
    <!-- 评论 -->
    <evaluate ref="evaluate" :width="'60%'" :taskId="taskId" :currentTaskId="currentTaskId" :id="id"
      @createComment="createComment" :tasks="tasks" :modelId="modelId" :InstanceData="InstanceData"
      :processInstance="processInstance" />
    <!-- 审批 -->
    <audit ref="audit" :width="'60%'" :taskId="taskId" :processInstance="processInstance" :detailForm="detailForm"
      @auditSucceed="goBack" :candidate_dept="candidate_dept" :nodesInfo="nodesInfo" />
    <!-- 文件预览 -->
    <!-- <FilePreviewDialog :file-url="fileUrl" :fileName="fileName" :dialog-visible="openFilePreview"
      @closePreviewDialog="closePreviewDialog"></FilePreviewDialog> -->
    <FilePreviewDialog ref="FileDialog" :fileData="fileData" :width="'90%'" @closePreviewDialog="closePreviewDialog" />

    <initiatingRange title="添加抄送人" ref="initiatingRange" :isUser="true" :addType="'0'" @confirm="confirm" />
  </div>
</template>

<script>
  import { debounce } from 'lodash-es';
  import { parseTime } from "@/utils/ruoyi";
  import FilePreviewDialog from "./components/FilePreviewDialog.vue";
  import { uniq } from "lodash-es";
  import { fetchData, AsyncFetchData } from "@/utils/utils";
  import { deepClone } from "@/utils/index";
  import {
    approveTask,
    delegateTask,
    getReturnList,
    getTaskListByProcessInstanceId,
    rejectTask,
    returnTask,
    getLogList,
    updateTaskAssignee,
    simpleTaskReject,
    simpleTaskApprove,
    simpleApproveInfo,
    pressFlow,
    getMapping,
    sendFlowUrge,
    flowUrge
  } from "@/api/bpm/task";
  import {
    updateForm,
    updateFormInfo,
    getOptions,
    getTrack,
    getfindTrack,
    freshTrack,
    addSender,
    flowVerifyByDesignId
  } from "@/api/bpm/flow";

  import store from "@/store";
  import { decodeFields } from "@/utils/formGenerator";
  import {
    getProcessInstance,
    getExpression,
    cancelProcessInstance,
    simpleTaskInfo,
    cancelSimpleTaskRevoke
  } from "@/api/bpm/processInstance";
  import { getActivityList } from "@/api/bpm/activity";
  import { listSimpleUsers } from "@/api/system/user";
  import evaluate from "./components/evaluate.vue";
  import timeline from "./components/timeline.vue";
  import addSign from "./components/addSign.vue";
  // import Parser from "@/components/parser/Parser";
  import Parser from "@/components/DrawerParser/Parser";
  import audit from "./components/audit";
  import { set } from "vue";
  import { switchDigest } from '@/utils/switchdigest'
  import initiatingRange from "./components/initiatingRange";
  export default {
    components: {
      timeline,
      Parser,
      addSign,
      evaluate,
      audit,
      FilePreviewDialog,
      initiatingRange
    },
    // props: ["drawerData", 'operation'],
    props: {
      drawerData: {
        type: Object, // 根据你的需要指定类型
        default: () => ({}) // 对于对象类型的prop，使用函数返回默认值
      },
      operation: {
        type: Boolean,
        default: true // 对于基本类型的prop，直接指定默认值
      }
    },
    inject: ["addRecord"],
    watch: {
      drawerData: {
        handler(newVal, oldVal) {
          if (newVal) {
            this.addRecord(newVal)
            this.dataLoaded = false;
            this.initial(newVal);
          }
        },
        deep: true, // 开启深度监听
        immediate: true // 立即执行一次监听函数
      }
    },
    computed: {
      currentNode() {
        const data = this.tasks.find(item => item.result == 1);
        return data || {};
      }
    },
    mounted() { },
    data() {
      return {
        InstanceData: [],
        imgList: [
          "https://source.fungsong.com/3452562980930rw2ySusf1vv2.png", //进行中
          "https://source.fungsong.com/34543364410145RpvpQiwyTeu.png", //已通过
          "https://source.fungsong.com/3454336531559UYM9NI2KUlFM.png", //已撤销
          "https://source.fungsong.com/3454336605543pGSe1EDxJ9hu.png" //未通过
        ],
        revoke: {
          reasonForRevocation: "",
        },

        revokeShow: false,
        datekey: Date.now(),
        logList: [],
        openFilePreview: false,
        isShowUpdateLog: false,
        currentNodeId: "",
        // 预览网络文件
        fileData: {
          name: '',
          url: '',
        },
        // fileUrl: null, // 预览本地文件，文件放置与public文件夹下
        // fileName: null, //
        scopeType: null, //是否加签
        activityList: [],
        jiaqianBtn: false, //加签按钮
        commentFlag: false, //评价按钮
        chexiaoBtn: false, //撤销按钮
        reStartBtn: false, //重新发起
        caozuoBtn: false, //操作按钮
        urgeProcessingBut: false, //催办按钮
        taskId: null,
        btnLoading: false, //操作按钮
        currentTaskId: null,
        taskType: 0, // 1我发起的  2待办任务  3已办任务  4我收到的
        processTasks: {}, //处理中的任务
        userId: "",
        processInstance: {
          processDefinition: {
            bpmnXml: null,
            formFields: []
          }
        }, //详情
        tasks: [], //流程
        // 流程表单详情
        detailForm: {
          fields: [],
          formBtns: false
        },
        moreFlag: false, //更多按钮
        // checkBtnFlag: false,

        //回退列表数据
        formLoading: false,
        returnOpen: false,
        revokeRules: {
          reasonForRevocation: [
            { required: true, message: "取消理由不能为空", trigger: "blur" }
          ]
        },
        formRules: {
          targetDefinitionKey: [
            { required: true, message: "必须选择回退节点", trigger: "change" }
          ],
          reason: [
            { required: true, message: "回退理由不能为空", trigger: "blur" }
          ]
        },
        returnList: [],
        formData: {
          id: "",
          targetDefinitionKey: undefined,
          reason: ""
        },
        // 转派审批人
        userOptions: [],
        updateAssignee: {
          open: false,
          form: {
            assigneeUserId: undefined
          },
          rules: {
            assigneeUserId: [
              { required: true, message: "新审批人不能为空", trigger: "change" }
            ],
          }
        },
        updateDelegate: {
          open: false,
          form: {
            delegateUserId: undefined,
            reason: ""
          },
          rules: {
            delegateUserId: [
              { required: true, message: "新审批人不能为空", trigger: "change" }
            ],
            reason: [
              { required: true, message: "委派理由不能为空", trigger: "blur" }
            ]
          }
        },

        bpmnControlForm: {
          prefix: "flowable"
        },
        dataLoaded: false,
        submitDisabled: false,
        fqrBtn: false,
        originalArray: {},
        origin: [],
        modelKey: [],
        candidate_dept: null,
        modelId: "",
        parserLoading: false,
        startUser: "",
        // 催办是否显示
        urgeProcessing: false,
        // 催办内容
        urgeProcessing_remarks: "",
        nodesInfo: {},
        digestIf: 0,
        digestInfo: '',
        designId: '',
        atList: [],
        isNotice: false,
        isH5: false,
      };
    },

    methods: {
      // 初始化方法
      async initial(newVal) {
        this.isH5 = window.electron ? false : true;
        this.resetBtn();
        this.dataLoaded = false;
        console.log("newVal", newVal);
        this.taskType = newVal.taskType;
        this.userId = JSON.parse(
          localStorage.getItem("store-user-info")
        ).userInfoState.userId;
        this.id = newVal.id;
        this.taskId = newVal.taskId || null;
        await this.getlistSimpleUsers();
        this.getDetail();
      },

      // 按钮权限重置
      resetBtn() {
        this.jiaqianBtn = false; //加签按钮
        this.commentFlag = false; //评价按钮
        this.chexiaoBtn = false; //撤销按钮
        this.reStartBtn = false; //重新发起
        this.caozuoBtn = false; //操作按钮
        this.urgeProcessingBut = false; //催办按钮
      },


      // 根据状态返回图片
      displayStatus() {
        switch (this.processInstance.status) {
          case 1:
            return "https://source.fungsong.com/3454804853962D0DdExSPB3OP.png"; //进行中
            break;
          case 2:
            if (this.processInstance.result == 2) {
              return "https://source.fungsong.com/3454805255918bqlTzibTZJb4.png";//已通过
            } else {
              return "https://source.fungsong.com/3454336605543pGSe1EDxJ9hu.png";//未通过
            }
            break;
          case 3:
            return "https://source.fungsong.com/3454336531559UYM9NI2KUlFM.png";//已撤销
            break;
        }
      },

      formattedTime(time) {
        return parseTime(time);
      },

      changefiles(e) {
        this.saveRelevancy(e);
        if (e.scheme.isAssociation) {
          // console.log(`output->e.scheme`, e.scheme)
          let event = e.event;
          let newArray = [];
          let { __vModel__, __slot__ } = e.scheme;
          if (event) {
            let flag = e.event;
            let filteredItems = this.originalArray.fields.filter(
              item => item.isAssociation == true
            ); //表单中存在哪些选项关联组件
            let filteredItemsI = filteredItems.filter(
              item => item.__vModel__ !== __vModel__
            ); //获取该选项关联组件以外选项关联组件的值
            let oa = []; //其他选项关联组件选定的值
            filteredItemsI.forEach(i => {
              if (i.__config__.defaultValue) {
                let oaItem = i.__slot__.options.filter(
                  e => e.value == i.__config__.defaultValue
                );
                if (oaItem[0]?.defaultChecked) {
                  oa.push(...oaItem[0].defaultChecked);
                }
              }
            });
            let list = __slot__.options.filter(item => {
              return item.value == flag;
            });
            let arr = list[0].defaultChecked || [];
            arr.push(__vModel__);
            arr.push(...this.origin);
            arr.push(...oa);
            arr = uniq(arr);

            for (let i = 0; i < this.originalArray.fields.length; i++) {
              for (let j = 0; j < arr.length; j++) {
                if (
                  (this.originalArray.fields[i].__vModel__ ||
                    this.originalArray.fields[i].__config__.componentName) ==
                  arr[j]
                ) {
                  if (this.originalArray.fields[i].__vModel__ == __vModel__) {
                    newArray.push(e.scheme);
                  } else {
                    newArray.push(this.originalArray.fields[i]);
                    break;
                  }
                } else {
                  console.log("|||");
                }
              }
            }
          } else {
            newArray = this.originalArray.fields;
          }
          // console.log(`output->newArray`, newArray);
          // 找出option的数组
          setTimeout(() => {
            this.$set(this.detailForm, "fields", newArray);
            // console.log(`output->this.detailForm`, this.detailForm);
            //这里更新了datekey ，组件就会刷新
            this.datekey = Date.now();
          }, 500);
        }
      },
      saveRelevancy(e) {
        let scheme = e.scheme;
        this.originalArray.fields.map(item => {
          if (item.__vModel__) {
            if (scheme.__vModel__ == item.__vModel__) {
              item.__config__.defaultValue = scheme.__config__.defaultValue;
            }
          } else {
            item.__config__.children.forEach(row => {
              row.forEach(rowItem => {
                if (scheme.__vModel__ == rowItem.__vModel__) {
                  rowItem.__config__.defaultValue =
                    scheme.__config__.defaultValue;
                }
              });
            });
          }
        });
      },
      showUpdateLog() {
        getLogList({ processInstanceId: this.id }).then(res => {
          const { code, data } = res;
          if (code == 200) {
            this.logList = data;
            this.isShowUpdateLog = true;
          }
        });
      },
      closePreviewDialog() {
        this.$refs.FileDialog.dialogVisible = !this.$refs.FileDialog.dialogVisible;
      },
      // FilePreviewDialogHandle(type, file) {
      //   this.openFilePreview = !this.openFilePreview;
      //   this.fileUrl = file.url;
      //   this.fileName = file.name;
      // },
      // onPreview(file, url = "") {
      //   this.fileUrl = url;
      //   this.fileName = file.name;
      //   this.openFilePreview = !this.openFilePreview;
      // },

      // 使用主应用预览文件
      onPreviewFile(file, url) {
        if (window.ipcRenderer) {
          window.ipcRenderer
            .invoke("win-operate", {
              win: "ChildWin",
              operate: "start",
              argu: {
                width: 800,
                height: 550,
                resizable: true,
                route: `/ImagePreview?src=${encodeURIComponent(url)}&name=${encodeURIComponent(file.name)}`,
              },
            })
            .then(() => {
              this.$message("预览失效");
            });
        } else {
          this.fileData = file;
          this.$refs.FileDialog.dialogVisible = true;
        }
      },


      // getFileType(fileUrl) {
      //   if (fileUrl !== "" && fileUrl != null && fileUrl !== undefined) {
      //     const fileType = fileUrl
      //       .split(".")
      //       .pop()
      //       .toLowerCase(); // 文件的扩展名（格式）

      //     return fileType;
      //   } else {
      //     return "docx";
      //   }
      // },
      async getTrackList() {
        let obj = {
          variables: this.processInstance.formVariables,
          definitionId: this.processInstance.processDefinition.id,
          instanceId: this.id,
          initiator: {
            avatarUrl: this.startUser.avatarUrl,
            userId: this.startUser.userId,
            userName: this.startUser.userName,
            deptId: JSON.parse(localStorage.getItem("store-user-info")).userInfoState.deptId,
          }
        };
        const { data } = await freshTrack(obj);
        return data;
      },
      linkDetails(e) {
        if (!e) return;
        let { defaultValue } = e.__config__;
        let id = defaultValue[defaultValue.length - 1];
        let { href } = this.$router.resolve(
          "/bpm/process-instance/detail?id=" + id + "&taskType=4"
        );
        window.open(href);
      },
      // 获取关联审批中文
      getApprovalName(e) {
        if (!e) return;
        let { options } = e;
        let { defaultValue } = e.__config__;
        let id = defaultValue[defaultValue.length - 1];
        let fn = arr => {
          for (let index = 0; index < arr.length; index++) {
            const item = arr[index];
            if (item.value == id) {
              return item.label;
            } else {
              if (item.children && item.children.length) {
                return fn(item.children);
              }
            }
          }
        };
        return fn(options);
      },
      // 加签成功
      addSuccess() {
        this.goBack();
      },
      // 撤回评论
      deleteComment() {
        this.getTaskListByProcessInstanceId();
      },
      // 评论成功
      createComment() {
        this.getDetail();
      },
      // 打开评论弹窗
      openEvaluateDialog(e) {
        if (e == 2) {
          this.$refs.evaluate.status = 1;
        }
        this.$refs.evaluate.dialogVisible = true;
      },
      // 打开加签弹窗
      createSign() {
        let that = this.$refs.oArser
        that.$refs[that.formConf.formRef].validate((valid) => {
          if (valid) {
            let oldObj = this.processInstance.formVariables
            let newObj = this.$refs.oArser[this.detailForm.formModel]
            this.processInstance.formVariables = { ...oldObj, ...newObj }
            this.nodesInfo = that.nodesInfo
            this.$refs.addSign.digestIf = this.digestIf;
            this.$refs.addSign.digestInfo = this.digestInfo;
            this.$refs.addSign.formFields = this.processInstance.processDefinition.formFields.map(item => JSON.parse(item));
            this.$refs.addSign.confFields = this.$refs.oArser.formConfCopy.fields
            this.$refs.addSign.dialogVisible = true;
          }
        });
      },
      submit(e) {
        if (
          this.processInstance.currentTask &&
          this.processInstance.currentTask.result == 11
        ) {
          this.updateForm(e);
        } else {
          this.updateFormInfo(e);
        }
      },
      // 审批人编辑表单
      async updateFormInfo(e) {
        let obj = {
          processInstanceId: this.id,
          variables: e.values
        };
        const { data } = await updateFormInfo(obj);
        // this.$message.success("提交成功");
        this.getDetail();
        this.$refs.audit.message = 1;
        this.handleAudit(true);
      },
      // 保存表单
      async updateForm(e) {
        let obj = {
          processInstanceId: this.id,
          variables: e.values,
          taskId: this.taskId || this.currentTaskId
        };
        const { data } = await updateForm(obj);
        this.$message.success("提交成功");
        this.getDetail();
      },
      /** 返回退回节点列表 */
      handleBackList() {
        // 可参考 http://blog.wya1.com/article/636697030/details/7296

        getReturnList(this.taskId || this.currentTaskId).then(response => {
          this.returnList = response.data;
          if (this.returnList.length == 0) {
            this.$modal.msgError("当前没有可回退的节点！");
            return;
          }
          this.formData.id = this.taskId || this.currentTaskId;
          this.returnOpen = true;
        });
      },
      /** 提交退回任务 */
      submitReturn: debounce(function () {
        if (!this.formData.targetDefinitionKey) {
          this.$modal.msgError("请选择退回节点！");
        }
        this.$refs["formRef"].validate(valid => {
          if (!valid) {
            return;
          }
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.btnLoading = true;
          let oldObj = this.processInstance.formVariables
          let newObj = this.$refs.oArser[this.detailForm.formModel]
          this.processInstance.formVariables = { ...oldObj, ...newObj }
          // this.processInstance.formVariables =
          //   this.$refs.oArser[this.detailForm.formModel];
          const params = {
            ...this.formData,
            variables: this.processInstance.formVariables,
            records: this.getRecords(),
          };
          if (this.candidate_dept) {
            params.variables.candidate_dept = this.candidate_dept;
          }
          let moneyList = params.records.filter(e => e.type == "amountMoney")
          if (moneyList.length > 0) {
            moneyList.forEach(key => {
              if (params.variables.hasOwnProperty(key.fieldId)) {
                params.variables[key.fieldId] = parseFloat(params.variables[key.fieldId]);
              }
            });
          }
          let nodesInfo = JSON.parse(JSON.stringify(this.$refs.oArser['nodesInfo']))
          if (Object.keys(nodesInfo).length > 0) {
            for (let key in nodesInfo) {
              if (params.variables.hasOwnProperty(key)) {
                params.variables[key] = nodesInfo[key];
              }
            }
          }
          returnTask(params).then(
            res => {
              loading.close();
              this.$modal.msgSuccess("回退成功！");
              if (this.isNotice) {
                let body = {
                  title: this.processInstance.name,
                  content: this.formData.reason,
                  processInstanceId: this.id,
                  operator: this.startUser.userName,
                  viewType: 2
                }
                let userIds = this.atList.map(o => o.userId)
                userIds.push(this.startUser.userId)
                flowUrge({ userIds, msgType: 16, body }).then(r => {
                  this.isNotice = false
                  this.atList = []
                  this.goBack();
                  this.returnOpen = false;
                  this.btnLoading = false;
                }).catch(() => {
                  this.isNotice = false
                  this.atList = []
                  this.goBack();
                  this.returnOpen = false;
                  this.btnLoading = false;
                })
              } else {
                this.goBack();
                this.returnOpen = false;
                this.btnLoading = false;
              }
            },
            () => {
              loading.close();
              this.btnLoading = false;
            }
          );
        });
      }, 500),
      //退回发起人节点提交
      handleFqrSubmit() {
        console.log(this.$refs.oArser[this.detailForm.formModel], 22222222222222222222);
        let oldObj = this.processInstance.formVariables
        let newObj = this.$refs.oArser[this.detailForm.formModel]
        this.processInstance.formVariables = { ...oldObj, ...newObj }
        // this.processInstance.formVariables =
        //   this.$refs.oArser[this.detailForm.formModel];
        const params = {
          id: this.taskId || this.currentTaskId,
          reason: "",
          variables: this.processInstance.formVariables,
          records: this.getRecords()
        };
        if (this.candidate_dept) {
          params.variables.candidate_dept = this.candidate_dept;
        }
        approveTask(params).then(response => {
          this.$modal.msgSuccess("提交成功！");
          this.goBack();
        });
      },
      /** 提交委派审批人 */
      submitUpdateDelegateForm() {
        this.$refs["updateDelegateForm"].validate(valid => {
          if (!valid) {
            return;
          }
          delegateTask(this.updateDelegate.form).then(response => {
            this.$modal.msgSuccess("委派任务成功！");
            this.updateDelegate.open = false;
            this.goBack();
          });
        });
      },
      /** 处理审批委派的操作 */
      handleDelegate() {
        //this.$modal.msgError("暂不支持【委派】功能，可以使用【转派】替代！");
        this.resetUpdateDelegateForm();
        this.updateDelegate.form.id = this.taskId || this.currentTaskId;
        // 设置为打开
        this.updateDelegate.open = true;
      },
      /** 取消委派审批人 */
      cancelUpdateDelegateForm() {
        this.updateDelegate.open = false;
        this.resetUpdateDelegateForm();
      },
      /** 重置委派审批人 */
      resetUpdateDelegateForm() {
        this.updateDelegate.form = {
          id: undefined,
          delegateUserId: undefined
        };
        this.resetForm("updateDelegateForm");
      },
      /** 提交转办审批人 */
      submitUpdateAssigneeForm: debounce(function () {
        let oldObj = this.processInstance.formVariables
        let newObj = this.$refs.oArser[this.detailForm.formModel]
        this.processInstance.formVariables = { ...oldObj, ...newObj }
        this.$refs["updateAssigneeForm"].validate(valid => {
          if (!valid) {
            return;
          }
          this.updateAssignee.form.id = this.taskId || this.currentTaskId;
          const params = {
            id: this.taskId || this.currentTaskId,
            ...this.updateAssignee.form,
            variables: this.processInstance.formVariables,
            records: this.getRecords()
          };
          if (this.candidate_dept) {
            params.variables.candidate_dept = this.candidate_dept;
          }
          // let digestsList = params.records.filter(e => e.tagIcon == "input");
          // let digest = [];
          // if (digestsList.length > 0) {
          //   digest = digestsList.map(e => {
          //     return {
          //       name: e.fieldName,
          //       value: e.newVal
          //     };
          //   });
          // }


          let nodesInfo = JSON.parse(JSON.stringify(this.$refs.oArser['nodesInfo']))
          if (Object.keys(nodesInfo).length > 0) {
            for (let key in nodesInfo) {
              if (params.variables.hasOwnProperty(key)) {
                params.variables[key] = nodesInfo[key];
              }
            }
          }

          let digest = []
          let digestsList = []
          let formFields = this.processInstance.processDefinition.formFields.map(item => JSON.parse(item));
          if (this.digestIf == 1) {
            this.digestInfo.forEach(e => {
              formFields.forEach(i => {
                if (e.__vModel__ == i.__vModel__) {
                  let value = switchDigest(i, 1, params.variables)
                  if(value){
                    digest.push({ name: i.__config__.label, value })
                  }
                }
              })
            })
          } else {
            digestsList = formFields.filter(e => e.__vModel__).slice(0, 3)
            digestsList.forEach(e => {
              let value = switchDigest(e, 1, params.variables)
              if(value){
                digest.push({ name: e.__config__.label, value })
              }
            })
          }
          params.digest = JSON.stringify(digest);



          let confFields = this.$refs.oArser.formConfCopy.fields
          let fullTextList = []
          //递归全局搜索
          confFields.forEach(i => {
            if (i.__config__.tag === "el-row") {
              i.__config__.children.forEach(j => {
                if (j.length > 0) {
                  j.forEach(e => {
                    let value = switchDigest(e, 0, params.variables)
                    fullTextList.push(e.__config__.label)
                    fullTextList.push(value)
                  })
                }
              })
            } else {
              let value = switchDigest(i, 0, params.variables)
              fullTextList.push(i.__config__.label)
              fullTextList.push(value)
            }
          })
          params.fullText = fullTextList.join(' ');
          console.log(`output->params`, params)

          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          updateTaskAssignee(params).then(response => {
            loading.close();
            this.$modal.msgSuccess("转办任务成功！");
            this.updateAssignee.open = false;
            this.goBack();
          }).catch((error) => {
            loading.close();
          });
        });
      }, 500),
      getRecords(data) {
        // console.log(`output->fields`, this.detailForm.fields)
        return this.detailForm.fields.reduce((acc, item) => {
          // console.log(`output->item`,item)

          if (item.__vModel__) {
            item.__config__.newVal = this.processInstance.formVariables[
              item.__vModel__
            ];
            acc.push({
              processDefinitionId: this.processInstance.processDefinition.id,
              processInstanceId: this.processInstance.id,
              fieldId: item.__vModel__,
              fieldName: item.__config__.label,
              oldVal: this.getVal(item, "defaultValue"),
              newVal: this.getVal(item, "newVal"),
              type: item.__config__.type
            });
          } else {
            if (item.__config__.children.length > 0) {
              item.__config__.children.forEach(child => {
                if (Array.isArray(child)) {
                  child.forEach(subChild => {
                    subChild.__config__.newVal = this.processInstance.formVariables[
                      subChild.__vModel__
                    ];
                    acc.push({
                      processDefinitionId: this.processInstance.processDefinition
                        .id,
                      processInstanceId: this.processInstance.id,
                      fieldId: subChild.__vModel__,
                      fieldName: subChild.__config__.label,
                      oldVal: this.getVal(subChild, "defaultValue"),
                      newVal: this.getVal(subChild, "newVal"),
                      type: subChild.__config__.type
                    });
                  });
                } else {
                  child.__config__.newVal = this.processInstance.formVariables[
                    child.__vModel__
                  ];
                  acc.push({
                    processDefinitionId: this.processInstance.processDefinition
                      .id,
                    processInstanceId: this.processInstance.id,
                    fieldId: child.__vModel__,
                    fieldName: child.__config__.label,
                    oldVal: this.getVal(child, "defaultValue"),
                    newVal: this.getVal(child, "newVal"),
                    type: child.__config__.type
                  });
                }
              });
            }
          }
          return acc;
        }, []);
      },
      getVal(item, key) {
        if (item.__config__.tagIcon === "upload") {
          if (typeof item.__config__[key] == "string") {
            item.__config__[key] = JSON.parse(item.__config__[key]);
          }
          let i = item.__config__[key].map(item => item.fileName).join(",");
          return i;
        } else if (
          item.__config__.tagIcon === "checkbox" ||
          item.__config__.tagIcon === "date-range" ||
          item.__config__.tagIcon === "time-range"
        ) {
          return item.__config__[key] ? item.__config__[key].join(",") : ''
        } else if (item.__config__.tagIcon === "cascader") {
          return item.__config__[key]
            .map(cascaderItem => {
              const findItem = item.options.find(optionItem => {
                return cascaderItem === optionItem.value;
              });

              if (findItem) {
                return findItem.label;
              }
            })
            .filter(mapItem => {
              return mapItem !== undefined;
            })
            .join(",");
        } else {
          return item.__config__[key] + "";
        }
      },
      /** 处理转办审批人 */
      handleUpdateAssignee() {
        let that = this.$refs.oArser;
        that.$refs[that.formConf.formRef].validate(valid => {
          if (valid) {
            // 设置表单
            this.resetUpdateAssigneeForm();
            this.updateAssignee.form.id = this.processTasks.id;
            // 设置为打开
            this.updateAssignee.open = true;
          }
        });
      },
      /** 重置转办审批人 */
      resetUpdateAssigneeForm() {
        this.updateAssignee.form = {
          id: undefined,
          assigneeUserId: undefined
        };
        this.resetForm("updateAssigneeForm");
      },
      /** 取消转办审批人 */
      cancelUpdateAssigneeForm: debounce(function () {
        this.updateAssignee.open = false;
        this.resetUpdateAssigneeForm();
      }, 500),


      // 获得用户列表
      async getlistSimpleUsers() {
        this.userOptions = [];
        const response = await listSimpleUsers();
        this.userOptions.push(...response.data);
        // .then((response) => {
        //   this.userOptions.push(...response.data);
        // });
      },

      // 审批通过/不通过
      handleAudit(pass) {
        let that = this.$refs.oArser
        console.log(that.formConf.formRef);
        that.$refs[that.formConf.formRef].validate((valid, arr, ccc) => {
          if (valid) {
            let oldObj = this.processInstance.formVariables
            let newObj = this.$refs.oArser[this.detailForm.formModel]
            this.processInstance.formVariables = { ...oldObj, ...newObj }

            this.taskId ? this.taskId : (this.taskId = this.currentTaskId);
            this.nodesInfo = that.nodesInfo
            this.$refs.audit.digestIf = this.digestIf;
            this.$refs.audit.digestInfo = this.digestInfo;
            this.$refs.audit.formFields = this.processInstance.processDefinition.formFields.map(item => JSON.parse(item));
            this.$refs.audit.confFields = this.$refs.oArser.formConfCopy.fields
            this.$refs.audit.dialogVisible = true;
            this.$refs.audit.pass = pass;
          } else {
            const key = Object.keys(arr)[0];
            this.$modal.msgError(arr[key][0].message);
          }
        });
      },
      // 撤销
      handleCancel() {
        this.revokeShow = true;
      },

      // 确定撤销
      confirmRevocation: debounce(function () {
        this.$refs["revoke"].validate((valid) => {
          console.log(valid);
          if (valid) {
            const id = this.processInstance.id;
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            cancelProcessInstance(id, this.revoke.reasonForRevocation).then(() => {
              loading.close();
              this.$modal.msgSuccess("取消成功");
              this.revokeShow = false;
              this.goBack();
            }).catch((error) => {
              loading.close();
            });
          } else {
            return false;
          }
        })
      }, 500),



      async getDetail() {
        this.parserLoading = true;
        // 获得流程实例相关
        this.processInstanceLoading = true;

        const { data } = this.taskId
          ? await getProcessInstance(this.id, this.taskId)
          : await getProcessInstance(this.id);
        // const { data } = await getProcessInstance(this.id, this.taskId);
        this.digestIf = data.digestIf
        if (data.digestIf == 1) {
          this.digestInfo = JSON.parse(data.digestInfo)
        }
        this.currentTaskId = data.currentTask?.taskId;
        this.modelId = data.modelId;
        this.startUser = data.startUser;
        this.designId = data.designId

        if (!data) {
          this.$message.error("查询不到流程信息！");
          return;
        }

        // if (data.modelKey) {
        //   const resp = await getExpression(data.modelKey);
        //   this.modelKey = resp.data;
        // }
        // 按钮权限
        this.setBtn(data);
        // 设置流程信息
        this.processInstance = data;
        this.$emit('setBackgColor', { status: data.status, result: data.result })
        let list = this.processInstance.processDefinition.formFields.map(e =>
          JSON.parse(e)
        );

        if (this.processInstance.formVariables) {
          this.candidate_dept = this.processInstance.formVariables.candidate_dept
            ? this.processInstance.formVariables.candidate_dept
            : null;
          list.forEach(item => {
            if (!item.display && item.disabled == false && this.taskType == 2) {
              this.detailForm.formBtns = true;
            }

            if (item.__vModel__) {
              /* 普通表单 */
              if (this.processInstance.formVariables[item.__vModel__]) {
                this.$set(
                  item.__config__,
                  "defaultValue",
                  this.processInstance.formVariables[item.__vModel__]
                );
              } else if (item.__config__.type === "amountMoney" && this.processInstance.formVariables[item.__vModel__] == 0) {
                this.$set(
                  item.__config__,
                  "defaultValue",
                  '0'
                );
              }

            } else if (item.__config__.children.length) {
              /* 容器表单 */
              const map = item.__config__.children[0].map(rowItem => {
                const havKeys = Object.keys(
                  this.processInstance.formVariables
                ).filter(key => {
                  if (key === rowItem.__vModel__) {
                    this.$set(
                      rowItem.__config__,
                      "defaultValue",
                      this.processInstance.formVariables[key]
                    );
                  }
                  return (
                    key.includes(rowItem.__vModel__) && key !== rowItem.__vModel__
                  );
                });

                console.log(havKeys, "havKeys");

                return havKeys.map(key => {
                  const dRowItem = deepClone(rowItem);
                  dRowItem.__config__.defaultValue = this.processInstance.formVariables[
                    key
                  ];
                  dRowItem.__vModel__ = key;
                  rowItem.__config__.defaultValue = this.processInstance.formVariables[
                    rowItem.__vModel__
                  ];
                  return dRowItem;
                });
              });

              if (map.flat().length) {
                map.forEach(item2 => {
                  item2.forEach((item3, index) => {
                    if (item.__config__.children[index + 1]) {
                      item.__config__.children[index + 1].push(item2[index]);
                    } else {
                      item.__config__.children[index + 1] = [item2[index]];
                    }
                  });
                });
              }
            }
          });
        }


        list = await AsyncFetchData(list)

        let missingObjects = [];

        // 表单选项关联处理
        let associations = list.filter(e => e.isAssociation);
        let association = list.filter(e => e.isAssociation)[0];
        if (associations.length > 0) {
          let arr = list.map(item =>
            item.__vModel__ ? item.__vModel__ : item.__config__.componentName
          );
          const filteredItems = list.filter(item => item.isAssociation == true);
          const defaultCheckedArray = filteredItems.flatMap(item =>
            item.__slot__.options.map(option => option.defaultChecked)
          );
          const oneDimArray = defaultCheckedArray.flat();
          this.origin = arr.filter(element => !oneDimArray.includes(element));
          let defaultChecked = [];
          associations.forEach(item => {
            let oo =
              item.__slot__.options.filter(
                e => e.value == item.__config__.defaultValue
              )[0]?.defaultChecked || [];
            defaultChecked.push(...oo);
          });

          defaultChecked.push(association.__vModel__);

          defaultChecked.push(...this.origin);

          defaultChecked = uniq(defaultChecked);
          let newArray = [];
          for (let i = 0; i < list.length; i++) {
            for (let j = 0; j < defaultChecked.length; j++) {
              if (
                (list[i].__vModel__ || list[i].__config__.componentName) ==
                defaultChecked[j]
              ) {
                if (list[i].__vModel__ == association.__vModel__) {
                  newArray.push(association);
                } else {
                  newArray.push(list[i]);
                  break;
                }
              }
            }
          }
          let idsInB = new Set(newArray.map(item => item.__vModel__));
          missingObjects = list.filter(item => !idsInB.has(item.__vModel__));
          list = newArray;


        }

        if (this.processInstance.processDefinition.formType === 10) {
          const currentTask = this.processInstance.currentTask;
          if (
            currentTask &&
            currentTask.result == 1 &&
            currentTask.assigneeUserId == this.userId
          ) {
            this.taskType = 2;
          }
          if (this.taskType != 2) {
            list.forEach(item => {
              if (item.__vModel__) {
                item.disabled = true;
              } else {
                item.__config__.children.forEach(row => {
                  row.forEach(rowItem => {
                    rowItem.disabled = true;
                  });
                });
              }
            });
            missingObjects.forEach(item => {
              if (item.__vModel__) {
                item.disabled = true;
              } else {
                item.__config__.children.forEach(row => {
                  row.forEach(rowItem => {
                    rowItem.disabled = true;
                  });
                });
              }
            });
            this.detailForm.formBtns = false;
          }

          // 退回打开所有编辑权限
          if (
            currentTask &&
            currentTask.result == 11 &&
            this.userId == currentTask.assigneeUserId
          ) {
            this.submitDisabled = true;

            list.forEach(e => {
              if (e.__vModel__) {
                e.disabled = false;
                e.display = false;

                if (e.__config__.type === "litigant") {
                  e.disabled = true;
                }
                // if (this.modelKey.includes(e.__vModel__)) {
                //   // 如果在，将e.type设置为true
                //   e.disabled = true;
                // }
              } else {
                e.__config__.children.forEach(childArray => {
                  childArray.forEach(child => {
                    child.disabled = false;
                    child.display = false;
                  });
                });
              }
            });

            missingObjects.forEach(e => {
              if (e.__vModel__) {
                e.disabled = false;
                e.display = false;

                if (e.__config__.type === "litigant") {
                  e.disabled = true;
                }
                // if (this.modelKey.includes(e.__vModel__)) {
                //   // 如果在，将e.type设置为true
                //   e.disabled = true;
                // }
              } else {
                e.__config__.children.forEach(childArray => {
                  childArray.forEach(child => {
                    child.disabled = false;
                    child.display = false;
                  });
                });
              }
            });

            this.detailForm.formBtns = true;
          }
          console.log(JSON.parse(JSON.stringify(list)), "list")
          this.detailForm.fields = list;

          this.detailForm = Object.assign(
            this.detailForm,
            JSON.parse(this.processInstance.processDefinition.formConf)
          );

          if (!currentTask) {
            this.detailForm.disabled = true;
            this.detailForm.formBtns = false;
          }
          this.originalArray = { ...this.detailForm };
          let arr = JSON.parse(JSON.stringify(this.originalArray.fields));
          let combinedArray = arr.concat(missingObjects);
          let uniqueObjects = combinedArray.reduce((acc, item) => {
            if (!acc.find(obj => obj.__vModel__ === item.__vModel__)) {
              acc.push(item);
            }
            return acc;
          }, []);
          this.originalArray.fields = uniqueObjects;
        }
        this.dataLoaded = true;
        this.parserLoading = false;
        // }, 2000);

        // 加载活动列表
        getActivityList({
          processInstanceId: this.processInstance.id
        }).then(response => {
          this.activityList = response.data;
        });

        this.getTaskListByProcessInstanceId();
      },

      setBtn(data) {
        const currentTask = data.currentTask;
        // 所有按钮权限  只有发起和代办就有按钮
        // this.checkBtnFlag = true;
        console.log(data)

        if (
          // this.userId == data.startUser.userId&&data.isRepeat
          data.isRepeat
        ) {
          this.reStartBtn = true;
        }
        // 撤销按钮
        this.chexiaoBtn = false;
        if (
          data.startUser &&
          this.userId == data.startUser.userId &&
          data.result === 1 &&
          data.isCancel == 1
        ) {
          this.chexiaoBtn = true;
        }
        // 操作按钮
        this.caozuoBtn = false;
        if (currentTask && currentTask.result == 1) {
          if (currentTask.assigneeUserId) {
            this.caozuoBtn =
              this.userId == currentTask.assigneeUserId ? true : false;
          }
        }
        if (currentTask && currentTask.result == 11) {
          if (currentTask.assigneeUserId) {
            this.chexiaoBtn = currentTask.assigneeUserId ? true : false;
            this.fqrBtn =
              this.userId == currentTask.assigneeUserId ? true : false;
          }
        }

        // 催办按钮权限
        if (data.status == 1 && data.startUser.userId == this.$store.state.imWorkbench.userId) {
          this.urgeProcessingBut = true;
        }

        // 评论按钮权限
        this.commentFlag = true;
        // 当前任务是否加签
        this.scopeType = false;
        if (currentTask && currentTask.scopeType) {
          this.scopeType = true;
        }
        // 加签按钮权限
        this.jiaqianBtn = false;
        if (data.isSign == 1) {
          this.jiaqianBtn = true;
        }
        // setTimeout(() => {
        //   let btnDiv = document.getElementById("btnDiv");
        //   // console.log(btnDiv, "btnDiv");
        //   if (btnDiv.childElementCount <= 0) {
        //     this.checkBtnFlag = false;
        //   }
        // }, 100);
      },
      // 获取流程
      async getTaskListByProcessInstanceId() {
        const { data } = await getTaskListByProcessInstanceId(this.id);
        this.InstanceData = data;
        if (data.length == 1) {
          this.scopeType = true;
        }
        // 审批记录
        this.tasks = [];
        // 移除已取消的审批
        data.forEach(task => {
          this.tasks.push(task);
        });
        console.log(this.tasks, "this.tasks")
        const a = this.tasks;
        this.tasks.reverse();
        if (this.processInstance.status == 1) {

          const track = await this.getTrackList();

          let subsequentNodes = false;
          if (
            data[0].approveType == "sequence"
          ) {
            this.currentNodeId = data[0].taskDefKey;
            const startIndex = track.findIndex(
              obj => obj.taskDefKey === this.currentNodeId
            );
            if (startIndex >= 0) {
              let a = track[startIndex].assigneeUsers.length;
              let b = 0
              data.forEach(elem => {
                if (elem.taskDefKey === this.currentNodeId) {
                  track[startIndex].assigneeUsers = track[
                    startIndex
                  ].assigneeUsers.filter(
                    item => item.userId != elem.assigneeUsers[0].userId
                  );
                  b++
                }
              });
              subsequentNodes = track[startIndex];
              a <= b ? subsequentNodes = false : subsequentNodes;
            }
          }

          let arr = [];
          let startIndex = -1;
          let i = 1;

          while (startIndex === -1 && this.tasks.length - i >= 0) {
            const lastTaskDefKey = this.tasks[this.tasks.length - i].taskDefKey;
            startIndex = track.findIndex(obj => obj.taskDefKey === lastTaskDefKey);
            i++;
          }
          if (startIndex !== -1) {
            let arr = track.slice(startIndex + 1);

            this.tasks.push(...arr);
          } else {
            console.log("未找到匹配元素");
          }

          if (subsequentNodes) {
            let indexs = 0;
            for (let i = this.tasks.length - 1; i >= 0; i--) {
              if (this.tasks[i].taskDefKey == subsequentNodes.taskDefKey) {
                indexs = i;
                break;
              }
            }
            if (subsequentNodes && subsequentNodes.assigneeUsers.length > 0) {
              this.tasks.splice(indexs + 1, 0, subsequentNodes);
            }
          }

        }
      },
      goBack() {
        this.$emit("closeDrawer");
      },
      openServiceTask() {
        // 抄送人选用户组
        this.$store.dispatch("bpm/listDeptUser");
        this.$refs.initiatingRange.dialogVisible = true;
      },
      confirm(val) {
        // console.log(`output->val`, val)
        let userIfs = val.map(item => item.id);
        let data = {
          instId: this.id,
          userIds: userIfs
        };
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        addSender(data)
          .then(res => {
            loading.close();
            this.$message.success("添加抄送人成功!");
            this.getDetail();
          })
          .catch(err => {
            loading.close();
          });
      },
      reStartGo() {
        flowVerifyByDesignId(this.designId).then(res => {
          if (res.data) {
            const query = { taskType: 1, reStartId: this.id }
            if (!this.operation) {
              const url = `/main/workbench/Workflow/create?taskType=1&reStartId=${this.id}`
              window.electron.toRoute(url)
            } else {
              if (this.$route.path == '/main/workbench/Workflow/create') {
                this.$router.replace({
                  name: "Create",
                  query
                });
                window.goRefresh();
              } else {
                this.$router.push({
                  name: "Create",
                  query
                });
              }
            }
          }
        })

      },

      // 更多功能
      handleCommand(command) {
        switch (command) {
          case 1:
            this.openServiceTask();
            break;
          case 2:
            this.createSign();
            break;
          case 3:
            this.handleBackList();
            break;
          case 4:
            this.print();
            break;
          case 5:
            this.urgeProcessing = true;
            break;
          case 6:
            this.print('print');
            break;
        }
      },
      // 打印
      print(type) {
        const data = {
          formName: this.processInstance.name,
          instanceId: this.processInstance.id,
          itemList: [],
        }
        this.$refs.oArser.print(data, type);
      },

      // 催办事件
      async OnUrgeProcessing() {
        await this.imUrgeProcessing()
        this.$modal.msgSuccess("催办成功！");
        this.urgeProcessing = false;
      },

      // 企业微信催办
      async wechatUrgeProcessing() {
        const pressUserIds = this.currentNode.assigneeUsers.map(item => item.userId);
        const query = {
          processInstanceId: this.processInstance.id,
          pressUserIds,
        }
        const { data } = await pressFlow(query);
        return data;
      },

      // IM催办
      async imUrgeProcessing() {
        const userIds = this.currentNode.assigneeUsers.map(item => item.userId);
        const Mapping = await getMapping(this.processInstance.id, this.currentNodeId)
        const query = {
          userIds,
          msgType: 15,
          body: {
            name: this.processInstance.name.split("_")[1], //审批标题
            formFields: this.processInstance.digest, //表单字段内容
            userIdTaskMap: Mapping.data, //任务id用户id映射关系
            processInstanceId: this.processInstance.id, // 流程实例id
            instanceResult: this.processInstance.result, //流程实例状态1.进行中2.已通过3.已拒绝4.已撤销
            remark: this.urgeProcessing_remarks,
            startUserName: this.processInstance.name.split("_")[0],
          }
        }
        const msg = await sendFlowUrge(query);
        return msg.data;
      },

      // 窗口独立
      OnSeparate() {
        const drawerData = JSON.stringify({ id: this.processInstance.id })
        const token = JSON.parse(localStorage.getItem('key_user_token'))
        const url = `${process.env.VUE_APP_RUN_URL}#/main/workbench/Workflow/mobileDetail?drawerData=${drawerData}&token=${token}`
        console.log(url, "url")
        window.electron.createNewWindow(url, { width: 800, height: 1000, title: '审批详情' });
      },

      // 返回当前审批节点
      currentApproval() {
        if (this.processInstance.status == 1) {
          const processorNode = this.tasks.find(err => err.result == 1);
          if (processorNode && processorNode.assigneeUsers.every(err => err.userId != this.processInstance.startUser.userId)) {
            const userNum = processorNode.assigneeUsers.length > 2 ? `等${processorNode.assigneeUsers.length}人` : ''
            const text = `等待${processorNode.assigneeUsers[0].userName}${userNum}处理`
            return text
          } else {
            return `等待我处理`
          }
          // if (processorNode.assigneeUsers.length == 1) {
          //   return processorNode.assigneeUsers[0].userId == this.processInstance.startUser.userId ? '等待我处理' : `等待${processorNode.assigneeUsers[0].userName}处理`
          // } else {

          // }
        }
        return false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .urgeProcessing {
    .urgeProcessing-title {
      color: var(--字体黑, rgba(27, 27, 27, 1));
      font-family: "Noto Sans SC";
      font-weight: 500;
      font-size: 16px;
    }

    .urgeProcessing-time {
      color: var(--次字体颜色, rgba(132, 132, 132, 1));
      font-family: "Noto Sans SC";
      font-weight: 400;
      font-size: 12px;
      margin-top: 12px;
    }

    .urgeProcessing-content {
      color: var(--字体黑, rgba(27, 27, 27, 1));
      font-family: "Noto Sans SC";
      font-weight: 400;
      font-size: 14px;
    }

    .urgeProcessing-userList {
      margin-top: 12px;
      display: flex;
      gap: 28px;

      .urgeProcessing-user {
        display: flex;
        align-items: center;

        img {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          margin-right: 8px;
        }

      }

    }

    .urgeProcessing-remarks {
      margin-top: 20px;
      margin-bottom: 8px;
      color: rgba(0, 0, 0, 1);
      font-family: "Noto Sans SC";
      font-weight: 400;
      font-size: 14px;
    }

  }

  .container {
    box-sizing: border-box;


    .left {
      overflow: auto;
      flex: 1;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      overflow: hidden;

      header {
        height: 112px;
        margin-bottom: 10px;

        .detailed {
          display: flex;
          text-align: justify;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
          color: rgba(132, 132, 132, 0.5);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 10px;
          line-height: 10px;

          .operation {
            img {
              width: 16px;
              height: 16px;
            }
          }

          .Number {
            user-select: text;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 60%;
          }
        }

        .user {

          display: flex;
          align-items: center;


          .info_img {
            width: 44px;
            height: 44px;
            border-radius: 50%;
          }

          .info_type {
            display: flex;
            margin-left: auto;
            width: 60px;
            height: 60px;
          }

          .info {
            display: flex;
            flex-direction: column;
            margin-left: 8px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex-wrap: wrap;

            &_top {
              margin-bottom: 8px;
              display: flex;
              gap: 8px;

              p {
                font-weight: 600;
                font-size: 16px;
              }

              div {
                padding: 4px 8px;
                border-radius: 20px;
                background: #f5f5f7;
                color: #060000;
                font-size: 14px;
              }
            }

            &_bottom {
              display: flex;
              gap: 8px;
              font-size: 12px;
              color: #727272;
            }
          }
        }

        .btn {
          margin-top: 8px;
          display: flex;
          align-items: center;
          border-radius: 30px;
          gap: 12px;
          margin-top: 18px;
          justify-content: flex-end;

          .icon {
            cursor: pointer;
            width: 32px;
            height: 32px;
            border: 1px solid #060000;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .main {
        box-sizing: border-box;
        overflow: auto;
        height: calc(100vh - 68px - 90px - 66px);
        overflow-x: hidden;

        .line {
          height: 1px;
          background: #f0f0f0;
          margin: 16px 0;
        }

        .info_box {
          display: flex;
          flex-direction: column;
          overflow-x: hidden;

          .title {
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            font-size: 16px;
            align-items: center;

            .el-button {
              height: auto;
              background: none;
              color: #1890ff;
              border: 0px;
            }
          }

          .descriptions {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            color: #727272;

            div {
              border: 1px dashed #727272;
              padding: 4px 10px;
              border-radius: 20px;
            }
          }
        }
      }

      .footer {
        width: 100%;
        height: 60px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(243, 244, 246, 1);
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: space-evenly;

        .footer-text {
          color: rgba(27, 27, 27, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 10px;
          line-height: 11px;
          user-select: none;
        }
      }
    }

  }

  .el-button {
    color: #fff;
    border-radius: 30px;
    background: #060000;
    border: 1px solid #060000;
    height: 32px;
  }

  .pian_btn {
    border: 1px solid #060000;
    background: #fff;
    color: #060000;
  }

  ::v-deep .el-upload-list__item-name {
    text-align: left;
    user-select: text;
    height: 32px !important;
    line-height: 32px !important;
  }

  ::v-deep .el-upload-list__item {
    height: 60px !important;

    img {
      width: 32px !important;
      height: 32px !important;
    }
  }

  ::v-deep .el-card__body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }


  ::v-deep .hideUpload .el-button {
    display: none;
  }

  ::v-deep .hideUpload .el-upload {
    display: none;
  }

  ::v-deep .el-button--default {
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px !important;
    background: rgb(255, 255, 255) !important;
    border: 1px solid rgb(220, 223, 230) !important;
    color: rgb(96, 98, 102) !important;
  }

  ::v-deep .el-button--primary {
    border-radius: 4px !important;
    border: 1px solid rgb(255 255 255) !important;
    /* padding: 9px 20px !important; */
  }

  ::v-deep .el-dropdown-menu__item {
    white-space: nowrap;
  }

  .first_line {
    height: 1px;
    transform: rotate(0.000005deg);
    border: 1px dashed var(--温柔灰, rgba(228, 229, 231, 1));
    /* margin-top: 16px;
    margin-bottom: 16px; */
  }

  .right_top {
    color: var(--字体黑, rgba(27, 27, 27, 1));
    font-family: "Noto Sans SC";
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    margin-bottom: 16px;
  }

  /* 滚动条样式 */
  ::-webkit-scrollbar {
    width: 0px;
  }

  /* 滑块样式 */
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 246, 249, 1);
    border-radius: 10px;
  }

  /* 滚动条轨道样式 */
  ::-webkit-scrollbar-track {
    background-color: #fcfcfc;
    border-radius: 2px;
  }

  ::v-deep .handover-dialog {
    .el-dialog__header {
      display: flex;

      .el-dialog__headerbtn {
        top: 8px;
        right: 0px;
      }
    }

    .el-dialog__body {
      padding: 20px 20px;

      .el-form-item {
        display: grid;
        margin-bottom: 18px !important;

        .el-form-item__label {
          margin-bottom: 12px;
        }

        .el-form-item__content {
          .el-input__inner {
            background: #fff !important;
            border: 1px solid rgb(220, 223, 230) !important;
          }
        }
      }
    }

    .el-dialog__footer {
      .dialog-footer {
        display: flex;

        .dialog-footer-btn {
          width: 110px;
          height: 44px;
          border-radius: 8px;
          background: rgba(243, 244, 246, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(132, 132, 132, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
        }

        .dialog-footer-btn-determine {
          width: 208px;
          height: 44px;
          border-radius: 8px;
          background: rgba(86, 105, 236, 1);
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(255, 255, 255, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }

  .footer-notApproved {
    width: 84px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid rgba(243, 244, 246, 1);
    color: rgba(132, 132, 132, 1);
    font-family: "Noto Sans SC";
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
  }

  .footer-adopt {
    width: 84px;
    height: 36px;
    border-radius: 4px;
    background: rgba(86, 105, 236, 1);
    color: rgba(255, 255, 255, 1);
    font-family: "Noto Sans SC";
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
  }

  /* 文字靠左 */
  .text-left {
    text-align: left;
  }

  .descriptions-label {
    border-radius: 2px;
    border: 1px solid var(--主题色, rgba(86, 105, 236, 1));
    box-sizing: border-box;
    color: var(--主题色, rgba(86, 105, 236, 1));
    font-size: 10px;
    padding: 2px;
    margin-left: 52px;
    white-space: nowrap;
    width: max-content;
    margin-top: 4px;
    margin-bottom: 16px;
  }

  .btnV {
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .aaaaaa {
    .sss {
      color: rgba(86, 105, 236, 1);
      margin-right: 8px;
    }
  }
</style>