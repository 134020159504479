var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Submited" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "return", on: { click: _vm.backToApproval } },
          [
            _c("img", {
              attrs: { src: require("@/assets/ioc/angle-left.png"), alt: "" }
            }),
            _c("span", [_vm._v("发起审批")])
          ]
        )
      ]),
      _c("div", { staticClass: "mainOn" }, [
        _vm._m(0),
        _c(
          "div",
          {
            staticStyle: {
              "font-weight": "600",
              "font-size": "14px",
              color: "#1b1b1b",
              "margin-top": "20px",
              "margin-bottom": "32px"
            }
          },
          [_vm._v("提交成功! ")]
        ),
        _c("div", { staticStyle: { display: "flex" } }, [
          _c("div", { staticClass: "eBtn", on: { click: _vm.handleView } }, [
            _vm._v("查看详情")
          ]),
          _c(
            "div",
            {
              staticClass: "eBtn",
              staticStyle: {
                "background-color": "#5669ec",
                color: "#ffffff",
                "margin-left": "20px"
              },
              on: { click: _vm.handleRepeat }
            },
            [_vm._v("再次提交")]
          )
        ])
      ]),
      _c("processDetails", {
        ref: "processDetails",
        attrs: { drawerData: _vm.drawerData },
        on: { closeDrawer: _vm.closeDrawer }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { width: "120px", height: "120px" } }, [
      _c("img", {
        staticStyle: { width: "100%", height: "100%" },
        attrs: {
          src: "https://cdn.fengwork.com/images/Group_1209.png",
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }