<template>
  <el-dialog title="预览" :visible.sync="dialogVisible" :width="width" :before-close="handleClose" :modal="false"
    :close-on-click-modal="false" custom-class='evaluate-dialog'>

    <el-button @click="downLoad" style="margin-bottom: 12px" size="mini">下载</el-button>

    <!-- docx -->
    <div v-if="getFileType(fileData.url) == 'docx' || getFileType(fileData.url) == 'doc'">
      <vue-office-docx :src="fileData.url" class="wid-heig" />
    </div>
    <!-- xlsx -->
    <div v-else-if="getFileType(fileData.url) == 'xlsx' || getFileType(fileData.url) == 'xls'">
      <vue-office-excel :src="fileData.url" class="wid-heig" />
    </div>
    <!-- 其他 -->
    <div v-else>
      <iframe :src="newUrl" class="wid-heig"></iframe>
    </div>
  </el-dialog>
</template>

<script>
  import VueOfficeDocx from "@vue-office/docx";
  import VueOfficeExcel from "@vue-office/excel";
  import "@vue-office/docx/lib/index.css";
  import "@vue-office/excel/lib/index.css";
  import axios from "axios";
  export default {
    name: "FilePreviewDialog",
    components: {
      VueOfficeDocx,
      VueOfficeExcel,
    },
    props: {
      // 文件数据
      fileData: {
        type: Object,
        default: () => ({
          name: '',
          url: '',
        }),
      },
      // 宽度
      width: {
        type: String,
        default: '50%',
      }
    },
    data() {
      return {
        // 是否显示
        dialogVisible: false,
      };
    },
    computed: {
      // 其他文件路径转化
      newUrl: function () {
        const utf8Encoder = new TextEncoder();
        const utf8Bytes = utf8Encoder.encode(this.fileData.url);
        const base64Encoded = btoa(String.fromCharCode(...utf8Bytes));
        const url = `https://kkfileview.fungsong.com/onlinePreview?url=${base64Encoded}`
        return url;
      }
    },
    mounted() {

    },
    methods: {
      // 获取文件类型
      getFileType(fileUrl) {
        if (fileUrl !== "" && fileUrl != null && fileUrl !== undefined) {
          const fileType = fileUrl.split(".").pop().toLowerCase(); // 文件的扩展名（格式）
          return fileType;
        } else {
          return "docx";
        }
      },
      // 下载
      downLoad() {
        axios
          .get(this.fileData.url, { responseType: "blob" })
          .then((response) => {
            const blob = new Blob([response.data]);
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = this.fileData.name;
            link.click();
            URL.revokeObjectURL(link.href);
          })
      },
      // 关闭
      handleClose(done) {
        this.$emit("closePreviewDialog", false);
      },
    },
  };
</script>
<style scoped>
  .wid-heig {
    width: 100%;
    height: 70vh;
    overflow: auto;
  }
</style>