var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "审批流程",
                "close-on-click-modal": false,
                visible: _vm.dialogVisible,
                width: _vm.width,
                "modal-append-to-body": false,
                "custom-class": "evaluate-dialog"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                    "label-position": "top"
                  },
                  on: { close: _vm.close }
                },
                [
                  _vm.message == 1
                    ? _c("p", { staticStyle: { "font-size": "18px" } }, [
                        _c("i", {
                          staticClass: "el-icon-success",
                          staticStyle: {
                            "margin-right": "10px",
                            color: "#67C23A"
                          }
                        }),
                        _vm._v("您已成功提交该表单，是否需通过该审批？")
                      ])
                    : _vm._e(),
                  _c("div", {
                    ref: "inputArea",
                    staticClass: "inputArea",
                    attrs: { contenteditable: true },
                    on: { keydown: _vm.handleKeyDown }
                  })
                ]
              ),
              _c("div", { staticClass: "tag" }, [
                _c("p", { staticStyle: { "margin-bottom": "8px" } }, [
                  _vm._v("推荐回复")
                ]),
                _c("div", { staticClass: "tag_reply" }, [
                  _c(
                    "div",
                    { staticClass: "tag_list" },
                    [
                      _vm._l(_vm.tagList, function(item, index) {
                        return _c(
                          "el-tag",
                          {
                            key: index,
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                return _vm.chooseTag(item)
                              }
                            }
                          },
                          [_vm._v(_vm._s(item))]
                        )
                      }),
                      _vm._l(_vm.CommentList, function(item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "tag_add",
                            class: { active: !_vm.isDelete, die: _vm.isDelete },
                            on: {
                              click: function($event) {
                                return _vm.chooseTag(item.tagName)
                              }
                            }
                          },
                          [
                            _vm._v(" " + _vm._s(item.tagName) + " "),
                            _vm.isDelete
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/ioc/minus-circle.png"),
                                    alt: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteComment(item.id)
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _c("div", { staticClass: "tag_operate" }, [
                    !_vm.isDelete
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/ioc/plus-add.png"),
                            alt: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.addComment = true
                            }
                          }
                        })
                      : _vm._e(),
                    !_vm.isDelete
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/ioc/minus-circle.png"),
                            alt: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.isDelete = true
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.isDelete
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/ioc/times-circle.png"),
                            alt: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.isDelete = false
                            }
                          }
                        })
                      : _vm._e()
                  ])
                ])
              ]),
              _c("div", { staticClass: "file" }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-upload",
                      {
                        attrs: {
                          action: _vm.uploadAction,
                          "show-file-list": false,
                          data: _vm.uploadData,
                          "on-success": function(res, file, fileList) {
                            return _vm.handleSuccess(res, file, fileList, 1)
                          },
                          multiple: "",
                          "before-upload": function(file) {
                            return _vm.beforeAvatarUpload(file, 1)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "el-icon-picture-outline" }),
                        _c("span", [_vm._v("图片")])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-upload",
                      {
                        attrs: {
                          action: _vm.uploadAction,
                          "show-file-list": false,
                          data: _vm.uploadData,
                          multiple: "",
                          "on-success": function(res, file, fileList) {
                            return _vm.handleSuccess(res, file, fileList, 2)
                          },
                          "before-upload": function(file) {
                            return _vm.beforeAvatarUpload(file, 2)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "el-icon-link" }),
                        _c("span", [_vm._v("附件")])
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "picture_list" },
                _vm._l(_vm.ruleForm.picture, function(item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c("el-image", {
                        staticStyle: { width: "50px", height: "50px" },
                        attrs: {
                          src: item.url,
                          "preview-src-list": _vm.previewList
                        }
                      }),
                      _c("div", { staticClass: "del_box" }, [
                        _c("i", {
                          staticClass: "el-icon-close",
                          staticStyle: { cursor: "pointer", color: "#fff" },
                          on: {
                            click: function($event) {
                              return _vm.delPicture(index)
                            }
                          }
                        })
                      ])
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "attachment_list" },
                _vm._l(_vm.ruleForm.attachment, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      style: {
                        borderTop: index == 0 ? "1px solid #c9c9c9" : "none"
                      }
                    },
                    [
                      _c("div", [
                        _c("i", {
                          staticClass: "el-icon-document",
                          staticStyle: { "font-size": "28px" }
                        }),
                        _c("p", [_vm._v(_vm._s(item.name))])
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "el-link",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.preview(item)
                                }
                              }
                            },
                            [_vm._v("预览")]
                          ),
                          _c("i", {
                            staticClass: "el-icon-delete",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                return _vm.delAttachment(index)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    height: "20px",
                    "align-items": "center"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "16px",
                        height: "16px",
                        "margin-right": "4px"
                      }
                    },
                    [
                      _vm.isNotice
                        ? _c("img", {
                            staticStyle: {
                              width: "100%",
                              height: "100%",
                              cursor: "pointer"
                            },
                            attrs: {
                              src: require("@/assets/ioc/check4.png"),
                              alt: ""
                            },
                            on: {
                              click: function($event) {
                                _vm.isNotice = false
                              }
                            }
                          })
                        : _c("img", {
                            staticStyle: {
                              width: "100%",
                              height: "100%",
                              cursor: "pointer"
                            },
                            attrs: {
                              src: require("@/assets/ioc/check3.png"),
                              alt: ""
                            },
                            on: {
                              click: function($event) {
                                _vm.isNotice = true
                              }
                            }
                          })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "aaaaaa",
                      staticStyle: { height: "20px", "line-height": "20px" }
                    },
                    [
                      _c("span", [_vm._v("审批意见通过聊天转发给")]),
                      _c("span", { staticClass: "sss" }, [
                        _vm._v(_vm._s(_vm.processInstance.startUser.userName))
                      ]),
                      _vm._l(_vm.atList, function(item) {
                        return _c(
                          "span",
                          { key: item.userId, staticClass: "sss" },
                          [_vm._v(_vm._s(item.userName))]
                        )
                      })
                    ],
                    2
                  )
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "cancel",
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v(" 取消 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "submit",
                      on: {
                        click: function($event) {
                          return _vm.fangd("ruleForm")
                        }
                      }
                    },
                    [_vm._v(" 确定 ")]
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增回复",
            "modal-append-to-body": false,
            visible: _vm.addComment,
            width: "30%",
            "custom-class": "evaluate-dialog"
          },
          on: {
            "update:visible": function($event) {
              _vm.addComment = $event
            },
            close: function($event) {
              _vm.reply = ""
            }
          }
        },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入" },
            model: {
              value: _vm.reply,
              callback: function($$v) {
                _vm.reply = $$v
              },
              expression: "reply"
            }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "div",
                {
                  staticClass: "submit",
                  staticStyle: { width: "100%", "margin-left": "0px" },
                  on: { click: _vm.debouncedAddReply }
                },
                [_vm._v(" 确定 ")]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }