<template>
  <el-row class="app-container" v-loading="loading">
    <!-- 侧边栏 -->
    <el-col :span="4" class="sidebar">
      <div class="title" style="display: flex;align-items: center">全部审批
      </div>

      <div class="category-list" @mouseenter="isManual = true" @mouseleave="isManual = false">
        <div v-for="item in classificationData" :key="item.id" @click="handleSelect(item.id)"
          :class="activeMenu == item.id ? 'select' : ''" class="category">
          <span class="list-item">{{ item.name }}</span>

        </div>
      </div>
    </el-col>

    <!-- 内容区域 -->
    <el-col :span="20" class="approval-content" ref="scrollContainer" @scroll="handleScroll">
      <el-input v-model="searchValue" placeholder="请输入流程名称" class="input" @input="getClassificationData">
        <template #prefix>
          <img src="@/assets/ioc/Group_1000005290.png" alt="custom icon" class="input-icon">
        </template>
      </el-input>
      <div class="section" v-for="item in classificationData" :key="item.id" :ref="item.id">
        <div class="title">{{ item.name }}</div>
        <div class="approval-list">
          <div class="approval-item" @click="openForm(approval)" v-for="approval in List[item.name]" :key="approval.id">
            <div class="approval-icon">
              <img
                :src="approval.icon == 'https://cdn.fengwork.com/sosee_oa/20240119icon.png' ? 'https://cdn.fengwork.com/workbench/Group_1000005531.png' : approval.icon"
                alt="图标">
            </div>
            <div class="approval-name">{{ approval.name }}</div>
          </div>
        </div>
      </div>
    </el-col>

  </el-row>
</template>

<script>
  import { groupBy, size } from "lodash-es";
  import { getTaskList, createForm } from '@/api/Home';
  // import draggable from "vuedraggable";
  export default {
    // components: { draggable },
    name: 'Home',
    data() {
      return {
        loading: true,
        activeMenu: '', // 默认选中项
        navbarHeight: 60, // 导航栏的高度，单位为px
        classificationData: [], // 分类数据
        List: [], // 列表数据
        searchValue: '', // 搜索框内容
        isManual: false, // 是否代码触发滚动
        isSortBt: true
      };
    },
    async mounted() {
      // 确保在 DOM 挂载后添加事件监听器
      this.initScrollListener();
      await this.getClassificationData();
      this.activeMenu = this.classificationData[0].id;
      this.loading = false;
    },
    computed: {

    },
    async activated() {
      await this.getClassificationData();
    },
    beforeDestroy() {
      // 组件销毁前移除事件监听器
      this.removeScrollListener();
    },
    methods: {
      aaaa(event) {
        event.preventDefault()
        console.log(event)
      },
      // 侧边栏点击事件
      handleSelect(key) {
        const element = this.$refs[key][0];
        if (element) {
          const offsetTop = element.offsetTop - this.navbarHeight + 42;
          this.scrollToPosition(offsetTop, key);
          this.activeMenu = key;
        }
      },
      // 定位内容
      scrollToPosition(position, key) {
        const scrollContainer = this.$refs['scrollContainer'].$el
        // 获取滚动容器的最大可滚动距离
        const maxScrollTop = scrollContainer.scrollHeight - scrollContainer.clientHeight + 100;
        scrollContainer.scrollTo({
          top: position,
          behavior: 'smooth',
        });
      },

      // 绑定滚动
      initScrollListener() {
        const container = this.$refs.scrollContainer;
        container.$el.addEventListener('scroll', this.handleScroll);
      },
      // 滚动事件
      handleScroll() {
        if (this.isManual) return 
        const scrollContainer = this.$refs.scrollContainer.$el;
        const sections = this.classificationData.map(item => ({ id: item.id, offsetTop: this.$refs[item.id][0].offsetTop }));
        const scrollTop = scrollContainer.scrollTop;
        let currentSection = sections[0].id;
        for (let i = 0; i < sections.length; i++) {
          if (scrollTop >= sections[i].offsetTop - scrollContainer.offsetTop - 42) {
            currentSection = sections[i].id;
          } else {
            break;
          }
        }
        this.activeMenu = currentSection;
      },
      // 移除滚动事件
      removeScrollListener() {
        const container = this.$refs.scrollContainer;
        container.$el.removeEventListener('scroll', this.handleScroll);
      },

      // 获取数据
      async getClassificationData() {
        const { data } = await getTaskList({ name: this.searchValue });
        const groupByList = groupBy(data, "groupName");
        let category = [];
        for (const key in groupByList) {
          if (groupByList.hasOwnProperty(key)) {
            category.push({
              id: groupByList[key][0].groupId,
              name: key,
              sort: groupByList[key][0].groupSort
            });
          }
        }
        category.sort((a, b) => a.sort - b.sort)
        const sorted = {};
        for (const key in groupByList) {
          if (Array.isArray(groupByList[key])) {
            sorted[key] = groupByList[key].slice().sort((a, b) => a.flowSort - b.flowSort);
          } else {
            sorted[key] = groupByList[key];
          }
        }
        this.List = sorted;
        this.classificationData = category;
      },

      // 跳转
      openForm(approval) {
        this.$router.push({ name: 'Create', query: approval });
      }
    },
  };
</script>

<style scoped lang="scss">
  /* 设置容器的高度，保证内容区域独立滚动 */
  .app-container {
    height: calc(100vh - 152px);
    display: flex;
    overflow: hidden;
    padding: 12px;
  }

  /* 侧边栏固定位置 */
  .sidebar {
    padding: 16px;
    height: 100%;
    overflow: auto;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    border-radius: 8px;
    // border-right: 1px solid #e8e8e8;

    .title {
      text-align: left;
      color: rgba(27, 27, 27, 1);
      font-family: "Noto Sans SC";
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 16px;
    }

    .category-list {
      // margin-top: 16px;

    }

    .category {
      text-align: left;
      line-height: 32px;
      margin-bottom: 10px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .list-item {
        margin-left: 10px;
        color: var(--字体黑, rgba(27, 27, 27, 1));
        font-family: "Noto Sans SC";
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
      }
    }

    .categoryLi {
      display: flex;
      align-items: center;
      height: 32px;
      margin-bottom: 10px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .list-item {
        margin-left: 2px;
        color: var(--字体黑, rgba(27, 27, 27, 1));
        font-family: "Noto Sans SC";
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
      }

    }
  }

  .select {
    border-radius: 4px;
    background: rgba(243, 244, 246, 1);
    color: var(--字体黑, rgb(27, 27, 27)) !important;
  }

  /* 内容区域可以滚动 */
  .approval-content {
    text-align: justify;
    height: 100%;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 100px;

    .input {
      // float: left;
      width: 176px;

      .input-icon {
        width: 16px;
        height: 16px;
        margin-left: 10px;
      }
    }
  }


  .section {
    margin-top: 20px;
    padding-left: 16px;

    .title {
      color: var(--字体黑, rgba(27, 27, 27, 1));
      font-family: "Noto Sans SC";
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
    }

    .approval-list {
      margin-top: 10px;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      .approval-item {
        width: 178px;
        height: 56px;
        border-radius: 8px;
        border: 1px solid rgba(243, 244, 246, 1);
        box-sizing: border-box;
        background: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        cursor: pointer;

        .approval-icon {
          margin-left: 10px;
          margin-right: 10px;

          img {
            width: 36px;
            height: 36px;
          }
        }

        .approval-name {
          color: var(--字体黑, rgba(27, 27, 27, 1));
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          padding-right: 10px;
          width: 120px;
          overflow-wrap: break-word;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          overflow: hidden;
        }
      }
    }


  }

  :deep(.el-menu-item.is-active) {
    border-radius: 4px;
    background: rgba(243, 244, 246, 1);
  }

  ::v-deep .el-input__prefix {
    left: 8px;
    top: 10px;
    transition: all .3s;
  }

  ::v-deep .el-input__inner {
    -webkit-appearance: none;
    background-color: rgb(243, 244, 246);
    background-image: none;
    border-radius: 4px;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 35px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    width: 100%;
  }

  /* 滚动条样式 */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* 滑块样式 */
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 246, 249, 1);
    border-radius: 10px;
  }

  /* 滚动条轨道样式 */
  ::-webkit-scrollbar-track {
    background-color: #fcfcfc;
    border-radius: 2px;
  }
</style>