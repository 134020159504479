var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "100vh" } }, [
    _c("embed", {
      attrs: {
        src: _vm.URL,
        width: "100%",
        height: "100%",
        type: "application/pdf"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }