import Vue from 'vue'
import VueRouter from 'vue-router'
// 页面组件
import Home from '../views/Home.vue'
import ApprovalCenter from '../views/ApprovalCenter.vue'
import DataManagement from '../views/DataManagement.vue'
import DataBoard from '../views/DataBoard.vue'
import ManagementBackend from '../views/ManagementBackend.vue'
import Navigate from '../views/navigate.vue'
import Create from '@/views/create.vue'
import OperationRecord from '@/views/OperationRecord.vue'
import MobileDetail from '@/components/mobileDetail'
import previewPDF from '@/components/previewPDF'
import Submited from '@/views/submited.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/main/workbench/Workflow',
    name: 'Navigate',
    component: Navigate,
    redirect: '/main/workbench/Workflow/Navigate/home',
    children: [
      {
        path: '/main/workbench/Workflow/Navigate/home',
        name: 'Home',
        component: Home,
        meta: {
          title: "首页",
          icon: "ioc/home-dash.png",
        },
      },
      {
        path: '/main/workbench/Workflow/Navigate/approvalCenter',
        name: 'ApprovalCenter',
        component: ApprovalCenter,
        meta: {
          title: "审批中心",
          icon: "ioc/hourglass.png",
        },
      },
      {
        path: '/main/workbench/Workflow/Navigate/dataManagement',
        name: 'DataManagement',
        component: DataManagement,
        meta: {
          title: "数据管理",
          icon: "ioc/chart-bar-alt.png",
          menuId: '1733020752463454210'
        },
      },
      {
        path: '/main/workbench/Workflow/Navigate/OperationRecord',
        name: 'OperationRecord',
        component: OperationRecord,
      },
    ]
  },
  {
    path: '/main/workbench/Workflow/create',
    name: 'Create',
    component: Create,
  },
  {
    path: '/main/workbench/Workflow/submited',
    name: 'Submited',
    component: Submited,
  },
  {
    path: '/main/workbench/Workflow/mobileDetail',
    name: 'MobileDetail',
    component: MobileDetail,
  },
  {
    path: '/main/workbench/Workflow/previewPDF',
    name: 'previewPDF',
    component: previewPDF,
  },
]

export default routes


