<template>
    <div style="height: 100vh;">
        <embed :src="URL" width="100%" height="100%" type="application/pdf">
    </div>
</template>

<script>
    export default {
        name: 'HFIMElectronWorkbenchIndex',

        data() {
            return {
                URL: '',
            };
        },

        mounted() {
            this.URL = this.$route.query.url;
        },

        methods: {

        },
    };
</script>