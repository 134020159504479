<script>
  import relatedApproval from '@/components/relatedApproval';
  import axios from "axios";
  import { deepClone, getType } from "@/utils/index";
  import render from "@/components/render/render.js";
  import { conforms, debounce } from "lodash-es";
  import { exportPdf } from "@/api/bpm/form";
  import {
    uploadURLFromRegionCode,
    init,
    config,
    DOMAIN,
  } from "@/utils/qiniuUploader.js";
  var turn = false;
  var start = true
  var uniqueArray = [];
  var indexInput = 0;
  function changeNumMoneyToChinese(money) {
    var cnNums = new Array(
      "零",
      "壹",
      "贰",
      "叁",
      "肆",
      "伍",
      "陆",
      "柒",
      "捌",
      "玖"
    ); //汉字的数字
    var cnIntRadice = new Array("", "拾", "佰", "仟"); //基本单位
    var cnIntUnits = new Array("", "万", "亿", "兆"); //对应整数部分扩展单位
    var cnDecUnits = new Array("角", "分", "毫", "厘"); //对应小数部分单位
    var cnInteger = "整"; //整数金额时后面跟的字符
    var cnIntLast = "元"; //整型完以后的单位
    var maxNum = 9999999999999999.9999; //最大处理的数字
    var IntegerNum; //金额整数部分
    var DecimalNum; //金额小数部分
    var ChineseStr = ""; //输出的中文金额字符串
    var parts; //分离金额后用的数组，预定义
    var Symbol = ""; //正负值标记
    if (money == "") {
      return "";
    }

    money = parseFloat(money);
    if (money >= maxNum) {
      // alert('超出最大处理数字');
      return "大写转换最高支持十六位数的金额";
    }
    if (money == 0) {
      ChineseStr = cnNums[0] + cnIntLast + cnInteger;
      return ChineseStr;
    }
    if (money < 0) {
      money = -money;
      Symbol = "负 ";
    }
    money = money.toString(); //转换为字符串
    if (money.indexOf(".") == -1) {
      IntegerNum = money;
      DecimalNum = "";
    } else {
      parts = money.split(".");
      IntegerNum = parts[0];
      DecimalNum = parts[1].substr(0, 4);
    }
    if (parseInt(IntegerNum, 10) > 0) {
      //获取整型部分转换
      var zeroCount = 0;
      var IntLen = IntegerNum.length;
      for (var i = 0; i < IntLen; i++) {
        var n = IntegerNum.substr(i, 1);
        var p = IntLen - i - 1;
        var q = p / 4;
        var m = p % 4;
        if (n == "0") {
          zeroCount++;
        } else {
          if (zeroCount > 0) {
            ChineseStr += cnNums[0];
          }
          zeroCount = 0; //归零
          ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
        }
        if (m == 0 && zeroCount < 4) {
          ChineseStr += cnIntUnits[q];
        }
      }
      ChineseStr += cnIntLast;
      //整型部分处理完毕
    }
    if (DecimalNum != "") {
      //小数部分
      var decLen = DecimalNum.length;
      for (var i = 0; i < decLen; i++) {
        var n = DecimalNum.substr(i, 1);
        if (n != "0") {
          ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
        }
      }
    }
    if (ChineseStr == "") {
      ChineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (DecimalNum == "") {
      ChineseStr += cnInteger;
    }
    ChineseStr = Symbol + ChineseStr;

    return ChineseStr;
  }
  function toChineseString(val) {
    let money = parseFloat(parseFloat(val).toFixed(4));

    var cnNums = new Array(
      "零",
      "壹",
      "贰",
      "叁",
      "肆",
      "伍",
      "陆",
      "柒",
      "捌",
      "玖"
    );
    //基本单位
    var cnIntRadice = new Array("", "拾", "佰", "仟");
    //对应整数部分扩展单位
    var cnIntUnits = new Array("", "万", "亿", "兆");
    //对应小数部分单位
    var cnDecUnits = new Array("角", "分", "毫", "厘");
    //整数金额时后面跟的字符
    var cnInteger = "整";
    //整型完以后的单位
    var cnIntLast = "元";
    //最大处理的数字
    var maxNum = 9999999999999999.9999;
    //金额整数部分
    var integerNum;
    //金额小数部分
    var decimalNum;
    //输出的中文金额字符串
    var chineseStr = "";
    //分离金额后用的数组，预定义
    var parts;

    var a = "";

    if (money == "" || isNaN(money)) {
      return "";
    }
    money = parseFloat(money);
    if (money >= maxNum) {
      //超出最大处理数字
      return "大写转换最高支持十六位数的金额";
    }
    if (money == 0) {
      chineseStr = cnNums[0] + cnIntLast + cnInteger;
      return chineseStr;
    }

    // if (money <= 0) {
    //   a = "负";
    // }

    //转换为字符串
    money = money.toString();
    if (money.indexOf(".") == -1) {
      integerNum = money;
      decimalNum = "";
    } else {
      parts = money.split(".");
      integerNum = parts[0];
      decimalNum = parts[1].substr(0, 4);
    }
    //获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
      var zeroCount = 0;
      var IntLen = integerNum.length;
      for (var i = 0; i < IntLen; i++) {
        var n = integerNum.substr(i, 1);
        var p = IntLen - i - 1;
        var q = p / 4;
        var m = p % 4;
        if (n == "0") {
          zeroCount++;
        } else {
          if (zeroCount > 0) {
            chineseStr += cnNums[0];
          }
          //归零
          zeroCount = 0;
          chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
        }
        if (m == 0 && zeroCount < 4) {
          chineseStr += cnIntUnits[q];
        }
      }
      chineseStr += cnIntLast;
    }
    //小数部分
    if (decimalNum != "") {
      var decLen = decimalNum.length;
      for (var i = 0; i < decLen; i++) {
        var n = decimalNum.substr(i, 1);
        if (n != "0") {
          chineseStr += cnNums[Number(n)] + cnDecUnits[i];
        }
      }
    }
    if (chineseStr == "") {
      chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum == "") {
      chineseStr += cnInteger;
    }

    return chineseStr;
  }

  const ruleTrigger = {
    "el-input": "blur",
    "el-input-number": "blur",
    "el-select": "change",
    "el-radio-group": "change",
    "el-checkbox-group": "change",
    "el-cascader": "change",
    "el-time-picker": "change",
    "el-date-picker": "change",
    "el-rate": "change",
    "el-upload": "change",
  };
  function debounce2(func, delay) {
    let timeoutId;

    return function (isCache) {
      const context = this;

      clearTimeout(timeoutId);

      timeoutId = setTimeout(function () {
        func(isCache);
      }, delay);
    };
  }
  function debounce1(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  const layouts = {
    colFormItem(h, scheme) {
      const config = scheme.__config__;
      const listeners = buildListeners.call(this, scheme);
      let labelWidth = config.labelWidth ? `${config.labelWidth}px` : null;
      if (config.showLabel === false) labelWidth = "0";
      // 转换defaultValue为大写
      if (config.type == "amountMoney" && turn == false) {
        if (config.formula?.length > 0) {

          // 返会所属的行容器对象
          const getRowContainer = (scheme) => {
            const rowContainer = this.formConfCopy.fields.filter(item => item.__config__.tag === "el-row");
            let data = false;
            rowContainer.forEach(element => {
              const foundItem = element.__config__.children.flat().find(item => item.__vModel__ === scheme.__vModel__);
              if (foundItem) {
                data = element;
              }
            });
            return data;
          }
          let obj = this[this.formConfCopy.formModel];
          let copyObj = JSON.parse(JSON.stringify(obj));
          const timeRubbing = scheme.__vModel__.split("-").length == 2 ? "-" + scheme.__vModel__.split("-")[1] : "";
          const isRow = getRowContainer(scheme);
          let arr = config.formula.map((item) => {
            if (item.type === 1) {
              if (isRow) {
                return copyObj[item.val.__vModel__ + timeRubbing];
              } else {
                const value = Object.keys(copyObj)
                  .filter(key => key.startsWith(item.val.__vModel__ + timeRubbing))  // 筛选出以指定前缀开头的键
                  .reduce((acc, key) => acc + Number(copyObj[key]), 0);
                return value;
              }
            } else {
              return item.val;
            }
            return item;
          });
          const hasUndefined = arr.some(
            (item) => item === undefined || item === null || item === ""
          );

          if (!hasUndefined) {
            let arrJson = arr.join("");
            let answer = eval(arrJson);
            this.$set(config, "defaultValue", answer);
            obj[scheme.__vModel__] = answer;
          }
        }
      }
      if (config.ifBig) {
        var upperCaseValue = config.defaultValue
          ? changeNumMoneyToChinese(config.defaultValue)
          : "";
      }
      if (config.tagIcon == 'date-range' && config.ifRange && config.defaultValue?.length == 2) {
        const startDate = new Date(config.defaultValue[0]);
        const endDate = new Date(config.defaultValue[1]);
        const diffInMs = endDate - startDate;
        if (scheme.type == 'datetimerange') {
          const hours = Math.ceil((diffInMs / (1000 * 60 * 60)) * 10) / 10;
          config.rangTime = hours + '小时'
        } else {
          const millisecondsPerDay = 24 * 60 * 60 * 1000;
          const diffInDays = Math.floor(diffInMs / millisecondsPerDay) + 1;
          config.rangTime = diffInDays + '天'
        }
      }
      if (config.tagIcon == 'time-range' && config.ifRange && config.defaultValue?.length == 2) {
        // 将时间字符串转换为秒数
        function timeToSecondsAll(timeStr) {
          const [hours, minutes, seconds] = timeStr.split(':').map(Number);
          return hours * 3600 + minutes * 60 + seconds;
        }
        const startTimeInSeconds = timeToSecondsAll(config.defaultValue[0]);
        const endTimeInSeconds = timeToSecondsAll(config.defaultValue[1]);
        // 计算时间差，以秒为单位
        const diffInSeconds = endTimeInSeconds - startTimeInSeconds;
        if (diffInSeconds < 0) {
          console.error("结束时间必须在开始时间之后");
          return null;
        }
        if (diffInSeconds < 60) {
          // 小于60秒，返回秒数
          config.rangTime = `${diffInSeconds} 秒`;
        } else if (diffInSeconds < 3600) {
          // 小于60分钟，返回分钟和秒
          const minutes = Math.floor(diffInSeconds / 60);
          const seconds = diffInSeconds % 60;
          config.rangTime = `${minutes} 分 ${seconds} 秒`;
        } else {
          // 大于或等于60分钟，可以进一步扩展到小时等
          const hours = Math.floor(diffInSeconds / 3600);
          const minutes = Math.floor((diffInSeconds % 3600) / 60);
          const seconds = diffInSeconds % 60;
          config.rangTime = `${hours} 小时 ${minutes} 分 ${seconds} 秒`;
        }
      }
      //后端要求添加属性
      if (scheme.display === true) {
        return;
      }
      if (scheme.__config__.tagIcon == 'upload' && start == true) {
        scheme['list-type'] = 'picture'
        start = false
        let that = this
        this.$nextTick(() => {
          const switchImgFile = debounce(function () {
            const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
            let dom = that.$refs[scheme.__vModel__].$el
            const innerDiv = dom.querySelector('.el-form-item__content > div');
            innerDiv.style.width = '100%';
            const images = dom.querySelectorAll('img');
            config.defaultValue.forEach((item, index) => {
              const extension = item.fileName.split('.').pop().toLowerCase();
              if (!imageExtensions.includes(extension)) {
                images[images.length - config.defaultValue.length + index].src = 'https://cdn.fengwork.com/workbench/Group_101.png'
              }
            })
          }, 200);
          switchImgFile()
        }, 200)
      }

      if (scheme.__config__.type === "approval") {
        if (this.nodesInfo[scheme.__vModel__]) {
        } else {
          if (config.defaultValue.length > 0) {
            this.nodesInfo[scheme.__vModel__] = config.defaultValue;
            this.nodesInfoCopy[scheme.__vModel__] = config.defaultValue;
          }
        }
      }
      if (scheme.__config__.type == "contacts" || scheme.__config__.type == "litigant") {
        let a = scheme.__config__.defaultValue
        if (scheme.multiple) {
          a.forEach((e, i, s) => s.toString())
        } else {
          a = scheme.__config__.defaultValue.toString()
        }
        this.$set(scheme.__config__, 'defaultValue', a)
      }
      return (
        <el-col span={config.span}>
          <el-form-item
            label-width={labelWidth}
            prop={scheme.__vModel__}
            label={config.showLabel ? config.label : ""}
            ref={scheme.__vModel__}
            class={
              scheme.__config__.tag === "el-upload" && scheme.disabled == true
                ? "hideUpload"
                : ""
            }
          >
            <render conf={scheme} on={listeners} />
            {config.ifBig ? (
              <div style="font-weight:bold;">
                大写 : <span style="font-weight:normal;">{upperCaseValue}</span>
              </div>
            ) : null}
            {config.ifRange ? (
              <div style="font-weight:bold;">
                时长 : <span style="font-weight:normal;">{config.rangTime}</span>
              </div>
            ) : null}
            {config.type == "approval" ? (
              <div style="width: 100%;">
                {this.nodesInfo[scheme.__vModel__]?.map((item, index) => (
                  <el-tag
                    key={index}
                    onClick={() => this.handleClick(item)}
                    onClose={() => this.handleClose(item, index, scheme)}
                    closable={scheme.disabled ? false : true}
                    type="primary"
                    style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}
                  >
                    {item?.label || ''}
                  </el-tag>
                ))}
              </div>
            ) : null}
            {config.type == "link" ? (
              <div>
                <el-link
                  onClick={() => this.handleLink(config)}
                  type="primary"
                  style="display:block"
                >
                  {config.defaultValue}
                </el-link>
              </div>
            ) : null}


          </el-form-item>
        </el-col>
      );
    },
    rowFormItem(h, scheme) {
      let child = renderChildren.apply(this, [h, scheme]);
      const SumVnode = getSumDom.apply(this, [h, scheme]);
      return (
        !scheme.display && (
          <el-col span={24} >
            <div class="table-box">
              {scheme.__config__.form.填写方式 === "表格" ? (
                <el-row class="table-row header-row isTable">
                  <el-col class="table-header first">名称</el-col>
                  {scheme.__config__.children[0].map((item, index) => (
                    <el-col class="table-header">{item.__config__.label}</el-col>
                  ))}
                  <el-col class="table-header last">操作</el-col>
                </el-row>
              ) : (
                ""
              )}

              {child.map((copyItem, index) => (
                <el-row
                  class={
                    scheme.__config__.form.填写方式 === "表格"
                      ? "table-row"
                      : "ul-row"
                  }
                >
                  {/* <div class="itemTitle">
                <div class="text">
                  {scheme.__config__.form.填写方式 + (index + 1)}
                </div>

                {!this.disabledReset && (
                  <el-button
                    type="text"
                    disabled={index === 0}
                    onClick={this.deleteItem(scheme, index)}
                  >
                    删除
                  </el-button>
                )}
              </div> */}

                  <div
                    class={{
                      itemContent: true,
                      isTable: scheme.__config__.form.填写方式 === "表格",
                      isList: scheme.__config__.form.填写方式 !== "表格",
                    }}
                  >
                    <el-col class="first">
                      {scheme.__config__.form.标题 + (scheme.__config__.form.type ? '' : index + 1)}
                    </el-col>
                    {copyItem}
                    <el-col class="last">
                      <el-button
                        type="text"
                        disabled={!scheme.disabled ? index === 0 : true}
                        onClick={this.deleteItem(scheme, index)}
                      >
                        删除
                      </el-button>
                    </el-col>
                  </div>

                  {/* {copyItem.map((item, index) => ({ item }))} */}
                </el-row>
              ))}

              {(SumVnode && scheme.__config__.children.length > 1) ?

                <el-row class={
                  scheme.__config__.form.填写方式 === "表格"
                    ? "table-row"
                    : "ul-row"
                }>

                  <div
                    class={{
                      itemContent: true,
                      isTable: scheme.__config__.form.填写方式 === "表格",
                      isList: scheme.__config__.form.填写方式 !== "表格",
                    }}
                  >
                    <el-col class="first">
                      合计
                    </el-col>
                    {SumVnode}
                    <el-col class="last">

                    </el-col>


                  </div>
                </el-row>
                : null
              }


              {!scheme.disabled && !scheme.__config__.form.type && (

                <el-button
                  type="text"
                  icon="el-icon-plus"
                  class="add-btn"
                  onClick={this.copyItem(scheme)}
                >
                  {scheme.__config__.form.动作名称}
                </el-button>
              )}
            </div>
          </el-col>)
      );
    },
  };

  // 主渲染函数
  function renderFrom(h) {
    const { formConfCopy } = this;
    // 判断是否有计算公式
    if (formConfCopy.fields.length > 0) {
      let filteredArray = [];
      // 过滤出有计算公式的组件
      const guidArray = (array) => {
        array.forEach(item => {
          if (item.__config__.tag === "el-row") {
            guidArray(item.__config__.children[0])
          }
          if (item.__config__.formula?.length > 0) {
            filteredArray.push(item)
          }
        });
      }
      guidArray(formConfCopy.fields);
      if (filteredArray.length > 0) {
        const result = filteredArray.map((item) =>
          item.__config__.formula.filter((subItem) => subItem.type === 1)
        );
        const flattenedArray = result.reduce((acc, val) => acc.concat(val), []);
        const uidArray = flattenedArray.map((item) => item.val.__vModel__);
        uniqueArray = [...new Set(uidArray)];
      }
    }
    return (
      /* gutter={formConfCopy.gutter} */
      <div>
        <el-row gutter={formConfCopy.gutter}>
          <el-form
            style="width: 100%;"
            size={formConfCopy.size}
            label-position={formConfCopy.labelPosition}
            disabled={formConfCopy.disabled}
            hide-required-asterisk={formConfCopy.disabled}
            label-width={`${formConfCopy.labelWidth}px`}
            ref={formConfCopy.formRef}
            // model不能直接赋值 https://github.com/vuejs/jsx/issues/49#issuecomment-472013664
            props={{ model: this[formConfCopy.formModel] }}
            rules={this[formConfCopy.formRules]}
          >
            {renderFormItem.call(this, h, formConfCopy.fields)}
            {formConfCopy.formBtns && formBtns.call(this, h)}
          </el-form>
        </el-row>

        <relatedApproval ref="relatedApproval" onDetermine={determine}></relatedApproval>
      </div>
    );
  }

  function formBtns(h) {
    // const hasIdOne = this.formConfCopy.fields.some(obj => obj.__vModel__ =='litigant')
    // this.depp=hasIdOne?true:false
    this.depp = this.realDepartOptions?.length > 1 ? true : false
    // let labelWidth = config.labelWidth ? `${config.labelWidth}px` : null;
    const result = this.formConfCopy.fields.every(
      (item) => item.disabled == true
    );
    if (!this.disabled) return null;
    if (this.preview) {
      return (
        <el-col >
          {this.depp ?
            <el-form-item label={"实际部门"} required>
              <el-select v-model={this.candidateDept} placeholder="请选择" style="width: 100%" onChange={this.handleSelectChange}>
                {
                  this.realDepartOptions.map(e => (
                    <el-option key={e.deptId} label={e.deptName} value={e.deptId} />
                  ))
                }
              </el-select>
            </el-form-item> : null}
          <el-form-item size="large">
            <el-button onClick={this.resetForm}>重置</el-button>
            <el-button onClick={this.save}>保存</el-button>
            {/* disabled={this.submitDisabled} */}
            <el-button type="primary" onClick={this.submitForm}>
              提交
            </el-button>
          </el-form-item>
        </el-col>
      );
    }

    if (!this.disabledReset) {
      return (
        <el-col>
          <el-form-item size="large">
            <el-button type="primary" onClick={this.submitForm}>
              提交
            </el-button>

            <el-button onClick={this.resetForm}>重置</el-button>
          </el-form-item>
        </el-col>
      );
    } else {
      // if (this.submitDisabled) {
      //   return (
      //     <el-col>
      //       <el-form-item size="large">
      //         <el-button type="primary" onClick={this.submitForm}>
      //           提交
      //         </el-button>
      //       </el-form-item>
      //     </el-col>
      //   );
      // }
    }
  }

  // 表格内容渲染函数
  function renderFormItem(h, elementList) {
    const that = this;
    const data = this[this.formConf.formModel];
    // const formRef = that.$refs[that.formConf.formRef] // 这里直接添加有问题，此时还找不到表单 $refs
    return elementList.map((scheme) => {
      const config = scheme.__config__;
      const layout = layouts[config.layout];

      // edit by 火凤源码，解决 el-upload 上传的问题
      // 参考 https://github.com/JakHuang/form-generator/blob/master/src/components/parser/example/Index.vue 实现
      const vModel = scheme.__vModel__;
      const val = data[vModel];
      if (scheme.__config__.tag === "el-upload") {
        scheme["on-preview"] = function (file) {
          if (file.response) {
            that.$emit("onPreview", file, DOMAIN + "/" + file.response.key);
          } else {
            that.$emit("onPreview", file, file.url);
          }
        };

        scheme["on-success"] = function (response, file, fileList) {
          // 添加到 data 中
          const list = fileList.map((item) => {
            let fname = item.name.replace(/[\\\/:*?"<>|#]/g, "");
            if (item.response) {
              return {
                fileUrl: DOMAIN + "/" + item.response.key,
                fileName: item.name,
                fileType: item.raw.type,
              };
            } else {
              return {
                fileUrl: item.url,
                fileName: item.name,
                fileType: item.type,
              };
            }
          });
          this.$set(data, vModel, list);
          that.formConf.fields.forEach((item) => {
            if (item.__vModel__) {
              if (item.__vModel__ === vModel) {
                this.$set(item.__config__, "defaultValue", list);
              }
            } else {
              item.__config__.children.forEach((row) => {
                row.forEach((rowItem) => {
                  if (rowItem.__vModel__ === vModel) {
                    this.$set(rowItem.__config__, "defaultValue", list);
                  }
                });
              });
            }
          });
          // console.log(`output->this.formConf`, that.formConf)

          // this.$set(scheme.__config__, defaultValue, list);
          // this.$emit("changeUploadList");

          // 发起表单校验
          that.$refs[that.formConf.formRef].validateField(vModel);

          /* 获取表单 */
          that.getFlow();

          that.$nextTick(() => {
            const switchImgFile = debounce(function (list) {
              const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
              let dom = that.$refs[vModel].$el
              const innerDiv = dom.querySelector('.el-form-item__content > div');
              innerDiv.style.width = '100%';
              const images = dom.querySelectorAll('img');
              list.forEach((item, index) => {
                let b = item.fileName.slice((item.fileName.lastIndexOf(".") - 1 >>> 0) + 2);
                let bc = b.toLowerCase();
                let bci = imageExtensions.includes(bc);
                if (!bci) {
                  images[index].src = 'https://cdn.fengwork.com/workbench/Group_101.png'
                }
              })
            }, 200);
            switchImgFile(list)
          });

        };

        // 注意 on-remove 不能绑定箭头函数！！！
        scheme["on-remove"] = function (file, fileList) {
          // 移除从 data 中

          const prev = data[vModel] || [];
          const index = prev.findIndex((item) => {
            if (file.response) {
              return item.fileUrl.includes(file.response.key);
            } else {
              return item.fileName.includes(file.name);
            }
          });

          if (index === -1) {
            return;
          }

          prev.splice(index, 1); // 直接移除即可，无需重复 set，因为 array 是引用

          // 发起表单校验
          that.$refs[that.formConf.formRef].validateField(vModel);

          /* 获取表单 */
          that.getFlow();
        };
      }

      if (layout) {
        return layout.call(this, h, scheme);
      }
      throw new Error(`没有与${config.layout}匹配的layout`);
    });
  }

  function renderChildren(h, scheme) {
    const config = scheme.__config__;
    if (!Array.isArray(config.children)) return null;
    return config.children.map((item) => {
      return renderFormItem.call(this, h, item);
    });
  }


  // 获取合计虚拟dom
  function getSumDom(h, scheme) {
    const isFromTable = scheme.__config__.form.填写方式 === "表格";
    let data = {};
    scheme.__config__.children.forEach((eleme, index) => {
      eleme.forEach(time => {
        if (time.__config__.total) {
          if (index == 0) {
            data[time.__vModel__] = Number(time.__config__.defaultValue);
          } else {
            let vModel = time.__vModel__.split("-")[0]
            data[vModel] = Number(data[vModel]) + Number(time.__config__.defaultValue);
          }
        }
      })
    })

    if (Object.keys(data).length == 0) {
      return false
    }

    const vNodeList = scheme.__config__.children[0].map(item => {
      if (data[item.__vModel__]) {
        return isFromTable ? h('el-col', { ref: item.__vModel__ + '_Sum' }, data[item.__vModel__]) : h('el-col', { ref: item.__vModel__ + '_Sum', class: 'listSum' }, item.__config__.label + '：' + data[item.__vModel__])
      } else {
        return h('el-col', {}, '')
      }
    })

    return vNodeList;
  }


  function setValue(event, config, scheme) {

    if (config.type == 'selectOptions') {
      let data = this[this.formConf.formModel]
      if (this.candidateDept) {
        data.candidate_dept = this.candidateDept
      }
      this.$nextTick(() => {
        this.$emit(
          "preview",
          {
            conf: this.formConfCopy,
            values: data,
            formFields: this.formConf.fields,
          },
          false
        );
      }, 200);

    }
    if (config.type === "litigant" && event) {
      this.$emit("delCandidate_dept")
      this.candidateDept = ''
      let userId = event
      this.emitChangeLitigantDebounced(userId);
    }
    if (config.type === "approval") {
      this.$refs.relatedApproval.formIds = config.approval.join();
      this.$refs.relatedApproval.__vModel__ = scheme.__vModel__;
      this.$refs.relatedApproval.revokeShow = true;
      event = this.nodesInfo[scheme.__vModel__]
    }
    // 组合组件触发的逻辑
    if (config._type_) {
      let data = this[this.formConf.formModel]
      if (config.renderKey != '17241536285931724153628593') {
        this.$nextTick(() => {
          this.$emit(
            "combination",
            {
              conf: this.formConfCopy,
              values: data,
              formFields: this.formConf.fields,
              type: config._type_,
            },
            false
          );
        }, 200);
      }
    }


    const vModelList = scheme.__vModel__.split("-");
    const hasOne = uniqueArray.indexOf(vModelList[0]) == -1;


    if (hasOne == true) {
      turn = true;
    } else {
      turn = false;
    }
    console.log(event, config, scheme, "event1");

    this.$set(config, "defaultValue", event);
    this.$set(this[this.formConf.formModel], scheme.__vModel__, event);
    this.$emit("changefiles", {
      event,
      scheme,
    });

    this.getFlow();

  }

  // 选项关联弹出框确定事件
  function determine(config, vModel, that) {
    config.forEach((item) => {
      that.nodesInfo[vModel].push({
        label: item.name,
        value: item.processInstanceId,
        children: null
      })
    });
  };

  function buildListeners(scheme) {
    const config = scheme.__config__;
    const methods = this.formConf.__methods__ || {};
    const listeners = {};

    // 给__methods__中的方法绑定this和event
    Object.keys(methods).forEach((key) => {
      listeners[key] = (event) => methods[key].call(this, event);
    });
    // 响应 render.js 中的 vModel $emit('input', val)
    listeners.input = (event) => setValue.call(this, event, config, scheme);
    listeners.change = (event) => setValue.call(this, event, config, scheme);

    return listeners;
  }

  export default {
    components: {
      render,
      relatedApproval
    },
    created() {
      start = true

    },
    mounted() {
      if (this.formConf.formVariables) {
        this.previewForm();
      }
      this.debouncedFunction = debounce2(this.previewForm, 1000);
    },
    beforeDestroy() {
      this.$emit("clearCache");
    },
    props: {
      formConf: {
        type: Object,
        required: true,
      },
      conditionList: {
        type: Array,
        default: () => [],
      },
      initData: {
        type: Object,
        default: () => {
          return {};
        },
      },
      tasks: {
        type: Array,
        default: () => [],
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      disabledReset: {
        type: Boolean,
        default: false,
      },
      preview: {
        type: Boolean,
        default: false,
      },
      submitDisabled: {
        type: Boolean,
        default: false,
      },
      realDepartOptions: {
        type: Array,
        default: () => [],
      },
      candidate_dept: {
        type: [String, Number],
        default: null,
      },
    },
    watch: {
      formConf: {
        immediate: true,
        deep: true,
        handler(val) {
          console.log(`output->va22l`, val);
        },
      },
      $route(to, from) {
        // this.$router.go(0);
      },
    },
    inject: ["addRecord"],
    data() {
      const data = {
        debouncedFunction: null,
        formConfCopy: deepClone(this.formConf),
        [this.formConf.formModel]: {},
        [this.formConf.formRules]: {},
        maxSize: 100 * 1024 * 1024, // 最大文件大小（10MB）
        uptoken: "",
        uploadAction: "", //图片上传链接
        uploadData: {}, //图片上传参数
        beforerUploadName: "", //上传之前文件名
        fileList: [],
        files: [], // 选中的文件列表
        uploading: false, // 是否正在上传
        percent: 0, // 上传进度
        uploadUrl: "", // 上传地址
        nodesInfo: {},
        nodesInfoCopy: {},
        // realDepartOptions: [],
        depp: true,
        candidateDept: this.candidate_dept
      };
      this.initFormData(data.formConfCopy.fields, data[this.formConf.formModel]);
      this.buildRules(data.formConfCopy.fields, data[this.formConf.formRules]);


      return data;
    },
    methods: {
      // 创建一个防抖版本的 emitChangeLitigant 方法  
      emitChangeLitigantDebounced: debounce1(function (userId) {
        this.$emit("changeLitigant", userId);
      }, 400), // 设置防抖时间为 1000 毫秒（1 秒）  
      handleSelectChange(e) {
        console.log(`output->e`, e)
        this.getFlow(1)

      },
      handleClick(item) {
        let row = JSON.parse(JSON.stringify(item));
        const lastIndex = row.value.lastIndexOf('-');
        if (lastIndex !== -1) {
          // 获取最后一段值
          const lastPart = row.value.substring(lastIndex + 1);
          // 判断最后一段值的长度
          if (lastPart.length === 1) {
            // 如果长度为 1，去掉最后的 `-` 和这一段
            row.value = row.value.substring(0, lastIndex);
          }
          // 如果长度大于 1，则保持原值，不变
        }
        this.addRecord({ id: row.value, name: row.label })
        this.$emit("refresh", { id: row.value, name: row.label });
      },
      handleClose(item, index, scheme) {
        this.nodesInfo[scheme.__vModel__].splice(index, 1);
        // if (scheme.__config__.defaultValue?.length > 0) {
        //   if (Array.isArray(scheme.__config__.defaultValue[0])) {
        //     let arr = scheme.__config__.defaultValue.filter(i => i[2] != item.value)
        //     // console.log(`output->arr`, arr)
        //     this.$set(scheme.__config__, "defaultValue", arr)
        //     this.nodesInfo[scheme.__vModel__].splice(index, 1)
        //     // console.log(`output->`,this.nodesInfo[scheme.__vModel__])
        //     this.$set(this[this.formConf.formModel], scheme.__vModel__, arr);
        //   } else {
        //     this.nodesInfo[scheme.__vModel__].splice(index, 1)
        //     // if(item)

        //     // this.nodesInfoCopy[scheme.__vModel__].splice(index, 1)
        //   }
        // } else {
        //   this.nodesInfo[scheme.__vModel__].splice(index, 1)
        //   // this.nodesInfoCopy[scheme.__vModel__].splice(index, 1)
        // }
        // if (scheme.__config__.defaultValue?.length == 0 && this.nodesInfo[scheme.__vModel__].length > 0) {
        //   this.$set(scheme.__config__, "defaultValue", this.nodesInfo[scheme.__vModel__])
        //   this.$set(this[this.formConf.formModel], scheme.__vModel__, this.nodesInfo[scheme.__vModel__]);
        // }
      },
      // 跳转外部链接
      handleLink(item) {
        if (window.electron) {
          window.electron.createNewWindow(item.defaultValue, { width: 1800, height: 1000 });
        } else {
          window.open(item.defaultValue)
        }
      },

      getFlow(val) {
        /*
        输入的字段 = conditionList
  
        请求getTrack
      */

        /*
        输入的字段 < conditionList
        输入的字段 != conditionList
  
        读取缓存，如果没有缓存则请求getTrack并缓存
      */

        const vals = this[this.formConf.formModel];

        const isNoVal = Object.keys(vals).every((item) => {
          return (
            vals[item] === undefined ||
            vals[item] === "" ||
            vals[item] === false ||
            (vals[item] && vals[item].length === 0)
          );
        });

        const isCache =
          !this.conditionList.length ||
          !this.conditionList.some((item) => {
            return (
              vals[item] !== undefined &&
              vals[item] !== "" &&
              vals[item] &&
              vals[item].length !== 0
            );
          });

        // console.log(isNoVal, "isNoVal");
        // console.log(isCache, "isCache");


        if (isNoVal) {
          setTimeout(() => {
            this.$emit("clearTasks");
            if (val) {
              this.debouncedFunction && this.previewForm(isCache);
            }
          }, 500);
        } else {
          this.debouncedFunction && this.debouncedFunction(isCache);
          // this.debouncedFunction && this.previewForm(isCache);
          // this.previewForm(isCache)
        }
      },
      initFormData(componentList, formData) {
        componentList.forEach((cur) => {
          const config = cur.__config__;
          if (cur.__vModel__) {
            if (cur.__config__.tagIcon == "upload" && typeof cur.__config__.defaultValue == 'string') {
              cur.__config__.defaultValue = JSON.parse(cur.__config__.defaultValue)
            }
            // formData[cur.__vModel__] = config.defaultValue;
            this.$set(formData, cur.__vModel__, config.defaultValue);
          } else if (config.children && config.children.length) {
            config.children.forEach((item) => {
              item.forEach((row) => {
                this.$set(formData, row.__vModel__, row.__config__.defaultValue);
              });
            });
          }
        });

        this.originalArray = { ...this.formConf };
      },
      buildRules(componentList, rules) {
        let arr = componentList.map(e => {
          if (e.disabled === true || e.readonly === true) {
            // 如果 disabled 为 true，将 required 设置为 false
            e.__config__.required = false;
          }
          return e;
        })
        arr.forEach((cur) => {
          const config = cur.__config__;
          // config.regList=[]
          if (Array.isArray(config.regList)) {
            if (config.required) {
              const required = {
                required: config.required,
                message: cur.placeholder,
              };
              if (Array.isArray(config.defaultValue)) {
                // required.type = "array";
                // required.message = `请至少选择一个${config.label}`;
              }
              required.message === undefined &&
                (required.message = `${config.label}不能为空`);
              config.regList.push(required);
            }
            rules[cur.__vModel__] = config.regList.map((item) => {
              item.pattern && (item.pattern = eval(item.pattern));
              item.trigger = ruleTrigger && ruleTrigger[config.tag];
              return item;
            });
          }
          if (config.children && config.children[0])
            this.buildRules(config.children[0], rules);
        });
        setTimeout(() => {
          this.$refs[this.formConf.formRef]?.clearValidate();
        }, 0);
      },
      //关闭
      close() {
        this.$emit("close");
      },
      resetForm() {
        this.$refs[this.formConf.formRef].resetFields();
        this.$emit("clearState");
        this.$emit("clearTasks");
        this.$emit("delCandidate_dept");
        this.candidateDept = ''

        // 重置是判断是否有关联组件，执行关联组件的方法
        let filteredItems = this.formConf.fields.filter((item) => item.isAssociation == true);
        if (filteredItems.length > 0) {
          filteredItems.forEach((err) => {
            this.$emit("changefiles", { event: false, scheme: err });
          })
        }

        this.formConfCopy = deepClone(this.formConf);
      },
      save() {
        this.$emit("saveDraft", {
          conf: this.formConfCopy,
          values: this[this.formConf.formModel],
          formFields: this.formConf.fields,
        });
      },
      submitForm() {
        if (this.depp && !this.candidateDept) {
          this.$message.warning('发起人多部门需要选择实际发起部门！')
          return
        }
        if (!this.depp && this.candidateDept) {
          delete this[this.formConf.formModel].candidate_dept
        }
        this.$refs[this.formConf.formRef].validate((valid) => {
          if (!valid) return false;
          this.$emit("submit", {
            conf: this.formConfCopy,
            values: this[this.formConf.formModel],
            formFields: this.formConf.fields,
          });
          return true;
        });
      },
      previewForm(isCache) {
        let data = this[this.formConf.formModel]
        if (this.candidateDept) {
          data.candidate_dept = this.candidateDept
        }
        console.log(`output->isCache`, isCache)
        this.$emit(
          "preview",
          {
            conf: this.formConfCopy,
            values: data,
            formFields: this.formConf.fields,
          },
          isCache
        );
      },
      copyItem(scheme) {
        return () => {
          const a = deepClone(scheme.__config__.children[0]);
          const time = Date.now()
          a.forEach((item) => {
            const id = item.__vModel__ + "-" + time;
            this[this.formConf.formRules][id] = deepClone(
              this[this.formConf.formRules][item.__vModel__]
            );

            this.$set(item, "__vModel__", id);

            this.$set(
              item.__config__,
              "defaultValue",
              this.initData[getType(item.__config__.defaultValue)] || null
            );

            this.$set(
              this[this.formConf.formModel],
              id,
              item.__config__.defaultValue
            );

          });

          scheme.__config__.children.push(a);
          // layouts.rowFormItem(undefined, scheme);
        };
      },
      deleteItem(scheme, index) {
        return () => {
          scheme.__config__.children[index].forEach((item) => {
            delete this[this.formConf.formModel][item.__vModel__];
            delete this[this.formConf.formRules][item.__vModel__];
          });
          scheme.__config__.children.splice(index, 1);

          scheme = null;
          index = null;
        };
      },
      handleApprovalChange(val, e, a, v) {
        console.log(`output->val2222222`, val, e, a, v);
      },

      // 打印 
      print(formData, type = 'preview') {
        console.log(`output->formData111`, JSON.parse(JSON.stringify(formData)))
        // 递归赋值
        const recursion = (data, isContainer, container, cid) => {
          data.forEach(element => {
            // 为行容器的情况
            if (element.__config__.tag === "el-row") {
              element.__config__.children.forEach(item => {
                recursion(item, 1, element.__config__.form['标题'], element.__config__.componentName)
              })
            } else {
              let data = {
                filedName: element.__vModel__,
                filedVal: element.__config__.defaultValue,
                fieldDesc: element.__config__.label,
                isContainer: isContainer,
                container: container,
                cid: cid,
                total: ''
              }
              // 为下拉框组件
              if ((element.__config__.tag === "el-select" || element.__config__.tag === "el-cascader") && this.$refs[data.filedName]) {
                data.filedVal = this.$refs[data.filedName].$children[1].$el.querySelectorAll('input')[0].value
                if (element.__config__.type == 'approval') {
                  let nodeList = '';
                  if (this.$refs[data.filedName].$children[2]) {
                    nodeList = this.$refs[data.filedName].$children[2]?.$el.parentNode.querySelectorAll('span');
                  }
                  const nodeString = [...nodeList].map(item => item.textContent).join('/');
                  data.filedVal = nodeString;
                }
              }
              // 为多选框组件
              if (element.__config__.tag === "el-checkbox-group") {
                data.filedVal = data.filedVal.join('/')
              }
              // 为上传文件组件
              if (element.__config__.tag === "el-upload") {
                data.filedVal = data.filedVal.map(item => item.fileName).join('/');
              }
              // 为日期范围组件 或者 时间范围组件
              if ((element.__config__.tag === "el-date-picker" || element.__config__.tag === "el-time-picker") && typeof data.filedVal === 'object') {
                data.filedVal = data.filedVal.join('-')
              }
              // 关联审批
              if (element.type == 'approval') {
                data.filedVal = data.filedVal.map(item => item.label).join('/');
              }
              // 有合计属性
              let SumDom = this.$refs[element.__vModel__.split('_')[0] + '_Sum']
              if (element.__config__.total && SumDom) {
                console.log(SumDom, SumDom.$el.textContent)
                data.total = SumDom.$el.textContent
              }
              data.filedVal = typeof data.filedVal === 'string' ? data.filedVal?.replace(/(\n|\t|\r|\r\n)/g, "").replace(/\s+/g, "") : data.filedVal;
              if (this.$refs[data.filedName]) {
                formData.itemList.push(data)
              }
            }
          });
        }
        console.log(`output->fields`, this.formConfCopy.fields)
        recursion(this.formConfCopy.fields, 0, '', '');
        console.log(`output->formData222`, formData)

        axios({
          method: 'post',
          url: process.env.VUE_APP_BASE_API + 'bpm/common/exportPdf', // 后端的下载接口
          headers: {
            'Clientid': 'e5cd7e4891bf95d1d19206ce24a7b32e',
            'Authorization': JSON.parse(localStorage.getItem('key_user_token')),
          },
          data: formData
        }).then((arr) => {
          if (arr.data.code === 200) {
            const fileUrl = arr.data.data.url;
            const fileName = formData.formName + '_' + new Date().getTime() + '.pdf';

            if (type == 'print') {
              window.ipcRenderer
                .invoke("win-operate", {
                  win: "ChildWin",
                  operate: "start",
                  argu: {
                    width: 800,
                    height: 550,
                    resizable: true,
                    route: `/ImagePreview?src=${encodeURIComponent(fileUrl)}&type=${type}`,
                  },
                })
                .then(() => {
                  this.$message("打开打印页面失败");
                });
              return ''
            }

            axios({
              method: 'post',
              url: fileUrl, // 后端的下载接口
              responseType: "arraybuffer",
            }).then((res) => {
              const err = res.data;
              const blob = new Blob([err], { type: 'application/pdf;charset=UTF-8' });
              // 创建一个下载链接
              const downloadUrl = URL.createObjectURL(blob);
              // 创建一个隐藏的 a 标签
              const link = document.createElement('a');
              link.href = downloadUrl;
              // 设置下载文件名
              link.download = fileName
              // console.log(arr.headers[content-disposition])
              // 触发点击事件下载文件
              link.click();
              // 释放掉 URL 对象
              URL.revokeObjectURL(downloadUrl);

            })
          } else {
            this.$message(arr.data.msg);
          }
        })
      }

    },
    //生命周期 - 创建完成（可以访问当前this实例）
    render(h) {
      return renderFrom.call(this, h);
    },
  };
</script>

<style lang="scss" scoped>
  .listSum {
    padding-left: 60px;
    margin-bottom: 5px;
  }

  .isTable {
    .el-form-item__label {
      display: none;
    }

    .el-form-item__content {
      margin: 0 35px !important;
    }

    .first,
    .last {
      width: 100px !important;
      min-width: 100px !important;
    }
  }

  .isList {
    position: relative;
    width: 100%;
    display: inline-block;
    border: 1px solid rgba(243, 244, 246, 1);
    padding: 30px;
    margin-top: 12px;

    .first,
    .last {
      top: 14px;
      position: absolute;
      right: 24px;
      width: auto !important;
    }

    .first {
      top: 14px;
      left: 15px;
      width: auto !important;
      font-size: 14px;
      text-align: left;
    }
  }

  ::v-deep .el-form-item {
    display: grid;
    /* margin-bottom: 18px !important; */
  }

  ::v-deep .el-input__inner {
    color: #100f0f !important;
    -webkit-text-fill-color: #100f0f
  }

  ::v-deep .el-textarea__inner {
    color: #100f0f !important;
    -webkit-text-fill-color: #100f0f
  }

  ::v-deep .el-tag--info {
    color: #100f0f !important;
    -webkit-text-fill-color: #100f0f
  }

  ::v-deep .el-radio__label {
    color: #100f0f !important;
    -webkit-text-fill-color: #100f0f
  }

  ::v-deep .el-checkbox__label {
    color: #100f0f !important;
    -webkit-text-fill-color: #100f0f
  }

  ::v-deep .input {
    color: #100f0f !important;
    -webkit-text-fill-color: #100f0f
  }

  ::v-deep .el-range-input {
    color: #100f0f !important;
    -webkit-text-fill-color: #100f0f
  }

  ::v-deep .el-range-separator {
    color: #100f0f !important;
    -webkit-text-fill-color: #100f0f
  }


  ::v-deep .el-form-item__label {
    line-height: 36px;
    color: rgba(0, 0, 0, 1);
    font-family: "Noto Sans SC";
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 5px;
    text-align: left;
    display: block;
    /* width: 100% !important; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80% !important;
  }

  ::v-deep .el-col {
    width: 100%;
    max-width: 100%;
  }

  ::v-deep .el-form-item__content {
    margin-left: 0px !important;

    >div {
      width: 100% !important;
    }
  }

  ::v-deep .el-button--small {
    border-radius: calc(var(--el-border-radius-base) - 1px);
    font-size: 12px;
    padding: 5px 11px !important;
  }

  ::v-deep .el-upload {
    display: flex;
    justify-content: left;
  }

  ::v-deep .el-button--primary {
    padding: 18px 18px !important;
    display: flex;
    color: rgba(255, 255, 255, 1);
    font-family: "Noto Sans SC";
    font-weight: 400;
    font-size: 14px;
  }

  ::v-deep .el-cascader__search-input {
    background: #FFF;
  }


  ::v-deep .table-box {

    overflow-y: auto;
    margin-bottom: 40px;
    margin-top: 40px;
    max-height: 400px;

    .add-btn {
      margin: 20px 0 20px 20px;
    }

    .el-col {
      flex: 1;
    }

    .el-form-item__content {
      margin-left: 10px;
    }

    .el-form-item__label {
      margin-bottom: 0px;
      white-space: nowrap;
    }

  }

  .header-row {
    display: flex;
    flex-wrap: nowrap;
  }

  .table-row {
    /* padding: 0 20px; */

    .itemContent {
      margin-bottom: 10px;
      display: flex;

      .el-col {
        display: flex;
        border-bottom: 1px solid #dfe6ec;
        justify-content: center;
        align-items: center;
        padding: 40px 20px;
        box-sizing: border-box;
        min-width: 300px;
      }

      .el-form-item {
        margin: 0;
        display: flex;
        align-items: center;

        .el-form-item__label {
          flex-shrink: 0;
        }
      }
    }

    .itemTitle {
      display: flex;
      align-items: center;
      padding: 0 20px;

      .text {
        margin: 20px;
      }
    }
  }

  .table-header {
    text-align: center;
    /* font-weight: bold; */
    background-color: #f8f8f9;
    padding: 10px;
    border-bottom: 1px solid #dfe6ec;
    display: flex;
    min-width: 300px;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: "Noto Sans SC";
    font-weight: 400;
    font-size: 14px;
  }

  .ul-row {
    border-radius: 10px;
    /* background-color: #f8f8f9; */
    box-sizing: border-box;
    /* margin: 20px;
    padding: 20px; */

    .itemTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }


  /* 通用滚动条样式 */
  ::-webkit-scrollbar {
    width: 4px;
    /* 纵向滚动条的宽度 */
    height: 4px;
    /* 横向滚动条的高度 */
  }

  /* 通用滑块样式 */
  ::-webkit-scrollbar-thumb {
    background: rgb(201, 201, 201);
    border-radius: 10px;
  }

  /* 通用滚动条轨道样式 */
  ::-webkit-scrollbar-track {
    background-color: #fcfcfc;
    border-radius: 2px;
  }

  .hideUpload {
    .el-form-item__content {
      >div {
        width: 100% !important;
      }
    }
  }
</style>