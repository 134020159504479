var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-box" },
    [
      _c("div", { ref: "approvalCenter" }, [
        _c(
          "div",
          { staticClass: "tab-header" },
          [
            _c(
              "el-radio-group",
              {
                on: { input: _vm.OnChangeType },
                model: {
                  value: _vm.chosen,
                  callback: function($$v) {
                    _vm.chosen = $$v
                  },
                  expression: "chosen"
                }
              },
              _vm._l(_vm.listType, function(row) {
                return _c(
                  "el-radio-button",
                  { key: row.type, attrs: { label: row.type } },
                  [
                    _c("span", [_vm._v(" " + _vm._s(row.name) + " ")]),
                    row.type == "todo" && _vm.total > 0
                      ? _c("div", { staticClass: "num" }, [
                          _vm._v(_vm._s(_vm.total))
                        ])
                      : _vm._e(),
                    row.type == "iReceived" && _vm.CountMsg > 0
                      ? _c("div", { staticClass: "num" }, [
                          _vm._v(_vm._s(_vm.CountMsg))
                        ])
                      : _vm._e()
                  ]
                )
              }),
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "search-header" }, [
          _c(
            "div",
            { staticClass: "sx-btn-box-left" },
            [
              _c(
                "el-input",
                {
                  staticClass: "search-input",
                  attrs: { placeholder: "流程名称/流程编号/摘要" },
                  model: {
                    value: _vm.formInline.name,
                    callback: function($$v) {
                      _vm.$set(_vm.formInline, "name", $$v)
                    },
                    expression: "formInline.name"
                  }
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-search",
                    attrs: { slot: "prefix" },
                    slot: "prefix"
                  })
                ]
              ),
              _vm.chosen != "iInitiated"
                ? _c(
                    "el-select",
                    {
                      staticClass: "search-input",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "发起人"
                      },
                      model: {
                        value: _vm.formInline.creator,
                        callback: function($$v) {
                          _vm.$set(_vm.formInline, "creator", $$v)
                        },
                        expression: "formInline.creator"
                      }
                    },
                    _vm._l(_vm.UserList, function(row) {
                      return _c("el-option", {
                        key: row.userId,
                        attrs: { label: row.userName, value: row.userId }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "sx-btn-box-right" },
            [
              _vm.chosen != "todo"
                ? _c(
                    "el-select",
                    {
                      staticClass: "search-input",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择流程状态"
                      },
                      model: {
                        value: _vm.formInline.status,
                        callback: function($$v) {
                          _vm.$set(_vm.formInline, "status", $$v)
                        },
                        expression: "formInline.status"
                      }
                    },
                    _vm._l(_vm.processStatusList, function(row) {
                      return _c("el-option", {
                        key: row.type,
                        attrs: { label: row.name, value: row.type }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "left",
                    trigger: "click",
                    "append-to-body": false,
                    width: "366",
                    "popper-options": {}
                  }
                },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "demo-form-inline",
                      staticStyle: { width: "100%" },
                      attrs: {
                        inline: true,
                        model: _vm.formInline,
                        "label-position": "top"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { label: "所属分组" }
                        },
                        [
                          _c("el-cascader", {
                            staticClass: "sx-btn-box-search",
                            staticStyle: { width: "340px" },
                            attrs: {
                              options: _vm.categoryList,
                              "show-all-levels": false,
                              props: {
                                label: "name",
                                value: "id",
                                multiple: true,
                                emitPath: false
                              },
                              "collapse-tags": true
                            },
                            model: {
                              value: _vm.formInline.formIds,
                              callback: function($$v) {
                                _vm.$set(_vm.formInline, "formIds", $$v)
                              },
                              expression: "formInline.formIds"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { label: _vm.getTimeText() }
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%", height: "36px" },
                            attrs: {
                              align: "center",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              type: "daterange",
                              "range-separator": "-",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "default-time": ["00:00:00", "23:59:59"],
                              "append-to-body": true,
                              size: "mini"
                            },
                            model: {
                              value: _vm.formInline.createTime,
                              callback: function($$v) {
                                _vm.$set(_vm.formInline, "createTime", $$v)
                              },
                              expression: "formInline.createTime"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { label: "结束时间" }
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%", height: "36px" },
                            attrs: {
                              align: "center",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              type: "daterange",
                              "range-separator": "-",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "default-time": ["00:00:00", "23:59:59"],
                              "append-to-body": true,
                              size: "mini"
                            },
                            model: {
                              value: _vm.formInline.endTime,
                              callback: function($$v) {
                                _vm.$set(_vm.formInline, "endTime", $$v)
                              },
                              expression: "formInline.endTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "sx-btn-box" }, [
                    _c(
                      "div",
                      { staticClass: "sx-btn", on: { click: _vm.resetSearch } },
                      [_vm._v(" 重置 ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "sx-btns", on: { click: _vm.search } },
                      [_vm._v(" 搜索 ")]
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "about-search-input-item",
                      attrs: { slot: "reference" },
                      slot: "reference"
                    },
                    [
                      _c("i", { staticClass: "el-icon-search" }),
                      _c("span", [_vm._v("高级筛选")])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.chosen == "iReceived",
                expression: "chosen == 'iReceived'"
              }
            ],
            staticClass: "unread"
          },
          [
            _c(
              "el-checkbox",
              {
                model: {
                  value: _vm.Read,
                  callback: function($$v) {
                    _vm.Read = $$v
                  },
                  expression: "Read"
                }
              },
              [_vm._v("仅未读")]
            ),
            _c("span", { on: { click: _vm.ReadAll } }, [
              _vm._v(" 全部标记为已读 ")
            ])
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.listType[_vm.chosenIndex].data.length != 0,
                expression: "listType[chosenIndex].data.length != 0"
              }
            ],
            staticClass: "tab-content"
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.chosen == "iInitiated",
                    expression: "chosen == 'iInitiated'"
                  }
                ]
              },
              [
                _c(
                  "el-table",
                  {
                    ref: "iInitiated",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.listType[0].data,
                      height: "calc(100vh - 270px)",
                      "row-class-name": "success-row",
                      "header-cell-style": {
                        background: "rgb(86, 105, 236)",
                        color: "rgb(250, 251, 252)"
                      }
                    },
                    on: { "row-click": _vm.onRrowClick }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "流程名称", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.initiator
                                ? _c(
                                    "div",
                                    { staticClass: "about-table-name" },
                                    [
                                      _c("div", [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              scope.row.initiator.avatarUrl ||
                                              require("@/assets/ioc/Group_41.png"),
                                            alt: ""
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "rgb(86, 105, 236)"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.UserClick(
                                                  scope.row.initiator.userId
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.initiator.userName
                                              )
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "rgb(27, 27, 27)",
                                                  "margin-left": "2px"
                                                }
                                              },
                                              [_vm._v("提交的")]
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", [
                                _vm._v(" " + _vm._s(scope.row.name) + " ")
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "摘要", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-popover",
                                {
                                  attrs: { trigger: "hover", placement: "top" }
                                },
                                [
                                  _vm._l(
                                    JSON.parse(scope.row.digest || "[]"),
                                    function(item, index) {
                                      return _c("div", { key: index }, [
                                        index <= 3
                                          ? _c("div", [
                                              _c("span", [
                                                _vm._v(_vm._s(item.name) + ":")
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.value == "undefined"
                                                      ? ""
                                                      : item.value
                                                  )
                                                )
                                              ])
                                            ])
                                          : _vm._e()
                                      ])
                                    }
                                  ),
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "reference" },
                                      slot: "reference"
                                    },
                                    _vm._l(
                                      JSON.parse(scope.row.digest || "[]"),
                                      function(item, index) {
                                        return _c("div", { key: index }, [
                                          index <= 3
                                            ? _c(
                                                "div",
                                                { staticClass: "name-wrapper" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.name) + ":"
                                                    )
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.value ==
                                                          "undefined"
                                                          ? ""
                                                          : item.value
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ],
                                2
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "当前审批人", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.assigner
                                ? _c(
                                    "div",
                                    _vm._l(scope.row.assigner, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "span",
                                        {
                                          key: index,
                                          staticStyle: {
                                            color: "rgb(86, 105, 236)"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.UserClick(item.userId)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.userName) +
                                              _vm._s(
                                                index <
                                                  scope.row.assigner.length - 1
                                                  ? "/"
                                                  : ""
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _c("div", [_vm._v(" - ")])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "所属分组", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(scope.row.groupName || "暂无")
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "提交时间", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parseTime(scope.row.createTime) || "-"
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "结束时间", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parseTime(scope.row.endTime) || "-"
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "流程状态", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.status == 1
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#5669ec" } },
                                    [_vm._v("进行中")]
                                  )
                                : _vm._e(),
                              scope.row.status == 2 && scope.row.result == 2
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#67c23a" } },
                                    [_vm._v("已通过")]
                                  )
                                : _vm._e(),
                              scope.row.status == 2 && scope.row.result == 3
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#f83e45" } },
                                    [_vm._v("不通过")]
                                  )
                                : _vm._e(),
                              scope.row.status == 3
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#848484" } },
                                    [_vm._v("已撤回")]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.chosen == "todo",
                    expression: "chosen == 'todo'"
                  }
                ]
              },
              [
                _c(
                  "el-table",
                  {
                    ref: "todo",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.listType[1].data,
                      height: "calc(100vh - 270px)",
                      "row-class-name": "success-row",
                      "header-cell-style": {
                        background: "rgb(86, 105, 236)",
                        color: "rgb(250, 251, 252)"
                      }
                    },
                    on: { "row-click": _vm.onRrowClick }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "流程名称", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.processInstance
                                ? _c(
                                    "div",
                                    { staticClass: "about-table-name" },
                                    [
                                      _c("div", [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              scope.row.processInstance
                                                .avatarUrl ||
                                              require("@/assets/ioc/Group_41.png"),
                                            alt: ""
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "rgb(86, 105, 236)"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.UserClick(
                                                  scope.row.processInstance
                                                    .startUserId
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.processInstance
                                                  .startUserName
                                              )
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "rgb(27, 27, 27)",
                                                  "margin-left": "2px"
                                                }
                                              },
                                              [_vm._v("提交的")]
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.processInstance
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.processInstance.name) +
                                        " "
                                    )
                                  ])
                                : _vm._e(),
                              !scope.row.processInstance
                                ? _c("div", [_vm._v(" - ")])
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "摘要", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-popover",
                                {
                                  attrs: { trigger: "hover", placement: "top" }
                                },
                                [
                                  _vm._l(
                                    JSON.parse(scope.row.digest || "[]"),
                                    function(item, index) {
                                      return _c("div", { key: index }, [
                                        index <= 3
                                          ? _c("div", [
                                              _c("span", [
                                                _vm._v(_vm._s(item.name) + ":")
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.value == "undefined"
                                                      ? ""
                                                      : item.value
                                                  )
                                                )
                                              ])
                                            ])
                                          : _vm._e()
                                      ])
                                    }
                                  ),
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "reference" },
                                      slot: "reference"
                                    },
                                    _vm._l(
                                      JSON.parse(scope.row.digest || "[]"),
                                      function(item, index) {
                                        return _c("div", { key: index }, [
                                          index <= 3
                                            ? _c(
                                                "div",
                                                { staticClass: "name-wrapper" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.name) + ":"
                                                    )
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.value ==
                                                          "undefined"
                                                          ? ""
                                                          : item.value
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ],
                                2
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "发起人", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.processInstance &&
                              scope.row.processInstance.startUserName
                                ? _c(
                                    "div",
                                    { staticStyle: { color: "#5669ec" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.UserClick(
                                                scope.row.processInstance
                                                  .startUserId
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.processInstance
                                                  .startUserName
                                              )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _c("div", [_vm._v(" - ")])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "所属分组", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(scope.row.groupName || "暂无")
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "提交时间", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parseTime(scope.row.createTime) || "-"
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.chosen == "finished",
                    expression: "chosen == 'finished'"
                  }
                ]
              },
              [
                _c(
                  "el-table",
                  {
                    ref: "finished",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.listType[2].data,
                      height: "calc(100vh - 270px)",
                      "row-class-name": "success-row",
                      "header-cell-style": {
                        background: "rgb(86, 105, 236)",
                        color: "rgb(250, 251, 252)"
                      }
                    },
                    on: { "row-click": _vm.onRrowClick }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "流程名称", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.processInstance
                                ? _c(
                                    "div",
                                    { staticClass: "about-table-name" },
                                    [
                                      _c("div", [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              scope.row.processInstance
                                                .avatarUrl ||
                                              require("@/assets/ioc/Group_41.png"),
                                            alt: ""
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "rgb(86, 105, 236)"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.UserClick(
                                                  scope.row.processInstance
                                                    .startUserId
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.processInstance
                                                  .startUserName
                                              )
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "rgb(27, 27, 27)",
                                                  "margin-left": "2px"
                                                }
                                              },
                                              [_vm._v("提交的")]
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.processInstance
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.processInstance.name) +
                                        " "
                                    )
                                  ])
                                : _vm._e(),
                              !scope.row.processInstance
                                ? _c("div", [_vm._v(" - ")])
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "摘要", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-popover",
                                {
                                  attrs: { trigger: "hover", placement: "top" }
                                },
                                [
                                  _vm._l(
                                    JSON.parse(scope.row.digest || "[]"),
                                    function(item, index) {
                                      return _c("div", { key: index }, [
                                        index <= 3
                                          ? _c("div", [
                                              _c("span", [
                                                _vm._v(_vm._s(item.name) + ":")
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.value == "undefined"
                                                      ? ""
                                                      : item.value
                                                  )
                                                )
                                              ])
                                            ])
                                          : _vm._e()
                                      ])
                                    }
                                  ),
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "reference" },
                                      slot: "reference"
                                    },
                                    _vm._l(
                                      JSON.parse(scope.row.digest || "[]"),
                                      function(item, index) {
                                        return _c("div", { key: index }, [
                                          index <= 3
                                            ? _c(
                                                "div",
                                                { staticClass: "name-wrapper" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.name) + ":"
                                                    )
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.value ==
                                                          "undefined"
                                                          ? ""
                                                          : item.value
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ],
                                2
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "发起人", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.processInstance &&
                              scope.row.processInstance.startUserName
                                ? _c(
                                    "div",
                                    { staticStyle: { color: "#5669ec" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.UserClick(
                                                scope.row.processInstance
                                                  .startUserId
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.processInstance
                                                  .startUserName
                                              )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _c("div", [_vm._v(" - ")])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "所属分组", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(scope.row.groupName || "暂无")
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "提交时间", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parseTime(scope.row.createTime) || "-"
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "结束时间", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parseTime(scope.row.endTime) || "-"
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "流程状态", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.status == 1
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#5669ec" } },
                                    [_vm._v("进行中")]
                                  )
                                : _vm._e(),
                              scope.row.status == 2 && scope.row.result == 2
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#67c23a" } },
                                    [_vm._v("已通过")]
                                  )
                                : _vm._e(),
                              scope.row.status == 2 && scope.row.result == 3
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#f83e45" } },
                                    [_vm._v("不通过")]
                                  )
                                : _vm._e(),
                              scope.row.status == 3
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#848484" } },
                                    [_vm._v("已撤回")]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.chosen == "iReceived",
                    expression: "chosen == 'iReceived'"
                  }
                ]
              },
              [
                _c(
                  "el-table",
                  {
                    ref: "iReceived",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.listType[3].data,
                      height: "calc(100vh - 295px)",
                      "row-class-name": "success-row",
                      "header-cell-style": {
                        background: "rgb(86, 105, 236)",
                        color: "rgb(250, 251, 252)"
                      }
                    },
                    on: { "row-click": _vm.onRrowClick }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "流程名称", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.processInstance
                                ? _c(
                                    "div",
                                    { staticClass: "about-table-name" },
                                    [
                                      _c("div", [
                                        scope.row.isRead == 0
                                          ? _c("div", { staticClass: "unread" })
                                          : _vm._e(),
                                        _c("img", {
                                          attrs: {
                                            src:
                                              scope.row.processInstance
                                                .avatarUrl ||
                                              require("@/assets/ioc/Group_41.png"),
                                            alt: ""
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "rgb(86, 105, 236)"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.UserClick(
                                                  scope.row.processInstance
                                                    .startUserId
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.processInstance
                                                  .startUserName
                                              )
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "rgb(27, 27, 27)",
                                                  "margin-left": "2px"
                                                }
                                              },
                                              [_vm._v("提交的")]
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.processInstance
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.processInstance.name) +
                                        " "
                                    )
                                  ])
                                : _vm._e(),
                              !scope.row.processInstance
                                ? _c("div", [_vm._v(" - ")])
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "摘要", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-popover",
                                {
                                  attrs: { trigger: "hover", placement: "top" }
                                },
                                [
                                  _vm._l(
                                    JSON.parse(scope.row.digest || "[]"),
                                    function(item, index) {
                                      return _c("div", { key: index }, [
                                        index <= 3
                                          ? _c("div", [
                                              _c("span", [
                                                _vm._v(_vm._s(item.name) + ":")
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.value == "undefined"
                                                      ? ""
                                                      : item.value
                                                  )
                                                )
                                              ])
                                            ])
                                          : _vm._e()
                                      ])
                                    }
                                  ),
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "reference" },
                                      slot: "reference"
                                    },
                                    _vm._l(
                                      JSON.parse(scope.row.digest || "[]"),
                                      function(item, index) {
                                        return _c("div", { key: index }, [
                                          index <= 3
                                            ? _c(
                                                "div",
                                                { staticClass: "name-wrapper" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.name) + ":"
                                                    )
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.value ==
                                                          "undefined"
                                                          ? ""
                                                          : item.value
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ],
                                2
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "发起人", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.processInstance &&
                              scope.row.processInstance.startUserName
                                ? _c(
                                    "div",
                                    { staticStyle: { color: "#5669ec" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.UserClick(
                                                scope.row.processInstance
                                                  .startUserId
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.processInstance
                                                  .startUserName
                                              )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _c("div", [_vm._v(" - ")])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "所属分组", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(scope.row.groupName || "暂无")
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "提交时间", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parseTime(scope.row.createTime) || "-"
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "结束时间", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parseTime(scope.row.endTime) || "-"
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "流程状态", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.result == 1
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#5669ec" } },
                                    [_vm._v("进行中")]
                                  )
                                : _vm._e(),
                              scope.row.result == 2
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#67c23a" } },
                                    [_vm._v("已通过")]
                                  )
                                : _vm._e(),
                              scope.row.result == 3
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#f83e45" } },
                                    [_vm._v("不通过")]
                                  )
                                : _vm._e(),
                              scope.row.result == 4
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#848484" } },
                                    [_vm._v("已撤回")]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.listType[_vm.chosenIndex].data.length == 0,
                expression: "listType[chosenIndex].data.length == 0"
              }
            ],
            staticClass: "empty-data"
          },
          [
            _c("img", {
              staticClass: "about-img",
              attrs: {
                src:
                  "https://source.fungsong.com/3453288888914YKG1W1O2ZTgm.png",
                alt: ""
              }
            }),
            _c("div", { staticClass: "about-text" }, [_vm._v("暂无数据")])
          ]
        )
      ]),
      _c("processDetails", {
        ref: "processDetails",
        attrs: { drawerData: _vm.drawerData },
        on: { closeDrawer: _vm.closeDrawer }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }