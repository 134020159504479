<template>
    <div>
        <div class="header">
            <div class="return" @click="backToApproval">
                <img src="@/assets/ioc/angle-left.png" alt="">
                <span>发起审批</span>
            </div>
            <div class="current" @click="showDraft">
                <img src="@/assets/ioc/file-copy.png" alt="">
                <span>草稿箱</span>
            </div>
        </div>
        <el-row :gutter="20" class="main-con">
            <el-col :span="16">
                <div class="form-con" ref="formBody">
                    <div class="form-container">
                        <div class="form-header">
                            <div class="form-icon">
                                <img :src="flowDetail.icon" alt="" class="icon">
                            </div>

                            <span class="form-name">{{ flowDetail.name }}</span>
                        </div>
                        <div v-if="flowDetail.form&&flowDetail.form.remark" class="divider"></div>
                        <div v-if="flowDetail.form&&flowDetail.form.remark" class="form-description">
                            <pre style="white-space: pre-wrap;">{{flowDetail.form.remark}}</pre>
                        </div>
                    </div>
                    <div class="form-content" v-loading="formLoading">
                        <Parser v-if="isShow" ref="Parser" :key="datekey" :form-conf="detailForm" @submit="submitForm"
                            :nodesInfoOld="nodesInfo" :disabled="true" :preview="true" :tasks="tasks"
                            :condition-list="conditionList" :submitDisabled="submitDisabled" :initData="initData"
                            @changefiles="changefiles" @clearState="clearState" @clearCache="clearCache"
                            @preview="previewForm" @saveDraft="saveDraft" @clearTasks="clearTasks"
                            @onPreview="onPreviewFile" @changeUploadList="changeUploadList"
                            :realDepartOptions="realDepartOptions" @changeLitigant="changeLitigant"
                            :candidate_dept="candidate_dept" @delCandidate_dept='delCandidate_dept'
                            @combination='combination' @openDrawer='openDrawer'>
                        </Parser>
                    </div>
                </div>

            </el-col>
            <el-col :span="8">
                <div class="form-right">
                    <div class="form-sidebar" v-if="tasks.length">审批</div>
                    <div style="margin-top: 20px;">
                        <timeline :tasks="tasks" @chooseUser="chooseUser" :tip="tip" />
                    </div>
                </div>
            </el-col>
        </el-row>


        <!-- 草稿箱 -->
        <el-dialog :title="'草稿箱(' + draftList.length + ')'" :visible.sync="dialogVisible" width="60%"
            :modal-append-to-body=false>
            <div class="draft-con">
                <img src="@/assets/ioc/info-circle.png" alt="" class="draft-ioc">
                <span class="draft-tip">3个月内未更新的草稿将被自动删除</span>
            </div>

            <div>
                <el-table :data="draftList" style="width: 100%" height="350">
                    <el-table-column label="流程名称">
                        <template slot-scope="scope">
                            <div class="draft-name">
                                <img style="width: 24px;height: 24px;" :src="scope.row.icon" alt="">
                                <span style="margin-left: 8px;">{{ scope.row.name }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="更新时间">
                        <template slot-scope="scope">
                            <span>{{ formatDate(scope.row.updateTime) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <div class="draft-btn">
                                <div @click="handleEdit(scope.row)" class="draft-btn cursor">
                                    <img src="@/assets/ioc/pen.png" alt="">
                                    <span class="draft-btn-Edit">编辑</span>
                                </div>
                                <div @click="handleDelete(scope.row)" class="draft-btn cursor"
                                    style="margin-left: 20px;">
                                    <img src="@/assets/ioc/trash.png" alt="">
                                    <span class="draft-btn-Delete">删除</span>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>

        <!-- 文件预览 -->
        <FilePreviewDialog :file-url="fileUrl" :fileName="fileName" :dialog-visible="openFilePreview"
            @closePreviewDialog="closePreviewDialog"></FilePreviewDialog>

        <!-- 详情 -->
        <processDetails ref="processDetails" :drawerData="drawerData"></processDetails>

        <!-- 关联审批 -->
        <!-- <relatedApproval ref="relatedApproval" :formIds="formIds"></relatedApproval> -->
    </div>
</template>

<script>
    import FilePreviewDialog from "@/views/detail/components/FilePreviewDialog.vue";
    import { DICT_TYPE, getDictDatas } from "@/utils/dict";
    import Parser from '@/components/parser/Parser';
    import processDetails from '@/components/processDetails.vue';
    import { decodeFields } from "@/utils/formGenerator";
    import { fetchData, AsyncFetchData } from "@/utils/utils";
    import { cloneDeep, uniq, isEqual } from "lodash-es";
    import { deepClone, getType, uniqueArr, formatDate } from "@/utils/index";
    import { switchDigest } from '@/utils/switchdigest'
    import {
        getProcessDefinitionBpmnXML,
        getTaskList,
        getProcessDefinitionList,
        getMoreDepartment
    } from "@/api/bpm/definition";
    import {
        flowManagement,
        flowManagementFindFlowForm,
        getTaskAssignRulList,
        getGroupUserInfo,
        getTrack,
        handleReStart
    } from "@/api/bpm/flow";
    import {
        createProcessInstance,
        simpleTaskCreate,
        createFlowDraft,
        getFlowDraftById,
        updateFlowDraft,
        getFlowDraft,
        deleteFlowDraft
    } from "@/api/bpm/processInstance";
    import timeline from '@/views/detail/components/timeline';
    export default {
        name: 'HFIMElectronWorkbenchCreate',
        components: {
            Parser,
            timeline,
            FilePreviewDialog,
            processDetails,
        },
        data() {
            return {
                // 参数原始值
                originalValue: '',
                formIds: '',
                drawerData: {},
                drawer: false,
                formLoading: true,
                isLoading: false,
                // show: false,
                dialogVisible: false,
                // 表单配置
                datePost: {
                    labelPosition: 'top'
                },
                isShow: false,
                // 流程ID
                form_id: '',
                processDefinitionId: '',
                // 流程详细信息 
                flowDetail: {
                    icon: '',
                    name: '',
                    description: false
                },
                businessKey: "", //业务标识
                submitTasks: [], //发起人自选列表
                submitDisabled: true, //提交按钮禁用控制
                tasks: [],
                users: "", //用户列表
                id: "", //存储表单ID
                datekey: Date.now(),
                originalArray: {},
                tip: "输入必填项后查看流程图",
                conditionList: [],
                flowManagement: {},
                assignRulList: [], //发起人自选选择列表
                openFilePreview: false,
                tabsOptions: [
                    {
                        label: "全部",
                        value: 0,
                    },
                ],
                fileUrl: null,
                fileName: null,
                tabPosition: "all",
                searchValue: undefined,
                // 遮罩层
                loading: true,
                initData: {
                    Number: 0,
                    String: "",
                    Boolean: false,
                    Array: [],
                    Object: {},
                    Undefined: undefined,
                    Null: null,
                },

                // 表格数据
                groupByList: [],
                u_groupByList: [],
                u_groupByListLength: 0,
                // 流程表单详情
                detailForm: {
                    fields: [],
                },

                // BPMN 数据
                bpmnXML: null,
                bpmnControlForm: {
                    prefix: "flowable",
                },

                // 流程表单
                selectProcessInstance: undefined, // 选择的流程实例

                // 数据字典
                // categoryDictDatas: getDictDatas(DICT_TYPE.BPM_MODEL_CATEGORY),

                // 发起人自选表单
                ruleForm: {},
                cache: null,
                origin: [],
                remark: "",
                realDepartOptions: [],
                candidate_dept: null,
                definitionId: '',
                parserLoading: false,
                // 草稿箱
                draftList: [],
                nodesInfo: {},
                digestIf: 0,
                digestInfo: []


            };
        },
        async mounted() {
            this.actions.onGlobalStateChange(state => {
                if (this.$route.path.includes("/main/workbench/Workflow")) {
                    if (state.saveData == true) {
                        this.saveData()
                    }
                }
            });
            // 接收路由跳转的参数
            const row = this.$route.query;
            this.init(row);
        },
        activated() {
            const row = this.$route.query;
            const isload = isEqual(row, this.originalValue)
            if (!isload) {
                this.init(row);
            }
        },
        methods: {
            // 打开详情
            openDrawer(row) {
                this.$refs.processDetails.drawer = true;
                this.drawerData = { id: row.value, taskType: 4 };
            },

            // 时间转化
            formatDate(time) {
                return formatDate(time)
            },
            // 删除草稿
            handleDelete(row) {
                deleteFlowDraft({ id: row.id }).then((res) => {
                    if (res.data) {
                        this.$modal.msgSuccess("删除成功");
                        this.showDraft();
                    }
                });
            },
            closePreviewDialog() {
                this.openFilePreview = !this.openFilePreview;
            },
            // 编辑草稿
            handleEdit(row) {

                this.dialogVisible = false;
                this.isShow = false;
                const data = {
                    taskType: 1,
                    draftId: row.id
                }
                this.init(data)
            },

            /** 查询流程定义列表 */
            // async getList() {
            //     const { data } = await getTaskList({ name: this.searchValue });
            //     this.groupByList = groupBy(data, "groupName");
            //     this.changeTab("全部");
            // },

            /** 草稿 **/
            showDraft() {
                getFlowDraft().then((res) => {
                    this.draftList = res.data.list;
                    this.dialogVisible = true
                });
            },

            // 初始化方法
            async init(row) {
                this.formLoading = true;
                this.originalValue = row;
                if (row) {
                    this.form_id = row.id;
                    this.processDefinitionId = row.processDefinitionId ? row.processDefinitionId : this.processDefinitionId;
                    if (row.draftId) {
                        getFlowDraftById({ id: row.draftId }).then((ref) => {
                            this.handleSelect(ref.data);
                            if (ref.data.formVariables.litigant) {
                                getMoreDepartment({ userId: ref.data.formVariables.litigant }).then(resp => {
                                    this.realDepartOptions = resp.data
                                    this.candidate_dept = ref.data.formVariables.candidate_dept ? ref.data.formVariables.candidate_dept : null
                                })
                            }
                        });
                    };

                    if (row.reStartId) {
                        const res = await handleReStart(row.reStartId);
                        if (res.code == 200) {
                            this.getRestart(res.data);
                            if (res.data.formVariables.litigant) {
                                getMoreDepartment({ userId: res.data.formVariables.litigant }).then(resp => {
                                    this.realDepartOptions = resp.data
                                    this.candidate_dept = res.data.formVariables.candidate_dept ? res.data.formVariables.candidate_dept : null
                                })
                            }
                        }
                    }

                    const MoreDep = await getMoreDepartment({
                        userId: JSON.parse(
                            localStorage.getItem("store-user-info")
                        ).userInfoState.userId
                    })
                    this.realDepartOptions = MoreDep.data

                    if (row.id || row.manageId) {
                        let data = ''
                        if (window.electronStore) {
                            data = window.electronStore.ipcRenderer.getStoreValue(this.$store.state.imWorkbench.userId + "_" + row.id)
                        }
                        await this.getFlowDetail(row, data); // 获取流程详细信息
                    }
                }
            },


            async handleSelect(row) {

                this.ruleForm = {};
                // 设置选择的流程
                // 流程表单
                if (row.manageId || row.id) {
                    let that = this;
                    this.selectProcessInstance = row;
                    // this.selectProcessInstance.processDefinitionId = this.processDefinitionId
                    this.id = row.formId;
                    const { data } = await flowManagement(row.manageId || row.id);
                    this.flowDetail = data;
                    this.bpmnXML = data.bpmnXml;
                    this.creator = data.createBy;
                    this.remark = data.form.remark;
                    this.businessKey = data.businessKey

                    this.digestIf = data.digestIf
                    if (data.digestIf == 1) {
                        this.digestInfo = JSON.parse(data.digestInfo)
                    }

                    const res = await flowManagementFindFlowForm({
                        formId: row.formId,
                        definitionId: row.processDefinitionId,
                    });


                    // 处理数据
                    const file = res.data;
                    this.selectProcessInstance.formName = res.data.name;
                    this.conditionList = file.conditionList || [];
                    this.conditionList.push('litigant')

                    let fields = decodeFields(file.formFields);

                    if (row.formVariables) {
                        fields.forEach((item) => {
                            if (item.__vModel__) {
                                /* 普通表单 */
                                if (row.formVariables[item.__vModel__]) {
                                    this.$set(
                                        item.__config__,
                                        "defaultValue",
                                        row.formVariables[item.__vModel__]
                                    );
                                }
                            } else if (item.__config__.children.length) {
                                /* 容器表单 */
                                const map = item.__config__.children[0].map((rowItem) => {
                                    const havKeys = Object.keys(row.formVariables).filter((key) => {
                                        if (key === rowItem.__vModel__) {
                                            this.$set(
                                                rowItem.__config__,
                                                "defaultValue",
                                                row.formVariables[key]
                                            );
                                        }

                                        return (
                                            key.includes(rowItem.__vModel__) &&
                                            key !== rowItem.__vModel__
                                        );
                                    });

                                    return havKeys.map((key) => {
                                        const dRowItem = deepClone(rowItem);
                                        dRowItem.__config__.defaultValue = row.formVariables[key];
                                        dRowItem.__vModel__ = key;
                                        this.$set(
                                            rowItem.__config__,
                                            "defaultValue",
                                            row.formVariables[rowItem.__vModel__]
                                        );

                                        return dRowItem;
                                    });
                                });

                                if (map.flat().length) {
                                    map.forEach((item2) => {
                                        item2.forEach((item3, index) => {
                                            if (item.__config__.children[index + 1]) {
                                                item.__config__.children[index + 1].push(item2[index]);
                                            } else {
                                                item.__config__.children[index + 1] = [item2[index]];
                                            }
                                        });
                                    });
                                }
                            }
                        });
                    }

                    fields = await AsyncFetchData(fields)
                    console.log(`output->fields`, fields)

                    this.detailForm = {
                        ...JSON.parse(file.formConf),
                        ...this.datePost,
                    };


                    let arr = file.formFields.map((item) => JSON.parse(item).__vModel__ ? JSON.parse(item).__vModel__ : JSON.parse(item).__config__.componentName);
                    let or = file.formFields.map((item) => JSON.parse(item));
                    const filteredItems = or.filter((item) => item.isAssociation == true);
                    const defaultCheckedArray = filteredItems.flatMap((item) =>
                        item.__slot__.options.map((option) => option.defaultChecked)
                    );
                    const oneDimArray = defaultCheckedArray.flat();
                    this.origin = arr.filter((element) => !oneDimArray.includes(element));
                    this.$set(this.detailForm, "fields", fields);
                    this.$set(this.detailForm, "formVariables", row.formVariables);
                    this.datekey = Date.now();
                    this.isShow = true;
                    this.formLoading = false;
                    this.originalArray = { ...this.detailForm };
                    setTimeout(() => {
                        const values = filteredItems.map(item => ({
                            value: this.detailForm.formVariables[item.__vModel__],
                            label: item.__vModel__
                        }));
                        values.forEach(item => {
                            const targetObject = filteredItems.find(obj => obj.__vModel__ == item.label);
                            this.$set(targetObject.__config__, "defaultValue", item.value);
                            let data = {
                                event: item.value,
                                scheme: targetObject
                            }
                            this.changefiles(data)
                        });
                    }, 0);
                }
            },

            async getRestart(row) {
                this.ruleForm = {};
                let that = this;
                this.id = row.formId;
                const { data } = await flowManagement(row.mntId);
                this.flowDetail = data;
                this.selectProcessInstance = data;
                this.selectProcessInstance.groupName = row.groupName;
                this.bpmnXML = data.bpmnXml;
                this.creator = data.createBy;
                this.remark = data.form.remark;
                this.businessKey = data.businessKey
                this.definitionId = row.definitionId

                this.digestIf = data.digestIf
                if (data.digestIf == 1) {
                    this.digestInfo = JSON.parse(data.digestInfo)
                }

                const res = await flowManagementFindFlowForm({
                    formId: row.formId,
                    definitionId: row.definitionId,
                });
                // 处理数据
                const file = res.data;
                this.conditionList = file.conditionList || [];
                this.conditionList.push('litigant')
                let fields = decodeFields(file.formFields);
                fields = fields.filter(i => !i.display)
                if (row.formVariables) {
                    fields.forEach((item) => {
                        if (item.__vModel__) {
                            /* 普通表单 */
                            if (row.formVariables[item.__vModel__] && !item.disabled) {
                                this.$set(
                                    item.__config__,
                                    "defaultValue",
                                    row.formVariables[item.__vModel__]
                                );
                            }
                        } else if (item.__config__.children.length) {
                            /* 容器表单 */
                            const map = item.__config__.children[0].map((rowItem) => {
                                const havKeys = Object.keys(row.formVariables).filter((key) => {
                                    if (key === rowItem.__vModel__) {
                                        this.$set(
                                            rowItem.__config__,
                                            "defaultValue",
                                            row.formVariables[key]
                                        );
                                    }

                                    return (
                                        key.includes(rowItem.__vModel__) &&
                                        key !== rowItem.__vModel__
                                    );
                                });

                                return havKeys.map((key) => {
                                    const dRowItem = deepClone(rowItem);
                                    dRowItem.__config__.defaultValue = row.formVariables[key];
                                    dRowItem.__vModel__ = key;
                                    this.$set(
                                        rowItem.__config__,
                                        "defaultValue",
                                        row.formVariables[rowItem.__vModel__]
                                    );

                                    return dRowItem;
                                });
                            });

                            if (map.flat().length) {
                                map.forEach((item2) => {
                                    item2.forEach((item3, index) => {
                                        if (item.__config__.children[index + 1]) {
                                            item.__config__.children[index + 1].push(item2[index]);
                                        } else {
                                            item.__config__.children[index + 1] = [item2[index]];
                                        }
                                    });
                                });
                            }
                        }
                    });
                }

                fields = await AsyncFetchData(fields)

                this.detailForm = {
                    ...JSON.parse(file.formConf),
                    ...this.datePost,
                };
                let arr = file.formFields.map((item) => JSON.parse(item).__vModel__ ? JSON.parse(item).__vModel__ : JSON.parse(item).__config__.componentName);
                let or = file.formFields.map((item) => JSON.parse(item));
                const filteredItems = or.filter((item) => item.isAssociation == true);
                const defaultCheckedArray = filteredItems.flatMap((item) =>
                    item.__slot__.options.map((option) => option.defaultChecked)
                );
                const oneDimArray = defaultCheckedArray.flat();
                this.origin = arr.filter((element) => !oneDimArray.includes(element));
                this.$set(this.detailForm, "fields", fields);
                this.$set(this.detailForm, "formVariables", row.formVariables);
                this.datekey = Date.now();
                this.isShow = true;
                this.formLoading = false;
                this.originalArray = { ...this.detailForm };
                setTimeout(() => {
                    const values = filteredItems.map(item => ({
                        value: this.detailForm.formVariables[item.__vModel__],
                        label: item.__vModel__
                    }));
                    values.forEach(item => {
                        const targetObject = filteredItems.find(obj => obj.__vModel__ == item.label);
                        this.$set(targetObject.__config__, "defaultValue", item.value);
                        let data = {
                            event: item.value,
                            scheme: targetObject
                        }
                        this.changefiles(data)
                    });
                }, 0);
            },

            // 获取流程详细信息
            async getFlowDetail(row, formVariables) {
                this.parserLoading = true
                this.ruleForm = {};
                this.selectProcessInstance = row;
                // this.id = row.formId;
                const flow = await flowManagement(row.manageId || row.id);
                const data = flow.data;
                this.flowDetail = data;
                this.bpmnXML = data.bpmnXml;
                this.creator = data.createBy;
                this.remark = data.form.remark;
                this.businessKey = data.businessKey
                const flowForm = await flowManagementFindFlowForm({
                    formId: row.formId,
                    definitionId: row.processDefinitionId,
                });


                const file = flowForm.data;
                this.conditionList = file.conditionList || [];
                this.conditionList.push('litigant')
                // 原逻辑
                let fields = decodeFields(flowForm.data.formFields);

                if (formVariables) {
                    fields.forEach((item) => {
                        if (item.__vModel__) {
                            /* 普通表单 */
                            if (formVariables[item.__vModel__]) {
                                this.$set(
                                    item.__config__,
                                    "defaultValue",
                                    formVariables[item.__vModel__]
                                );
                            }
                        } else if (item.__config__.children.length) {
                            /* 容器表单 */
                            const map = item.__config__.children[0].map((rowItem) => {
                                const havKeys = Object.keys(formVariables).filter((key) => {
                                    if (key === rowItem.__vModel__) {
                                        this.$set(
                                            rowItem.__config__,
                                            "defaultValue",
                                            formVariables[key]
                                        );
                                    }

                                    return (
                                        key.includes(rowItem.__vModel__) &&
                                        key !== rowItem.__vModel__
                                    );
                                });

                                return havKeys.map((key) => {
                                    const dRowItem = deepClone(rowItem);
                                    dRowItem.__config__.defaultValue = formVariables[key];
                                    dRowItem.__vModel__ = key;
                                    this.$set(
                                        rowItem.__config__,
                                        "defaultValue",
                                        formVariables[rowItem.__vModel__]
                                    );

                                    return dRowItem;
                                });
                            });

                            if (map.flat().length) {
                                map.forEach((item2) => {
                                    item2.forEach((item3, index) => {
                                        if (item.__config__.children[index + 1]) {
                                            item.__config__.children[index + 1].push(item2[index]);
                                        } else {
                                            item.__config__.children[index + 1] = [item2[index]];
                                        }
                                    });
                                });
                            }
                        }
                    });
                }

                fields = await AsyncFetchData(fields)

                this.detailForm = {
                    ...JSON.parse(file.formConf),
                    ...this.datePost,
                };
                let arr = file.formFields.map((item) => JSON.parse(item).__vModel__ ? JSON.parse(item).__vModel__ : JSON.parse(item).__config__.componentName);
                let or = file.formFields.map((item) => JSON.parse(item));
                const filteredItems = or.filter((item) => item.isAssociation == true);
                const defaultCheckedArray = filteredItems.flatMap((item) =>
                    item.__slot__.options.map((option) => option.defaultChecked)
                );
                const oneDimArray = defaultCheckedArray.flat();
                this.origin = arr.filter((element) => !oneDimArray.includes(element));
                this.$set(this.detailForm, "fields", fields);
                this.$set(this.detailForm, "formVariables", '');

                if (formVariables) {
                    this.$set(this.detailForm, "formVariables", formVariables);
                }

                this.datekey = Date.now();
                this.originalArray = { ...this.detailForm };
                this.id = row.formId;
                this.isShow = true;
                this.formLoading = false;

                this.digestIf = data.digestIf
                if (data.digestIf == 1) {
                    this.digestInfo = JSON.parse(data.digestInfo)
                }
                // }, 2000);
            },

            chooseUser(e) {
                let submitTasks = this.submitTasks;
                let index = submitTasks.findIndex(
                    (item) => item.taskDefKey == e.taskDefKey
                );
                submitTasks[index].assigneeUsers = e.assigneeUsers;

                this.submitTasks = submitTasks;
            },

            // 计算时间
            LeaveCalculation(time) {
                const { startTime, startingTimePeriod, endTime, endTimePeriod } = time;
                // 将日期字符串转换为 Date 对象
                const startDate = new Date(startTime);
                const endDate = new Date(endTime);
                // 计算两日期之间的天数差异
                const differenceInTime = endDate.getTime() - startDate.getTime();
                let differenceInDays = differenceInTime / (1000 * 3600 * 24) + 1;
                if (startingTimePeriod == '下午') {
                    differenceInDays = differenceInDays - 0.5
                }
                if (endTimePeriod == '上午') {
                    differenceInDays = differenceInDays - 0.5
                }
                return differenceInDays;
            },

            getMoreDepartment(userId) {
                getMoreDepartment({ userId: userId }).then(res => {
                    this.realDepartOptions = res.data
                })
            },
            // 组合组件触发事件
            combination(conf) {
                switch (conf.type) {
                    case 'leave':
                        if (conf.values['field1724060738332'] && conf.values['field1724060746228'] && conf.values['field1724060774790'] && conf.values['field1724060779370']) {
                            var data = 0;
                            if (new Date(conf.values['field1724060738332']) > new Date(conf.values['field1724060774790'])) {
                                // return
                                this.$message.error('结束时间不能小于开始时间')
                                data = 0;
                            } else {
                                const time = {
                                    startTime: conf.values['field1724060738332'],
                                    startingTimePeriod: conf.values['field1724060746228'],
                                    endTime: conf.values['field1724060774790'],
                                    endTimePeriod: conf.values['field1724060779370'],
                                }
                                data = this.LeaveCalculation(time);
                            }

                            if (conf.values['field1724060921495'] != data) {
                                this.detailForm.fields.forEach((element, index) => {
                                    if (element.__config__.form && element.__config__.form.type == "leave") {
                                        this.detailForm.fields[index].__config__.children[0][4].__config__.defaultValue = data;
                                    }
                                });
                                this.$set(this.detailForm, "fields", this.detailForm.fields)
                                this.datekey = Date.now();
                            }
                        }
                        break;
                    default:
                        break;
                }
            },

            delCandidate_dept() {
                this.candidate_dept = ''
                const userId = JSON.parse(localStorage.getItem("store-user-info")).userInfoState.userId
                this.getMoreDepartment(userId);
            },

            onPreview(file, url = "") {
                this.openFilePreview = !this.openFilePreview;
                this.fileUrl = url;
                this.fileName = file.name;
            },

            // 使用主应用预览文件
            onPreviewFile(file, url) {
                window.ipcRenderer
                    .invoke("win-operate", {
                        win: "ChildWin",
                        operate: "start",
                        argu: {
                            width: 800,
                            height: 550,
                            resizable: true,
                            route: `/ImagePreview?src=${encodeURIComponent(url)}`,
                        },
                    })
                    .then(() => {
                        this.$message("预览失效");
                    });
                // }else{
                //     this.$message.error('文件不存在')
                // }
            },

            clearCache() {
                this.cache = null;
            },

            clearTasks() {
                this.tasks = [];
            },

            changeUploadList(e) {
                // console.log(`output->e`, e);
            },

            changeLitigant(e) {
                this.getMoreDepartment(e);
            },
            // 查看审批路径
            async previewForm(e, isCache) {
                let deVal = [];
                let variables = {}

                Object.keys(e.values).filter((item) => {
                    if (e.values[item]) {
                        variables[item] = e.values[item];
                    }
                });

                if (this.conditionList && this.conditionList.length > 0) {
                    this.conditionList.forEach((item) => {
                        if (!variables[item]) {
                            variables[item] = ''
                        }
                    })
                }

                if (Object.keys(variables).length === 0) variables[Object.keys(e.values)[0]] = ''

                let obj = {
                    variables: variables,
                    bpmnXml: this.bpmnXML,
                    creator: this.creator,
                    initiator: {
                        userId: JSON.parse(localStorage.getItem("store-user-info")).userInfoState.userId,
                        userName: JSON.parse(localStorage.getItem("store-user-info")).userInfoState.userName,
                        avatarUrl: JSON.parse(localStorage.getItem("store-user-info")).userInfoState.avatarUrl,
                        deptId: JSON.parse(localStorage.getItem("store-user-info")).userInfoState.deptId,
                    }
                };

                const Track = await getTrack(obj);
                if (Track.code == 200) {
                    this.tasks = Track.data;
                    this.submitTasks = cloneDeep(Track.data);
                } else {
                    this.$message.error(Track.msg)
                }

            },

            clearState() {
                this.detailForm.fields.forEach((item) => {
                    if (item.__vModel__) {
                        if (item.__config__.tagIcon == 'time-range') {
                            this.$set(item.__config__, "defaultValue", null);
                        } else {
                            item.__config__.defaultValue =
                                this.initData[getType(item.__config__.defaultValue)];
                        }
                    } else {
                        item.__config__.children.forEach((row) => {
                            row.forEach((rowItem) => {
                                if (rowItem.__vModel__) {
                                    rowItem.__config__.defaultValue =
                                        this.initData[getType(rowItem.__config__.defaultValue)];
                                }
                            });
                        });
                    }
                });

                this.datekey = Date.now();
            },

            saveRelevancy(e) {
                let scheme = e.scheme;

                this.originalArray.fields.map((item) => {
                    if (item.__vModel__) {
                        if (scheme.__vModel__ == item.__vModel__) {
                            item.__config__.defaultValue = scheme.__config__.defaultValue;
                        }
                    } else {
                        item.__config__.children.forEach((row) => {
                            row.forEach((rowItem) => {
                                if (scheme.__vModel__ == rowItem.__vModel__) {
                                    rowItem.__config__.defaultValue =
                                        scheme.__config__.defaultValue;
                                }
                            });
                        });
                    }
                });
            },

            // 选项过滤表单
            changefiles(e) {
                this.submitDisabled = true;

                this.saveRelevancy(e);
                if (e.scheme.isAssociation) {

                    let event = e.event;
                    let newArray = [];
                    let { __vModel__, __slot__ } = e.scheme;
                    if (event) {
                        let flag = e.event;
                        let filteredItems = this.originalArray.fields.filter((item) => item.isAssociation == true);//表单中存在哪些选项关联组件
                        let filteredItemsI = filteredItems.filter((item) => item.__vModel__ !== __vModel__);//获取该选项关联组件以外选项关联组件的值
                        let oa = []//其他选项关联组件选定的值
                        filteredItemsI.forEach(i => {
                            if (i.__config__.defaultValue) {
                                let oaItem = i.__slot__.options.filter(e => e.value == i.__config__.defaultValue)
                                if (oaItem[0]?.defaultChecked) {
                                    oa.push(...oaItem[0].defaultChecked);
                                }
                            }
                        })
                        let list = __slot__.options.filter((item) => {
                            return item.value == flag;
                        });

                        let arr = list[0].defaultChecked || [];
                        // console.log(`output->arr`,arr)
                        arr.push(__vModel__);
                        arr.push(...this.origin);
                        arr.push(...oa)
                        arr = uniq(arr);//去重

                        for (let i = 0; i < this.originalArray.fields.length; i++) {
                            for (let j = 0; j < arr.length; j++) {
                                if (
                                    (this.originalArray.fields[i].__vModel__ ||
                                        this.originalArray.fields[i].__config__.componentName) ==
                                    arr[j]
                                ) {
                                    if (this.originalArray.fields[i].__vModel__ == __vModel__) {
                                        newArray.push(e.scheme);
                                    } else {
                                        newArray.push(this.originalArray.fields[i]);
                                        break;
                                    }
                                } else {
                                    console.log("|||");
                                }
                            }
                        }
                    } else {

                        let arr = this.originalArray.fields.map((item) => item.__vModel__ || item.__config__.componentName);
                        let filteredItems = this.originalArray.fields.filter((item) => item.isAssociation == true);
                        const defaultCheckedArray = filteredItems.flatMap((item) =>
                            item.__slot__.options.map((option) => option.defaultChecked)
                        );
                        const oneDimArray = uniqueArr(defaultCheckedArray.flat());
                        let missingElements = arr.filter(element => !oneDimArray.includes(element));
                        let fieldsFilter = this.originalArray.fields.filter(item => missingElements.includes(item.__vModel__ || item.__config__.componentName));
                        newArray = fieldsFilter;
                        // newArray = this.originalArray.fields;
                    }

                    // 找出option的数组
                    // setTimeout(() => {
                    this.$set(this.detailForm, "fields", newArray);
                    //这里更新了datekey ，组件就会刷新
                    this.datekey = Date.now();
                    // }, 500);
                }
            },

            arrayToObject(arr) {
                let obj = {};
                for (let i = 0; i < arr.length; i++) {
                    let item = arr[i];
                    if (item.optionList && item.optionList.length) {
                        obj[item.taskDefKey] = JSON.stringify(
                            item.assigneeUsers.map((e) => e.userId)
                        );
                        delete item.optionList;
                    }
                }

                return obj;
            },

            submitForm(params) {
                if (!params) {
                    return;
                }
                if (params.top) {
                    const scrollableDivRect = this.$refs.formBody.getBoundingClientRect();
                    const top = params.top - scrollableDivRect.top + this.$refs.formBody.scrollTop;
                    this.$refs.formBody.scrollTo({ top, behavior: 'smooth' })
                    return false
                }

                if (params.values.hasOwnProperty('field1724060921495') && params.values['field1724060921495'] == 0) {
                    return this.$message.error('结束时间不能小于开始时间')
                }

                if (!this.submitTasks.length) {
                    this.$message.error(
                        "任务:" + "[" + this.tasks[0].name + "]" + "请选择审批人"
                    );
                    return;
                }

                for (let index = 0; index < this.submitTasks.length; index++) {
                    const item = this.submitTasks[index];
                    if (!item.assigneeUsers || item.assigneeUsers.length <= 0) {
                        let taskType = item.taskType == "USER_TASK" ? "审批人" : "抄送人";
                        this.$message.error(
                            "任务:" + "[" + item.name + "]" + "请选择" + taskType
                        );
                        return;
                    }
                }
                let ruleFormObj = this.arrayToObject(this.submitTasks);
                // 设置表单禁用
                const conf = params.conf;
                conf.disabled = true; // 表单禁用
                conf.formBtns = false; // 按钮隐藏
                const formFields = params.formFields;

                // 提交表单，创建流程
                if (Object.keys(params.nodesInfo).length > 0) {
                    for (let key in params.nodesInfo) {
                        if (params.values.hasOwnProperty(key)) {
                            params.values[key] = params.nodesInfo[key];
                        }
                    }
                }
                const variables = { ...params.values, ...ruleFormObj };

                let digest = []
                let digestsList = []
                if (this.digestIf == 1) {
                    this.digestInfo.forEach(e => {
                        formFields.forEach(i => {
                            if (e.__vModel__ == i.__vModel__) {
                                let value = switchDigest(i, 0, variables)
                                // console.log(`output->a`,a)
                                if (value) {
                                    digest.push({ name: i.__config__.label, value })
                                }

                            }
                        })
                    })
                } else {
                    digestsList = formFields.filter(e => e.__vModel__).slice(0, 3)
                    digestsList.forEach(e => {
                        let value = switchDigest(e, 0, variables)
                        if (value) {
                            digest.push({ name: e.__config__.label, value })
                        }

                    })
                }

                let confFields = params.conf.fields
                let fullTextList = []
                //递归全局搜索
                confFields.forEach(i => {
                    if (i.__config__.tag === "el-row") {
                        i.__config__.children.forEach(j => {
                            if (j.length > 0) {
                                j.forEach(e => {
                                    let value = switchDigest(e, 0, variables)
                                    fullTextList.push(e.__config__.label)
                                    fullTextList.push(value)
                                })
                            }
                        })
                    } else {
                        let value = switchDigest(i, 0, variables)
                        fullTextList.push(i.__config__.label)
                        fullTextList.push(value)
                    }
                })
                let obj = {
                    processDefinitionId: this.selectProcessInstance.processDefinitionId || this.definitionId,
                    groupId: this.selectProcessInstance.groupId,
                    groupName: this.selectProcessInstance.groupName,
                    variables: variables,
                    formId: this.id,
                    trackList: this.submitTasks,
                    draftId: this.$route.query.draftId,
                    digest: JSON.stringify(digest),
                    fullText: fullTextList.join(' '),
                };
                if (this.businessKey) obj.businessKey = this.businessKey

                this.formLoading = true;
                createProcessInstance(obj)
                    .then((response) => {
                        this.$modal.msgSuccess("发起流程成功");
                        this.formLoading = false;
                        this.originalValue = {};
                        this.$router.push({ name: 'Submited', params: { id: response.data } })
                        const id = this.$store.state.imWorkbench.userId + "_" + this.originalValue.id;
                        if (id && window.electronStore) {
                            window.electronStore.ipcRenderer.setStoreValue(id, '');
                        }
                    })
                    .catch(() => {
                        this.formLoading = false;
                        conf.disabled = false; // 表单开启
                        conf.formBtns = true; // 按钮展示
                    });
            },

            saveDraft({ values, conf, formFields }) {
                let obj = {
                    name: this.selectProcessInstance.name,
                    processDefinitionId: this.selectProcessInstance.processDefinitionId || this.definitionId,
                    groupId: this.selectProcessInstance.groupId,
                    groupName: this.selectProcessInstance.groupName,
                    formVariables: values,
                    // formFields,
                    formName: this.selectProcessInstance.formName,
                    formId: this.selectProcessInstance.formId,
                    manageId:
                        this.selectProcessInstance.manageId || this.selectProcessInstance.id,
                };
                const isNoVal = Object.keys(values).every((item) => {
                    return (
                        values[item] === undefined ||
                        values[item] === "" ||
                        values[item].length === 0
                    );
                });

                if (isNoVal) return this.$modal.msgWarning("请输入后保存");

                if (this.$route.query.draftId) {
                    updateFlowDraft({ id: this.$route.query.draftId, ...obj }).then(
                        (res) => {
                            if (res.data) {
                                this.$modal.msgSuccess("保存成功");
                                this.$router.push({ name: 'Home' })
                            }
                        }
                    );
                } else {
                    createFlowDraft(obj).then((res) => {
                        if (res.data) {
                            this.$modal.msgSuccess("保存成功");
                            this.$router.push({ name: 'Home' })
                        }
                    });
                }
            },

            // 返回审批页面
            backToApproval() {
                this.saveData();
                this.$router.push({ name: 'Home' });
            },
            // 保存数据
            saveData() {
                const data = this.$refs.Parser[this.$refs.Parser.formConf.formModel]
                const id = this.$store.state.imWorkbench.userId + "_" + this.originalValue.id;
                if (id && window.electronStore) {
                    window.electronStore.ipcRenderer.setStoreValue(id, data);
                }
            }
        },
    };
</script>

<style scoped lang="scss">
    .cursor {
        cursor: pointer;
    }

    .header {
        display: flex;
        padding: 16px;
        justify-content: space-between;


        div {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }

        .return {
            span {
                margin-left: 4px;
                color: var(--, rgba(27, 27, 27, 1));
                font-family: "Noto Sans SC";
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
            }
        }


        .current {
            width: 72px;
            height: 34px;
            border-radius: 6px;
            border: 1px solid rgba(243, 244, 246, 1);
            box-sizing: border-box;
            background: rgba(250, 251, 252, 1);

            span {
                text-align: right;
                color: var(--次字体颜色, rgba(132, 132, 132, 1));
                font-family: "Noto Sans SC";
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
            }
        }
    }

    .form-con {
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(100vh - 170px);
    }

    .form-container {
        border-radius: 6px;
        background: rgba(255, 255, 255, 1);
        padding: 16px;
        margin-left: 16px;

        .form-header {
            display: flex;
            align-items: center;

            .form-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid rgba(243, 244, 246, 1);
                width: 48px;
                height: 48px;
                border-radius: 50%;

                .icon {
                    width: 30px;
                    height: 32px;
                    margin: 0px;
                }
            }

            .form-name {
                margin-left: 8px;
                color: var(--, rgba(27, 27, 27, 1));
                font-family: "Noto Sans SC";
                font-weight: 600;
                font-size: 14px;
                line-height: 14px;
            }
        }

        .divider {
            width: 100%;
            height: 1px;
            transform: rotate(0.000005deg);
            border: 1px dashed rgba(228, 229, 231, 1);
            margin-top: 12px;
            margin-bottom: 12px;
        }

        .form-description {
            color: rgba(132, 132, 132, 1);
            font-family: "Noto Sans SC";
            font-weight: 400;
            font-size: 13px;
            text-align: left;
        }
    }

    .form-content {
        margin-top: 12px;
        border-radius: 6px;
        background: rgba(255, 255, 255, 1);
        margin-left: 16px;
        padding: 16px;
        min-height: 380px;
    }

    .form-sidebar {
        color: rgba(27, 27, 27, 1);
        font-family: "Noto Sans SC";
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
    }

    .form-right {
        border-radius: 6px;
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(100vh - 170px);
        background: rgba(255, 255, 255, 1);
        padding-top: 24px;
        padding-right: 16px;
        padding-left: 16px;
        padding-bottom: 16px;

        .form-sidebar {
            text-align: left;
        }
    }

    .draft-con {
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: 20px;
        background: rgba(243, 244, 246, 1);
        height: 34px;

        .draft-ioc {
            margin: 0px 6px 0px 10px;
        }

        .draft-tip {
            color: rgba(132, 132, 132, 1);
            font-family: "Noto Sans SC";
            font-weight: 400;
            font-size: 14px;
        }
    }

    .draft-name {
        display: flex;
        align-items: center;
    }


    .draft-btn {
        display: flex;
        align-items: center;

        .draft-btn-Edit {
            margin-left: 6px;
            color: rgba(86, 105, 236, 1);
            font-family: "Noto Sans SC";
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
        }

        .draft-btn-Delete {
            margin-left: 6px;
            color: rgba(248, 62, 69, 1);
            font-family: "Noto Sans SC";
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
        }
    }

    ::v-deep .el-upload-list__item-name {
        text-align: left;
        user-select: text;
        height: 32px !important;
        line-height: 32px !important;
    }

    /* ::v-deep .el-form-item__content{

    } */

    ::v-deep .el-dropdown-menu__item {
        white-space: nowrap;
    }

    ::v-deep .el-dialog__header {
        padding: 20px 24px 20px 24px;
    }

    ::v-deep .el-dialog__body {
        color: rgb(96, 98, 102);
        font-size: 14px;
        word-break: break-all;
        padding: 0px 20px 20px 20px;
    }

    ::v-deep .el-dialog__headerbtn {
        top: 10px;
    }

    ::v-deep .main-con {
        margin-right: 5px !important;
        height: calc(100vh - 150px);
    }

    ::v-deep .el-upload-list__item {
        height: 60px !important;

        img {
            width: 32px !important;
            height: 32px !important;
        }
    }


    /* 通用滚动条样式 */
    ::-webkit-scrollbar {
        width: 4px;
        /* 纵向滚动条的宽度 */
        height: 4px;
        /* 横向滚动条的高度 */
    }

    /* 通用滑块样式 */
    ::-webkit-scrollbar-thumb {
        background: rgb(201, 201, 201);
        border-radius: 10px;
    }

    /* 通用滚动条轨道样式 */
    ::-webkit-scrollbar-track {
        background-color: #fcfcfc;
        border-radius: 2px;
    }
</style>